import Lottie from "lottie-react";
import animationClientsLottie from "./clientsAnimation.json";

const style = {
    height: 70,
    width: 65,
};

const AnimationClientsLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationClientsLottie} />;
};

export default AnimationClientsLottie;