import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import api from '../../services/api';
import { Button, Form, Label, Input, Container, Row, Col } from 'reactstrap';
import Cookies from 'js-cookie';
import atmoLogo from './atmo publicador (2).jpg';
import BackgroundLoginPage from "./backgroundLoginPage/backgroundLoginPage.js";
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton.js";
import AnimationForgotPassword from './AnimationForgotPassword/AnimationMaquinasLottie.js';

import './style.css';
import '../../components/MobileModeStyle/loginForgotPassMobileStyle.css'
function Login() {
    const [email, setEmail] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const navigate = useNavigate();

    const handlePasswordResetLink = async (e) => {
        e.preventDefault();
        setShowSpinner(true);

        try {
            const response = await api.post('/api/passwordResetLink', {
                email,
            });

            setIsSent(true);

        } catch (error) {
            console.error('Erro ao enviar email:', error);
        } finally {
            setShowSpinner(false);
        }
    };

    const handleReturnToLogin = () => {
        navigate("/login");
    };

    return (
        <>
            <div className='backgroundColorLogin'>
                <div className='div-whatsapp-button'>
                    <WhatsAppButton />
                </div>

                <Container id="containerLogin">
                    <img id="imgAtmoLogoLoginADM" className='logoForgotPassClass' src={atmoLogo} alt='Logo Atmo' />
                    <Row id="rowContainerLogin" className='rowContainerMobileLogin'>
                        <Col sm={9} id='colFormLoginADM' className='colGapColumClass'>
                            <div className='d-flex justify-content-center w-100'>
                                <AnimationForgotPassword />
                            </div>
                            <div className='d-flex justify-content-center align-items-center flex-column w-100'>
                                <span className='fontText1ForgotPass'>Esqueceu a senha ?</span>
                                <span className='fontText2ForgotPass'>Informe seu email abaixo para recuperar a senha.</span>
                            </div>
                            {isSent ? (
                                <div className='w-100 d-flex flex-column justify-content-center'>
                                    <span className='spanTextForgotSend mb-1'>Um link de recuperação foi enviado para o seu e-mail.</span>
                                    <Button id='btnEntrarLogin' onClick={handleReturnToLogin}>Voltar para Login</Button>
                                </div>
                            ) : (
                                    <Form onSubmit={handlePasswordResetLink} className='formForgotMobileClass'>
                                    <div>
                                        {/* <Label for="email"></Label> */}
                                        <Input
                                            placeholder='Email'
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div className='divBtnForgotPass mt-3'>
                                        <Button className='w-100' id='btnEntrarLogin' type="submit" disabled={showSpinner}>
                                            {showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Enviar e-mail</div>}
                                        </Button>
                                    </div>
                                </Form>
                            )}
                        </Col>
                    </Row>
                </Container>
                <BackgroundLoginPage />
            </div>
        </>
    );
}

export default Login;