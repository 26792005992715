import React from 'react'

const DocInput = ({ value, onChange, type }) => {

  return (
    <div className="mb-3">
      <input
        type="text"
        className="form-control"
        value={value}
        id='input-group-edit'
        onChange={onChange}
        maxLength={type.value === 2 ? 11 : 18}
        placeholder='documento'
      />
    </div>
  )
}

export default DocInput
