import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsPlusCircle } from 'react-icons/bs'
import { FaTrash } from 'react-icons/fa'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import DocInput from '../../components/DocInput'
import { GiPositionMarker } from 'react-icons/gi'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Cookies from 'js-cookie'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [nome, setNome] = useState('')
    const [nomeFantasia, setNomeFantasia] = useState('')
    const [email, setEmail] = useState('')
    const [clients, setClients] = useState([])
    const [clientToAssociate, setClientToAssociate] = useState('')
    const [idTipoDocumento, setIdTipoDocumento] = useState('')
    const [documento, setDocumento] = useState('')
    const [documentoFilial, setDocumentoFilial] = useState('')
    const [observacao, setObservacao] = useState('')
    const [contato, setContato] = useState('')
    const [codInterno, setCodInterno] = useState('')
    const [horaAbertura, setHoraAbertura] = useState('00:00:00')
    const [horaFechamento, setHoraFechamento] = useState('00:00:00')
    const [selectedTimezone, setSelectedTimezone] = useState('')
    const [timezones, setTimezones] = useState([])
    const [novoContrato, setNovoContrato] = useState(false)
    const [selectedUf, setSelectedUf] = useState('')
    const [ufs, setUfs] = useState([])
    const [logradouro, setLogradouro] = useState('')
    const [numero, setNumero] = useState('')
    const [cidade, setCidade] = useState('')
    const [bairro, setBairro] = useState('')
    const [complemento, setComplemento] = useState('')
    const [cep, setCep] = useState('')
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [ativo, setAtivo] = useState(false)
    const [telefones, setTelefones] = useState([])
    const customId = 'success-toast-id'

    let { id } = useParams()

    const editLocal = async (e) => {
        e.preventDefault()

        if (nome === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (email && email.indexOf('@') === -1) {
            toast.error('O campo email deve conter o caractere @.', { autoClose: 3000 })
        } else if (idTipoDocumento === '') {
            toast.error('Por favor selecione um tipo de documento.', { autoClose: 3000 })
        } else if (documento === '') {
            toast.error('Por favor forneça um documento válido.', { autoClose: 3000 })
        } else if (selectedTimezone === '') {
            toast.error('Por favor selecione uma timezone.', { autoClose: 3000 })
        } else if (selectedUf === '' || logradouro === '' || numero === '' || cidade === '' || bairro === '' || cep === '') {
            toast.error('Por favor preencha corretamente os campos de endereço.', { autoClose: 3000 })
        } else if (clientToAssociate === '') {
            toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
        } else if (latitude !== '' && longitude !== '' && !validateLatitudeOrLongitude(`${latitude},${longitude}`)) {
            toast.error('Por favor forneça latitude e longitude válidas.', { autoClose: 3000 })
        } else if (telefones.indexOf('') !== -1) {
            toast.error('Por favor preencha todos os campos de telefone adicionados.', { autoClose: 3000 })
        } else {
            await api.put(`/api/local/${id}`, {
                nome,
                clientToAssociate,
                ambienteToAssociate: Cookies.get('ambienteId'),
                nomeFantasia,
                idTipoDocumento,
                documento,
                observacao,
                contato,
                email,
                codInterno,
                horaAbertura,
                horaFechamento,
                documentoFilial,
                selectedTimezone,
                novoContrato,
                selectedUf,
                logradouro,
                numero: numero.toString(),
                cidade,
                bairro,
                complemento,
                cep,
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude),
                ativo,
                telefones
            }).then(() => {
                toast.success('Local edit com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/locais') })
            })
        }

    }

    const adicionarTelefone = (e) => {
        e.preventDefault()

        setTelefones([...telefones, ''])
    }

    const handleDocInput = (e) => {
        if (idTipoDocumento !== '' && idTipoDocumento.value === 2) {
            const inputValue = e.target.value
            const formattedValue = inputValue
                .replace(/\D/g, '')
                .slice(0, 11)
                .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

            setDocumento(formattedValue)
        } else if (idTipoDocumento !== '' && idTipoDocumento.value === 1) {
            const cleanedValue = e.target.value.replace(/\D/g, '')
            const formattedValue = cleanedValue
                .replace(/\D/g, '')
                .slice(0, 14)
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

            setDocumento(formattedValue)
        }

    }

    const handleCepInput = (e) => {
        const inputValue = e.target.value
        const formattedValue = inputValue
            .replace(/\D/g, '')
            .slice(0, 8)
            .replace(/(\d{5})(\d{3})/, '$1-$2')

        setCep(formattedValue)
    }

    const handlePhoneInput = (e, index) => {
        const inputValue = e.target.value
        const formattedValue = inputValue.replace(/^(\d{2})(\d{2})(\d{5})(\d{4})$/, '+$1 ($2) $3-$4')

        const novosTelefones = [...telefones]
        novosTelefones[index] = formattedValue
        setTelefones(novosTelefones)
    }

    const handleDocFilialInput = (e) => {
        if (idTipoDocumento !== '' && idTipoDocumento.value === 2) {
            const inputValue = e.target.value
            const formattedValue = inputValue
                .replace(/\D/g, '')
                .slice(0, 11)
                .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

            setDocumentoFilial(formattedValue)
        } else if (idTipoDocumento !== '' && idTipoDocumento.value === 1) {
            const cleanedValue = e.target.value.replace(/\D/g, '')
            const formattedValue = cleanedValue
                .replace(/\D/g, '')
                .slice(0, 14)
                .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

            setDocumentoFilial(formattedValue)
        }

    }

    const handleTypeDoc = (e) => {
        setIdTipoDocumento(e)
        setDocumento('')
        setDocumentoFilial('')
    }

    function validateLatitudeOrLongitude(value) {
        const regex = new RegExp(
            /^(-?\d+)(\.\d+)?,(-?\d+)(\.\d+)?$/
        )

        const matches = regex.exec(value)

        if (!matches) {
            return false
        }

        const latitude = parseFloat(matches[1])
        const longitude = parseFloat(matches[4])

        if (latitude < -90 || latitude > 90) {
            return false
        }

        if (longitude < -180 || longitude > 180) {
            return false
        }

        return true
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/local/${id}`).then(async (res) => {
                if (cancel) return

                await api.post(`api/client/getClientByAmbienteId`, {
                    ambiente: res.data.localAmbiente
                }).then((res) => {
                    let clientsData = []

                    res.data.clients.forEach(client => {
                        clientsData.push({
                            value: client.id,
                            label: client.name
                        })
                    })

                    setClients(clientsData)
                })

                setClientToAssociate(res.data.localCliente)

                setNome(res.data.local.nome)

                if (res.data.local.nome_fantasia) {
                    setNomeFantasia(res.data.local.nome_fantasia)
                }

                if (res.data.local.email) {
                    setEmail(res.data.local.email)
                }

                setIdTipoDocumento({
                    value: res.data.local.tipo_documento,
                    label: res.data.local.tipo_documento === 1 ? 'CNPJ' : 'CPF'
                })
                setDocumento(res.data.local.documento)

                if (res.data.local.documento_filial) {
                    setDocumentoFilial(res.data.local.documento_filial)
                }

                if (res.data.local.observacao) {
                    setObservacao(res.data.local.observacao)
                }

                if (res.data.local.contato) {
                    setContato(res.data.local.contato)
                }

                if (res.data.local.codigo_interno) {
                    setCodInterno(res.data.local.codigo_interno)
                }

                if (res.data.local.hora_abertura) {
                    setHoraAbertura(res.data.local.hora_abertura)
                }

                if (res.data.local.hora_fechamento) {
                    setHoraFechamento(res.data.local.hora_fechamento)
                }

                // eslint-disable-next-line
                setSelectedTimezone(res.data.timezones.filter(item => item.value == res.data.local.timezone)[0])
                setTimezones(res.data.timezones)
                setNovoContrato(res.data.local.novo_contrato)
                setUfs(res.data.ufs)
                // eslint-disable-next-line
                setSelectedUf(res.data.ufs.filter(item => item.value == res.data.local.id_uf)[0])
                setCidade(res.data.local.cidade)
                setBairro(res.data.local.bairro)
                setCep(res.data.local.cep)
                setLogradouro(res.data.local.logradouro)
                setNumero(res.data.local.numero)

                if (res.data.local.complemento) {
                    setComplemento(res.data.local.complemento)
                }

                if (res.data.local.latitude) {
                    setLatitude(res.data.local.latitude)
                }

                if (res.data.local.longitude) {
                    setLongitude(res.data.local.longitude)
                }

                setTelefones(res.data.telefones)

                setAtivo(res.data.local.ativo)
            })

            await api.post(`api/client/getClientByAmbienteId`, {
                ambiente: Cookies.get('ambienteId')
            }).then((res) => {
                let clientsData = []

                res.data.clients.forEach(client => {
                    clientsData.push({
                        value: client.id,
                        label: client.name
                    })
                })

                setClients(clientsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><GiPositionMarker className='fs-1' /><h3 id='tittleH2'> Edição de local</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/locais' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='nome' value={nome} onChange={(e) => setNome(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Nome fantasia</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='nome fantasia' value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Email</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col xs lg="2">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Tipo de documento</Form.Label>
                                    <Select className='input-group-edit' value={idTipoDocumento} onChange={(e) => handleTypeDoc(e)} options={[{ value: 1, label: 'CNPJ' }, { value: 2, label: 'CPF' }]} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Documento</Form.Label>
                                    <div className='input-group-edit'>
                                        <DocInput value={documento} type={idTipoDocumento} onChange={(e) => handleDocInput(e)} />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Documento filial</Form.Label>
                                    <div className='input-group-edit'>
                                        <DocInput value={documentoFilial} type={idTipoDocumento} onChange={(e) => handleDocFilialInput(e)} />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Observação</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '80px' }}
                                        value={observacao}
                                        className='input-group-edit'
                                        onChange={(e) => setObservacao(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Contato</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '80px' }}
                                        value={contato}
                                        className='input-group-edit'
                                        onChange={(e) => setContato(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col xs lg="4">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Código interno</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='Código interno' value={codInterno} onChange={(e) => setCodInterno(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Hora abertura</Form.Label>
                                    <Form.Control className='input-group-edit' type='time' placeholder='Hora abertura' value={horaAbertura} onChange={(e) => setHoraAbertura(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Hora fechamento</Form.Label>
                                    <Form.Control className='input-group-edit' type='time' placeholder='Hora fechamento' value={horaFechamento} onChange={(e) => setHoraFechamento(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs lg="6">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Timezone</Form.Label>
                                    <Select className='input-group-edit' value={selectedTimezone} onChange={(e) => setSelectedTimezone(e)} options={timezones} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col xs lg="2">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>UF</Form.Label>
                                    <Select className='input-group-edit' value={selectedUf} onChange={(e) => setSelectedUf(e)} options={ufs} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Cidade</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='cidade' value={cidade} onChange={(e) => setCidade(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Bairro</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='Bairro' value={bairro} onChange={(e) => setBairro(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>CEP</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='CEP' value={cep} onChange={(e) => handleCepInput(e)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Número</Form.Label>
                                    <Form.Control className='input-group-edit' type='number' placeholder='Número' value={numero} onChange={(e) => setNumero(e.target.value)} min="0" step="1" />
                                </Form.Group>
                            </Col>
                            <Col xs lg="5">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Logradouro</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='Logradouro' value={logradouro} onChange={(e) => setLogradouro(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Complemento</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='Complemento' value={complemento} onChange={(e) => setComplemento(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col xs lg="3">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Latitude</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='Latitude' value={latitude} onChange={(e) => setLatitude(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs lg="3">
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Longitude</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='Longitude' value={longitude} onChange={(e) => setLongitude(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Clientes</Form.Label>
                                    <Select className='input-group-edit' value={clientToAssociate} onChange={(e) => setClientToAssociate(e)} options={clients} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end'>
                            <Col xs lg="2">
                                <Button onClick={(e) => adicionarTelefone(e)} className='editButton mb-3'><BsPlusCircle /> Novo telefone</Button>
                            </Col>
                            <Col>
                                <Form.Group >
                                    {telefones.map((numero, index) => (
                                        <div key={index} style={{ display: 'flex', marginBottom: '5px' }}>
                                            <Form.Control id='input-group-edit' style={{ width: '13rem', marginRight: '5px' }} type='text' placeholder='número' value={numero} onChange={(e) => handlePhoneInput(e, index)} />
                                            <Button className='deleteButton' onClick={() => setTelefones([...telefones.slice(0, index), ...telefones.slice(index + 1)])}><FaTrash /> Excluir</Button>
                                        </div>
                                    ))}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="3">
                                <Form.Check
                                    className='mb-3 mt-2 fw-semibold'
                                    type='switch'
                                    checked={ativo}
                                    onChange={(e) => setAtivo(e.target.checked)}
                                    label='Ativo'
                                    id='input-group-edit'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                            <Col xs lg="3">
                                <Form.Check
                                    className='fw-semibold'
                                    type='switch'
                                    checked={novoContrato}
                                    onChange={(e) => setNovoContrato(e.target.checked)}
                                    label='Novo contrato'
                                    id='input-group-edit'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editLocal} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit