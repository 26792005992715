import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { BiSolidBookContent } from 'react-icons/bi'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [activated, setActivated] = useState(false)
    const [clients, setClients] = useState([])
    const [clienteToAssociate, setClienteToAssociate] = useState('')
    const [tipoConteudo, setTipoConteudo] = useState('')
    const [templates, setTemplates] = useState([])
    const [templatesToAssociate, setTemplatesToAssociate] = useState('')
    const [approveTemplates, setApproveTemplates] = useState('')
    const [approveFixedMedias, setApproveFixedMedias] = useState('')
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editClient = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (clienteToAssociate === '') {
            toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
        } else if (templatesToAssociate === '' && tipoConteudo === 'Notícia Atmo') {
            toast.error('Por favor selecione um template.', { autoClose: 3000 })
        } else if (approveTemplates === '') {
            toast.error('Por favor selecione aprovação para templates.', { autoClose: 3000 })
        } else if (approveFixedMedias === '') {
            toast.error('Por favor selecione aprovação para mídias fixas.', { autoClose: 3000 })
        } else if (tipoConteudo === '') {
            toast.error('Por favor selecione um tipo de conteúdo.', { autoClose: 3000 })
        } else {
            await api.put(`/api/conteudo/${id}`, {
                clienteToAssociate,
                activated,
                name,
                tipoConteudo,
                templatesToAssociate,
                approveTemplates,
                approveFixedMedias
            }).then(() => {
                setActivated('')
                setName('')
                setClienteToAssociate('')
                setTipoConteudo('')
                setTemplatesToAssociate('')
                setApproveTemplates('')
                setApproveFixedMedias('')
                toast.success('Conteúdo editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/conteudo') })
            })
        }

    }

    const handleChangeCliente = async (e) => {
        setClienteToAssociate(e)
    
        await api.post(`api/materiais/getMateriaisByClientId`, {
          cliente: e,
        }).then((res) => {
            setTemplatesToAssociate('')
            setTemplates(res.data.materiaisArray)
        })
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/conteudo/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.conteudo.name)
                setActivated(res.data.conteudo.ativo)

                let clientesData = []

                clientesData.push({
                    value: '',
                    label: 'Todos'
                })

                res.data.clients.forEach(cliente => {
                    clientesData.push({
                        value: cliente.id,
                        label: cliente.name
                    })
                })

                setClients(clientesData)
                setClienteToAssociate(res.data.conteudoCliente)
                setTemplates(res.data.materiais)
                setTipoConteudo(res.data.tipo_conteudo)
                setApproveTemplates(res.data.conteudo.approve_templates)
                setApproveFixedMedias(res.data.conteudo.approve_fixed_medias)
                
                if (res.data.templateMaterial !== null) {
                    setTemplatesToAssociate({'label': res.data.templateMaterial.nome,
                        'value': res.data.templateMaterial.id
                    })
                }
            })

        }

        fetchData()

        return () => {
            cancel = true
        }
        // eslint-disable-next-line
    }, [id])

    const options = ['Normal', 'Sequencial', 'Hora Marcada', 'Notícia Atmo'];
    const opcoes = ['NÃO', 'SIM'];

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BiSolidBookContent className='fs-1' /><h3 id='tittleH2'> Edição de conteúdo</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/conteudo' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='d-flex align-items-end rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Cliente</Form.Label>
                                    <Select className="shadowCustom fw-medium" id='input-group-edit' value={clienteToAssociate} onChange={(e) => handleChangeCliente(e)} options={clients} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3' controlId='formBasicSector'>
                                    <Form.Label className='fw-semibold'>Tipo Conteúdo</Form.Label>
                                    <Form.Select
                                        className='input-group-edit'
                                        name='tipoConteudo'
                                        value={tipoConteudo}
                                        onChange={(e) =>
                                            setTipoConteudo(e.target.value)
                                        }>
                                        <option disabled value="">Selecione uma opção</option>
                                        {options.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Aprovação para templates</Form.Label>
                                    <Form.Select
                                        className="fw-medium shadowCustom"
                                        id='input-group-edit'
                                        name='approveTemplates'
                                        value={approveTemplates}
                                        onChange={(e) =>
                                            setApproveTemplates(e.target.value)
                                        }>
                                        <option disabled value="">Selecione...</option>
                                        {opcoes.map((option, index) => (
                                            <option key={index} value={index}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Aprovação para mídias fixas (imagens e vídeos)</Form.Label>
                                    <Form.Select
                                        className="fw-medium shadowCustom"
                                        id='input-group-edit'
                                        name='approveFixedMedias'
                                        value={approveFixedMedias}
                                        onChange={(e) =>
                                            setApproveFixedMedias(e.target.value)
                                        }>
                                        <option disabled value="">Selecione...</option>
                                        {opcoes.map((option, index) => (
                                            <option key={index} value={index}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            { tipoConteudo === 'Notícia Atmo' 
                                ?
                                    <Col>
                                        <Form.Group className='mb-3'>
                                            <Form.Label className='fw-semibold'>Selecione Conteudos*</Form.Label>
                                            <Select 
                                                className="shadowCustom fw-medium"
                                                placeholder="Selecione..."
                                                id='input-group-edit'  
                                                value={templatesToAssociate}
                                                onChange={(e) => setTemplatesToAssociate(e)}
                                                options={templates}
                                            />
                                        </Form.Group>
                                    </Col> 
                                :
                                    ''
                            }
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check
                                    className='fw-semibold'
                                    type='switch'
                                    id='input-group-edit'
                                    checked={activated}
                                    onChange={(e) => setActivated(e.target.checked)}
                                    label='Ativar/Desativar'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editClient} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit