import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { IoGameController } from 'react-icons/io5'
import Select from 'react-select'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Edit = () => {
    const history = useNavigate()
    const [descricao, setDescricao] = useState('')
    const [comandoMaquina, setComando] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [horaInicio, setHoraInicio] = useState('')
    const [horaFim, setHoraFim] = useState('')
    const [domingo, setDomingo] = useState(false)
    const [segunda, setSegunda] = useState(false)
    const [terca, setTerca] = useState(false)
    const [quarta, setQuarta] = useState(false)
    const [quinta, setQuinta] = useState(false)
    const [sexta, setSexta] = useState(false)
    const [sabado, setSabado] = useState(false)
    const [persistente, setPersistente] = useState(false)
    const [ativo, setAtivo] = useState(false)
    const [maquinas, setMaquinas] = useState([])
    const [maquinaToAssociate, setMaquinaToAssociate] = useState('')
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editComando = async (e) => {
        e.preventDefault()

        if (descricao === '') {
            toast.error('Por favor preencha o campo de descrição.', { autoClose: 3000 })
        } else if (comandoMaquina === '') {
            toast.error('Por favor preencha o campo de comando.', { autoClose: 3000 })
        } else if (maquinaToAssociate === '') {
            toast.error('Por favor selecione uma máquina.', { autoClose: 3000 })
        } else if (dataInicio === '' || horaInicio === '' || dataFim === '' || horaFim === '') {
            toast.error('Por favor preencha todos os campos de date e hora.', { autoClose: 3000 })
        } else {
            await api.put(`/api/comandosMaquina/${id}`, {
                descricao,
                comando: comandoMaquina,
                id_maquina: maquinaToAssociate.value,
                data_inicio: dataInicio,
                data_fim: dataFim,
                horario_inicio: horaInicio,
                horario_fim: horaFim,
                domingo,
                segunda,
                terca,
                quarta,
                quinta,
                sexta,
                sabado,
                persistente,
                ativo
            }).then(() => {
                setDescricao('')
                setComando('')
                toast.success('Comando editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/comandosMaquina') })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get('api/getMaquinas/getMachinesByAmbienteId').then(async (res) => {
                if (cancel) return

                setMaquinas(res.data.maquinas)
            })

            await api.get(`api/comandosMaquina/${id}`).then(async (res) => {
                if (cancel) return

                setMaquinaToAssociate(res.data.maquina)
                setDescricao(res.data.comando.descricao)
                setComando(res.data.comando.comando)
                setDataInicio(res.data.comando.data_inicio)
                setHoraInicio(res.data.comando.horario_inicio)
                setDataFim(res.data.comando.data_fim)
                setHoraFim(res.data.comando.horario_fim)
                setSegunda(res.data.comando.segunda)
                setTerca(res.data.comando.terca)
                setQuarta(res.data.comando.quarta)
                setQuinta(res.data.comando.quinta)
                setSexta(res.data.comando.sexta)
                setSabado(res.data.comando.sabado)
                setDomingo(res.data.comando.domingo)
                setPersistente(res.data.comando.persistente)
                setAtivo(res.data.comando.ativo)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><IoGameController className='fs-1' /><h3 id='tittleH2'> Edição de comando máquina</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/comandosMaquina' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Descrição</Form.Label>
                                    <Form.Control className="shadowCustom" id='input-group-edit' type='text' placeholder='Nome' value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Comando</Form.Label>
                                    <Form.Control className="shadowCustom" id='input-group-edit' type='text' placeholder='Nome' value={comandoMaquina} onChange={(e) => setComando(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Máquinas</Form.Label>
                                    <Select className='input-group-edit' value={maquinaToAssociate} onChange={(e) => setMaquinaToAssociate(e)} options={maquinas} isMulti={false} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Data início</Form.Label>
                                    <Form.Control id='input-group-edit' className="shadowCustom"
                                        name='dataInicio'
                                        type='date'
                                        value={dataInicio}
                                        onChange={(e) =>
                                            setDataInicio(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Hora início</Form.Label>
                                    <Form.Control id='input-group-edit' className="shadowCustom"
                                        name='horaInicio'
                                        type='time'
                                        value={horaInicio}
                                        onChange={(e) =>
                                            setHoraInicio(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Data fim</Form.Label>
                                    <Form.Control id='input-group-edit' className="shadowCustom"
                                        name='dataFim'
                                        type='date'
                                        value={dataFim}
                                        onChange={(e) =>
                                            setDataFim(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label>Hora fim</Form.Label>
                                    <Form.Control id='input-group-edit' className="shadowCustom"
                                        name='horaFim'
                                        type='time'
                                        value={horaFim}
                                        onChange={(e) =>
                                            setHoraFim(e.target.value)
                                        }
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='border border-2 p-1 container shadowCustom'>
                            <span className='textColors fw-semibold m-1'> Calendario Semanal</span>

                            <div className='border border-1 border-dark-subtle d-flex rowEditCreatComandoMaquina'>
                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='domingo'
                                        label='Domingo'
                                        checked={domingo}
                                        onChange={(e) =>
                                            setDomingo(e.target.checked)
                                        }
                                    />
                                </Col>

                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='segunda'
                                        label='Segunda-feira'
                                        checked={segunda}
                                        onChange={(e) =>
                                            setSegunda(e.target.checked)
                                        }
                                    />
                                </Col>

                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='terca'
                                        label='Terça-feira'
                                        checked={terca}
                                        onChange={(e) =>
                                            setTerca(e.target.checked)
                                        }
                                    />
                                </Col>

                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='quarta'
                                        label='Quarta-feira'
                                        checked={quarta}
                                        onChange={(e) =>
                                            setQuarta(e.target.checked)
                                        }
                                    />
                                </Col>

                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='quinta'
                                        label='Quinta-feira'
                                        checked={quinta}
                                        onChange={(e) =>
                                            setQuinta(e.target.checked)
                                        }
                                    />
                                </Col>

                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='sexta'
                                        label='Sexta-feira'
                                        checked={sexta}
                                        onChange={(e) =>
                                            setSexta(e.target.checked)
                                        }
                                    />
                                </Col>

                                <Col>
                                    <Form.Check
                                        className='m-2 formControlComandoMaquinas formCheckMobile'
                                        type='switch'
                                        id='sabado'
                                        label='Sábado'
                                        checked={sabado}
                                        onChange={(e) =>
                                            setSabado(e.target.checked)
                                        }
                                    />
                                </Col>
                            </div>
                        </Row>

                        <Row className='mb-1 p-2 container'>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    id='persistente'
                                    checked={persistente}
                                    onChange={(e) => setPersistente(e.target.checked)}
                                    label='Persistente'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>

                        <Row className='mb-2 p-2 container'>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    id='ativo'
                                    checked={ativo}
                                    onChange={(e) => setAtivo(e.target.checked)}
                                    label='Ativo'
                                />
                            </Col>
                        </Row>

                        <Row className='mb-3'>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editComando} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit