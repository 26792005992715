import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { FaUsers, FaTrash } from 'react-icons/fa'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Cookies from 'js-cookie'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [clients, setClients] = useState([])
    const [clientToAssociate, setClientToAssociate] = useState('')
    const [ativo, setAtivo] = useState(false)
    const [locais, setLocais] = useState([])
    const [locaisToAssociate, setLocaisToAssociate] = useState([])
    const [maquinas, setMaquinas] = useState([])
    const [maquinasToAssociate, setMaquinasToAssociate] = useState([])
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editGrupo = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (clientToAssociate === '') {
            toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
        } else {
            await api.put(`/api/grupo/${id}`, {
                nome: name,
                clientToAssociate,
                locaisToAssociate,
                maquinasToAssociate,
                ativo
            }).then(() => {
                setName('')
                toast.success('Grupo editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/grupos') })
            })
        }

    }

    const handleChangeLocal = async (e) => {
        setLocaisToAssociate(e)

        await api.post(`api/maquina/getMachinesByPlaceId`, {
            locais: e
        }).then((res) => {
            setMaquinas(res.data.maquinas)
        })
    }

    const handleChangeCliente = async (e) => {
        setClientToAssociate(e)
        setMaquinas([])
        setLocaisToAssociate([])
        setMaquinasToAssociate([])

        await api.post(`api/local/getLocalByClientId`, {
            cliente: e
        }).then((res) => {
            let locaisData = []

            res.data.locais.forEach(local => {
                locaisData.push({
                    value: local.id,
                    label: local.nome
                })
            })

            setLocais(locaisData)
        })
    }

    const handleSelectAllLocais = (e) => {
        e.preventDefault()

        setLocaisToAssociate(locais)
    }

    const handleRemoveAllLocais = (e) => {
        e.preventDefault()

        setLocaisToAssociate([])
        setMaquinas([])
    }

    const handleSelectAllMaquinas = (e) => {
        e.preventDefault()

        setMaquinasToAssociate(maquinas)
    }

    const handleRemoveAllMaquinas = (e) => {
        e.preventDefault()

        setMaquinasToAssociate([])
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/grupo/${id}`).then(async (res) => {
                if (cancel) return

                setName(res.data.grupo.nome)
                setClientToAssociate(res.data.grupoCliente)
                setAtivo(res.data.grupo.ativo)
                setLocais(res.data.locais)
                setMaquinas(res.data.maquinas)
                setLocaisToAssociate(res.data.associatedLocais)
                setMaquinasToAssociate(res.data.associatedMaquinas)
            })

            await api.post(`api/client/getClientByAmbienteId`, {
                ambiente: Cookies.get('ambienteId')
            }).then((res) => {
                let clientsData = [{ value: 'todos', label: 'Todos' }]

                res.data.clients.forEach(client => {
                    clientsData.push({
                        value: client.id,
                        label: client.name
                    })
                })

                setClients(clientsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><FaUsers className='fs-1' /><h3 id='tittleH2'> Edição de grupo</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/grupos' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Form.Group className='mb-3' controlid='formBasicName'>
                            <Form.Label className='fw-semibold'>Nome</Form.Label>
                            <Form.Control className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className='fw-semibold'>Clientes</Form.Label>
                            <Select className='input-group-edit' value={clientToAssociate} onChange={(e) => handleChangeCliente(e)} options={clients} />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className='fw-semibold'>Locais</Form.Label>
                            <Select className='input-group-edit' value={locaisToAssociate} onChange={(e) => handleChangeLocal(e)} options={locais} isMulti={true} />
                            <Button className='editButton mt-3' onClick={(e) => handleSelectAllLocais(e)}><AiOutlinePlusCircle /> Todos os locais</Button>
                            <Button className='deleteButton mt-3' onClick={(e) => handleRemoveAllLocais(e)}><FaTrash /> Remover locais</Button>
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className='fw-semibold'>Maquinas</Form.Label>
                            <Select className='input-group-edit' value={maquinasToAssociate} onChange={(e) => setMaquinasToAssociate(e)} options={maquinas} isMulti={true} />
                            <Button className='editButton mt-3' onClick={(e) => handleSelectAllMaquinas(e)}><AiOutlinePlusCircle /> Todas as máquinas</Button>
                            <Button className='deleteButton mt-3' onClick={(e) => handleRemoveAllMaquinas(e)}><FaTrash /> Remover máquinas</Button>
                        </Form.Group>

                        <Row>
                            <Col xs={10}>
                                <Form.Check
                                    type='switch'
                                    id='input-group-edit'
                                    checked={ativo}
                                    onChange={(e) => setAtivo(e.target.checked)}
                                    label='Ativar/Desativar'
                                    style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editGrupo} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit