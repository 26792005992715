import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import api from '../../services/api';
import { Button, Form, Label, Input, Container, Row, Col } from 'reactstrap';
import Cookies from 'js-cookie';
import atmoLogo from './atmo publicador (2).jpg';
import BackgroundLoginPage from "./backgroundLoginPage/backgroundLoginPage.js";
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton.js";
import { ToastContainer, toast } from 'react-toastify'

import './style.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [isSent, setIsSent] = useState(false);
    const navigate = useNavigate();
    const customId = 'success-toast-id'

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const handlePasswordReset = async (e) => {
        e.preventDefault();

        try {
            if (password.length < 8) {
                toast.error('Por favor insira uma senha de no mínimo oito caracteres', { autoClose: 3000 })

            } else if(passwordConfirmation !== password) {
                toast.error('Senha e confirmação não conferem', { autoClose: 3000 })
                
            } else {
                const response = await api.post(`/api/resetar-senha/${queryParams.get('token')}`, {
                    email: queryParams.get('email'),
                    password,
                    password_confirmation: passwordConfirmation,
                    token: queryParams.get('token')
                });

                setIsSent(true);

                navigate("/login");
            }

        } catch (error) {
            console.error('Erro ao fazer login:', error);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className='backgroundColorLogin'>
                <div className='div-whatsapp-button'>
                    <WhatsAppButton />
                </div>

                <Container id="containerLogin">
                    <img id="imgAtmoLogoLogin" src={atmoLogo} alt='Logo Atmo' />
                    <Row id="rowContainerLogin">
                        <Col sm={9} id='colFormLogin'>
                            <Label id='textLogin'>Redefinição de senha</Label>
                            {/* Renderiza o formulário ou a mensagem de confirmação com base no estado */}
                            {isSent ? (
                                <div>
                                    <p>Senha redefinida com sucesso.</p>
                                </div>
                            ) : (
                                <Form onSubmit={handlePasswordReset}>
                                    <div>
                                        <Label for="password"></Label>
                                        <Input
                                            placeholder='Nova Senha'
                                            type="password"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <Label for="password"></Label>
                                        <Input
                                            placeholder='Confirmar senha'
                                            type="password"
                                            id="password"
                                            value={passwordConfirmation}
                                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                                            required
                                        />
                                    </div>

                                    <div id='divBtnLogin'>
                                        <Button id='btnEntrarLogin' type="submit"><div className='fw-semibold'>Enviar</div></Button>
                                    </div>
                                </Form>
                            )}
                        </Col>
                    </Row>
                </Container>
                <BackgroundLoginPage />
            </div>
        </>
    );
}

export default Login;
