import React from 'react'
import { Bar } from 'react-chartjs-2'
import { saveAs } from 'file-saver'
import { FaDownload } from 'react-icons/fa6'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { TbTemplate } from 'react-icons/tb'

import { Button } from 'react-bootstrap'

const handleDownloadTemplate = () => {
    const canvas = document.querySelector('#graph2')
    const dataURL = canvas.toDataURL('image/png')
    saveAs(dataURL, 'Grafico de Templates.png')
}

const ChartTemplatesPub = ({ total, labels, qtd }) => {
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Templates',
                backgroundColor: 'rgba(255, 140, 0, 1)',
                borderColor: 'rgba(255, 179, 0, 1)',
                borderWidth: 2,
                hoverBackgroundColor: 'rgb(255, 153, 0, 0.60)',
                hoverBorderColor: 'rgba(255, 179, 0, 1)',
                data: qtd,
                borderRadius: '1',
                borderSkipped: false,
            },
        ],
    }

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                color: 'grey',
                anchor: 'end',
                align: 'top',
                formatter: (value) => {
                    return value
                },
            },
        },
    }

    return (
        <div className='backgroundCardChart'>
            <div className='d-flex justify-content-between text-white text-center bg-dark bordeRroundedTop'>
                <label className='fs-5 p-1'><TbTemplate className='paddingFaPhotoVideoClass' /> Publicações Templates</label>
                <Button
                    size='sm'
                    className='m-1'
                    onClick={handleDownloadTemplate}
                    data-bs-toggle='tooltip'
                    title='Download do Grafico'
                >
                    <FaDownload />
                </Button>
            </div>
            <div className='borderTopEndClass'>
                <label className='fs-6 fw-semibold m-lg-1' style={{ color: 'var(--bg-colorText)' }}>Total: {total}</label>
            </div>
            <Bar id='graph2' data={data} options={options} plugins={[ChartDataLabels]} />
            <label className='graphLegendClass' style={{ color: 'var(--bg-colorText)' }}>Quantidade de publicações de templates por data.</label>
        </div>
    )
}

export default ChartTemplatesPub
