import Lottie from "lottie-react";
import animationProvedoresLottie from "./provedoresAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationProvedoresLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationProvedoresLottie} />;
};

export default AnimationProvedoresLottie;