import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'

import AnimationMaquinasLottie from './AnimationMaquinasLottie/AnimationMaquinasLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [maquina, setMaquina] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [maquinaIdToDelete, setMaquinaIdToDelete] = useState('')
    const [maquinaNameToDelete, setMaquinaNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [maquinaPerPage] = useState(10)
    const indexOfLastMaquina = currentPage * maquinaPerPage
    const indexOfFirstMaquina = indexOfLastMaquina - maquinaPerPage
    const currentMaquina = maquina.slice(indexOfFirstMaquina, indexOfLastMaquina)
    const nPages = Math.ceil(maquina.length / maquinaPerPage)
    const [loading, setLoading] = useState(true);
    const [setPermissions] = useState([])

    let { idLocal } = useParams()

    const getMaquina = async () => {

        setLoading(true);

        try {
            const [permissionsResponse, userPermissionsResponse, maquinaResponse ] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get(`/api/maquina/index/${idLocal}`)
            ]);

            setPermissions(permissionsResponse.data.permissions);
            setUserPermissions(userPermissionsResponse.data.permissions);
            setMaquina(maquinaResponse.data.maquina);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get(`/api/maquina/index/${idLocal}`)
        setMaquina(response.data.maquina)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (maquinaId, maquinaName) => {
        setModalShow(true)
        setMaquinaIdToDelete(maquinaId)
        setMaquinaNameToDelete(maquinaName)
    }

    const deleteMaquina = async (maquinaId) => {
        await api.delete(`/api/maquina/${maquinaId}`).then(() => {
            getMaquina()
            setModalShow(false)
            toast.success('Máquina deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteMaquinaConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de máquina.</h4>
                    <p>
                        Você tem certeza de que deseja remover a máquina {props.maquinaname} ?
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteMaquina(props.maquinaid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditMaquinaPage = (maquinaId) => {
        history(`/maquinas/edit/${maquinaId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getMaquina()
        // eslint-disable-next-line
    }, [])

    const filteredMaquina = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentMaquina
        } else {
            return maquina.filter((maquina) => maquina.name.toLowerCase().includes(lowerSearch) || maquina.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, maquina, currentMaquina])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
            <div id='divContainer' className='container'>
                <DeleteMaquinaConfirmaitonModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    maquinaid={maquinaIdToDelete}
                    maquinaname={maquinaNameToDelete}
                />

                <div className='headerContainer'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><AnimationMaquinasLottie /><h3 id='tittleH2'>Máquinas</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group'>
                        <InputGroup id='input-group' className='input-group'>
                            <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>

                        {
                            userPermissions.some((permission) => permission.name === 'store_maquina') ? (
                                <div className='btnVoltarMobile'>
                                    <Button onClick={() => history(`/maquinas/new/${idLocal}`)} id='buttonNew' className='borderbtnCreateEdit'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Nova Maquina</span></Button>
                                </div>
                            ) : ''
                        }
                    </div>
                </div>

                <ToastContainer />

                <div className='container overflow-auto mt-2'>
                    <Table id='tableList' striped bordered hover>
                        <thead>
                            <tr id="trList">
                                <th className='text-center'>#ID</th>
                                <th className='text-center'>Nome</th>
                                <th className='text-center'>Monitor</th>
                                <th className='text-center'>Grade</th>
                                <th className='text-center'>Hash</th>
                                <th className='text-center'>Data de instalação</th>
                                <th className='text-center'>Monitoramento</th>
                                <th id='thAction text-center'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredMaquina.map((maquina, index) => (
                                <tr key={maquina.id}>
                                    <td className='text-center'>{maquina.id}</td>
                                    <td className='text-center'>{maquina.name}</td>
                                    <td className='text-center'>{maquina.monitores}</td>
                                    <td className='text-center'>{maquina.grade_name}</td>
                                    <td className='text-center'>{maquina.hash}</td>
                                    <td className='text-center'>{maquina.data_instalacao ? maquina.data_instalacao.split(' ')[0].split('-').reverse().join('/') : "Não Há Registro" }</td>
                                    <td className='text-center'>{maquina.monitoramento === 1 ? 'Sim' : 'Não'}</td>
                                    <td id='tdButtonAction'>
                                        {
                                            userPermissions.some((permission) => permission.name === 'edit_maquina') ? (
                                                <Button className='editButton' onClick={() => showEditMaquinaPage(maquina.id)}><BsPencilSquare /> Editar</Button>
                                            ) : ''
                                        }

                                        {
                                            userPermissions.some((permission) => permission.name === 'delete_maquina') ? (
                                                <Button className='deleteButton' onClick={() => showDeleteConfirmationModal(maquina.id, maquina.name)}><FaTrash /> Excluir</Button>
                                            ) : ''
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
            )}
        </>
    )
}

export default Index