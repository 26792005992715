import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsNewspaper } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Create = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [url, setUrl] = useState('')
    const [provedores, setProvedores] = useState([])
    const [provedorToAssociate, setProvedorToAssociate] = useState('')
    const customId = 'success-toast-id'

    const createEditoria = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (provedorToAssociate === '') {
            toast.error('Por favor selecione um provedor.', { autoClose: 3000 })
        } else if (url === '') {
            toast.error('Por favor preencha o campo de url.', { autoClose: 3000 })
        } else {
            await api.post('/api/noticiaEditoria/store', {
                nome: name,
                id_provedor: provedorToAssociate.value,
                url,
            }).then(() => {
                setName('')
                toast.success('Editoria criada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/editorias') })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get('api/noticiaProvedor/index').then((res) => {
                if (cancel) return

                let provedoresData = []

                res.data.provedores.forEach(provedor => {
                    provedoresData.push({
                        value: provedor.id,
                        label: provedor.nome
                    })
                })

                setProvedores(provedoresData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BsNewspaper className='fs-1' /><h3 id='tittleH2'> Nova Editoria</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/editorias' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Provedor</Form.Label>
                                    <Select className='input-group-edit' id='input-group-edit' value={provedorToAssociate} onChange={(e) => setProvedorToAssociate(e)} options={provedores} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicUrl'>
                                    <Form.Label className='fw-semibold'>URL</Form.Label>
                                    <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='url' value={url} onChange={(e) => setUrl(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg='2'>
                                <Button className='btnCreateMobile' onClick={createEditoria} id='buttonNew' type='submit'>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Create