import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AnimationAmbienteLottie from './AnimationAmbienteLottie/AnimationAmbienteLottie'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import Loading from '../../components/LoaderComponent/loader'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [ambientes, setAmbientes] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [ambienteIdToDelete, setAmbienteIdToDelete] = useState('')
    const [ambienteNameToDelete, setAmbienteNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [ambientesPerPage] = useState(10)
    const indexOfLastAmbiente = currentPage * ambientesPerPage
    const indexOfFirstAmbiente = indexOfLastAmbiente - ambientesPerPage
    const currentAmbientes = ambientes.slice(indexOfFirstAmbiente, indexOfLastAmbiente)
    const nPages = Math.ceil(ambientes.length / ambientesPerPage)
    const [permissions, setPermissions] = useState([])
    const [loading, setLoading] = useState(true)

    const getAmbientes = async () => {

        setLoading(true)

         try {
        const [permissionsResponse, userPermissionsResponse, ambientesResponse] = await Promise.all([
            api.get('/api/permission/index'),
            api.get(`api/user/${Cookies.get('userId')}`),
            api.get('/api/ambiente/index')
        ]);

        setPermissions(permissionsResponse.data.permissions);
        setUserPermissions(userPermissionsResponse.data.permissions);
        setAmbientes(ambientesResponse.data.ambientes);

        
    } catch (error) {
        console.error('Erro:', error);
        toast.error('Erro ao carregar dados.', { autoClose: 3000 });
    } finally {
        setLoading(false);
    }

        const response = await api.get('/api/ambiente/index')
        setAmbientes(response.data.ambientes)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (ambienteId, ambienteName) => {
        setModalShow(true)
        setAmbienteIdToDelete(ambienteId)
        setAmbienteNameToDelete(ambienteName)
    }

    const deleteAmbiente = async (ambienteId) => {
        await api.delete(`/api/ambiente/${ambienteId}`).then(() => {
            getAmbientes()
            setModalShow(false)
            toast.success('Ambiente deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteAmbienteConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Ambiante.</h4>
                    <p>
                        Você tem certeza de que deseja remover este ambiente <span className='d-flex justify-content-center mb-0'>{props.ambientename} ?</span>
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteAmbiente(props.ambienteid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditAmbientePage = (ambienteId) => {
        history(`/ambiente/edit/${ambienteId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getAmbientes()
    }, [])

    const filteredAmbientes = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentAmbientes
        } else {
            return ambientes.filter((ambiente) => ambiente.name.toLowerCase().includes(lowerSearch) || ambiente.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, ambientes, currentAmbientes])

    return (
        <>
            <NavBar />

            {loading ? (
                <div className='loading'><Loading /></div>
            ) : (
                <div id='divContainer' className='container'>
                    <DeleteAmbienteConfirmaitonModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        ambienteid={ambienteIdToDelete}
                        ambientename={ambienteNameToDelete}
                    />

                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'><AnimationAmbienteLottie /><h3 id='tittleH2'>Ambientes</h3></div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id='search'><AiOutlineSearch /></InputGroup.Text>
                                <Form.Control
                                    placeholder='pesquisar'
                                    aria-label='pesquisar'
                                    aria-describedby='search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {
                                userPermissions.some((permission) => permission.name === 'store_ambiente') ? (
                                    <NavLink className='btnVoltarMobile' to='/ambiente/new' style={{ textDecoration: 'none' }}>
                                        <Button id='buttonNew' className='borderbtnCreateEdit'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='downloadDashButtonMobile'>Novo Ambiente</span></Button>
                                    </NavLink>
                                ) : ''
                            }
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr id='trList'>
                                    <th className='th-text-center'>#ID</th>
                                    <th className='th-text-center'>Nome</th>
                                    <th id='thAction'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredAmbientes.map((ambiente, index) => (
                                    <tr key={ambiente.id} className={ambiente.ativo ? '' : 'table-danger'}>
                                        <td className='td-text-center fontCustom'>{ambiente.id}</td>
                                        <td className='td-text-center fontCustom'>{ambiente.name}</td>
                                        <td id='tdButtonAction'>
                                            {
                                                userPermissions.some((permission) => permission.name === 'edit_ambiente') ? (
                                                    <Button className='editButton btnTableMoBile me-2' onClick={() => showEditAmbientePage(ambiente.id)}><BsPencilSquare /> Editar</Button>
                                                ) : ''
                                            }

                                            {
                                                userPermissions.some((permission) => permission.name === 'delete_ambiente') ? (
                                                    <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(ambiente.id, ambiente.name)}><FaTrash /> Excluir</Button>
                                                ) : ''
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />

                </div>
            )}
        </>
    )
}

export default Index