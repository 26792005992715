import React, { useState, useEffect } from 'react'
import interact from 'interactjs'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FaTrash } from 'react-icons/fa'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Select from 'react-select'
import { AiOutlineVideoCameraAdd } from "react-icons/ai";
import { LuImagePlus } from "react-icons/lu";
import { MdOutlineTextIncrease } from "react-icons/md";
import { MdFormatListNumbered } from "react-icons/md";
import { IoCalendarOutline } from "react-icons/io5";
import { BiCalendarWeek } from "react-icons/bi";
import { LuClock } from "react-icons/lu";
import { MdEuroSymbol } from "react-icons/md";
import { BiDollar } from "react-icons/bi";
import { FaTemperatureArrowDown } from "react-icons/fa6";
import { FaTemperatureArrowUp } from "react-icons/fa6";
import { PiSelectionBackgroundDuotone } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";
import { LuTextCursorInput } from "react-icons/lu";
import { TbLayoutGridAdd } from "react-icons/tb";
import { MdSaveAlt } from "react-icons/md";

const DragAndDropArea = () => {
  const [items, setItems] = useState([])
  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const [selectedBackground, setSelectedBackground] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [backgrounds, setBackgrounds] = useState([])
  const [doSpaces, setDoSpaces] = useState('')
  const customId = 'success-toast-id'
  const [clients, setClients] = useState([])
  const [clientToAssociate, setClientToAssociate] = useState('')
  const history = useNavigate()
  const [backgroundContent, setBackgroundContent] = useState('')

  const handleSubmit = () => {
    const convertedItems = convertItemsForBackend(items)
    if (templateName === '') {
      toast.error('Por favor preencha o campo de Nome Template.', { autoClose: 3000 })
    } else if (selectedBackground === '') {
      toast.error('Por favor escolha um plano de fundo.', { autoClose: 3000 })
    } else if (clientToAssociate.value === '') {
      toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
    } else {
      api.post('/api/generate-zip', {
        items: convertedItems,
        background: selectedBackground,
        templateName: templateName,
        cliente: clientToAssociate
      })
        .then(response => {
          toast.success('Template criado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/template/padrao/index') })
        })
        .catch(error => {
          toast.error('Erro ao criar template', { toastId: customId, autoClose: 3000 })
          console.error("Erro ao enviar dados:", error)
        })
    }
  }

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      await api.get(`api/materiais/getMateriais`)
        .then(response => {
          setBackgrounds(response.data.materiais)
          setDoSpaces(response.data.spaces)
          setSelectedBackground({ type: response.data.materiais[0].type, url: `${response.data.spaces}${response.data.materiais[0].arquivo}` })

          if (response.data.materiais[0].type === 'video') {
            setBackgroundContent(`
            <video autoPlay loop muted playsInline style="position: absolute; width: 100%; height: 100%; object-fit: cover;">
              <source src="${response.data.spaces}${response.data.materiais[0].arquivo}" type="video/mp4"/>
              Seu navegador não suporta vídeos.
            </video>
          `)
          } else {
            setBackgroundContent(`
            <div style="width: 100%; height: 100%; background-image: url(${response.data.spaces}${response.data.materiais[0].arquivo}); background-size: cover; background-position: center;"></div>
          `)
          }
        })
        .catch(error => console.error("Falha ao carregar backgrounds:", error))


      await api.post(`api/client/getClientByAmbienteId`, {
        ambiente: Cookies.get('ambienteId'),
      }).then((res) => {
        if (cancel) return

        let clientsData = [{ label: 'Selecione...', value: '' }]

        res.data.clients.forEach((client) => {
          clientsData.push({
            value: client.id,
            label: client.name,
          })
        })

        setClientToAssociate(clientsData[0])
        setClients(clientsData)
      })
    }

    const adjustDragAndDropAreaSize = () => {
      const area = document.getElementById('dragAndDropArea')
      if (!area) return

      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight * 0.5
      const fullHdAspectRatio = 1920 / 1080

      let newWidth = viewportWidth
      let newHeight = viewportWidth / fullHdAspectRatio
      newHeight = newHeight * 2

      if (newHeight > viewportHeight) {
        newHeight = viewportHeight
        newWidth = viewportHeight * fullHdAspectRatio
        newWidth = newWidth * 2
      }

      area.style.width = `${newWidth}px`
      area.style.height = `${newHeight}px`
    }

    adjustDragAndDropAreaSize()

    window.addEventListener('resize', adjustDragAndDropAreaSize)

    window.removeEventListener('resize', adjustDragAndDropAreaSize)

    interact('.resize-drag')
      .draggable({
        onmove: window.dragMoveListener,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],
      })
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        modifiers: [
          interact.modifiers.restrictEdges({
            outer: 'parent',
          }),
        ],
      })
      .on('dragmove', (event) => {
        const index = event.target.getAttribute('data-index')
        updateItemPosition(index, event.dx, event.dy)
      })
      .on('resizemove', (event) => {
        const index = event.target.getAttribute('data-index')
        updateItemSize(index, event.rect, event.deltaRect)
      })

    function updateItemPosition(index, dx, dy) {
      setItems(items =>
        items.map((item, i) =>
          i === parseInt(index)
            ? {
              ...item,
              left: item.left + dx,
              top: item.top + dy,
            }
            : item,
        ),
      )
    }

    function updateItemSize(index, rect, deltaRect) {
      setItems(items =>
        items.map((item, i) =>
          i === parseInt(index)
            ? {
              ...item,
              width: rect.width,
              height: rect.height,
              left: item.left + deltaRect.left,
              top: item.top + deltaRect.top,
            }
            : item,
        ),
      )
    }

    window.dragMoveListener = function (event) {
      const target = event.target
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

      target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [])

  const handleChangeCliente = async (e) => {
    setClientToAssociate(e)
  }

  const removeSelectedItem = () => {
    if (selectedItemIndex !== null) {
      setItems(items.filter((_, index) => index !== selectedItemIndex))
      setSelectedItemIndex(null)
    }
  }

  const itemExists = (type) => items.some(item => item.tipo === type);

  const addItem = (type) => {
    setItems([...items,
    {
      nome: `${type}_${items.length}`,
      tipo: type,
      left: 0,
      top: 0,
      width: 100,
      height: 50,
      fontColor: '#000000',
      fadeIn: 30,
      fadeOut: 40,
      fontSize: 100,
      lista: null
    }
    ])
  }

  const handleItemClick = (index) => {
    setSelectedItemIndex(index)
  }

  const handleChangeBackground = (e) => {
    if (e.target.value !== '') {
      const bg = backgrounds.find(bg => `${doSpaces}${bg.caminho}/${bg.arquivo}` === e.target.value)

      if (bg) {
        setSelectedBackground({ type: bg.type, url: e.target.value })
      }

      if (bg.type === 'video') {
        setBackgroundContent(`
          <video autoPlay loop muted playsInline style="position: absolute; width: 100%; height: 100%; object-fit: cover;">
            <source src="${e.target.value}" type="video/mp4"/>
            Seu navegador não suporta vídeos.
          </video>
        `)
      } else {
        setBackgroundContent(`
          <div style="width: 100%; height: 100%; background-image: url(${e.target.value}); background-size: cover; background-position: center;"></div>
        `)
      }
    } else {
      setSelectedBackground('')
    }
  }

  const updateSelectedItem = (updatedFields) => {
    setItems(items =>
      items.map((item, i) =>
        i === selectedItemIndex ? { ...item, ...updatedFields } : item,
      ),
    )
  }

  const convertItemsForBackend = (items) => {
    const area = document.getElementById('dragAndDropArea')
    if (!area) return items

    const areaRect = area.getBoundingClientRect()

    return items.map(item => {
      const itemWidthInPx = parseFloat(item.width)
      const itemHeightInPx = parseFloat(item.height)
      const itemLeftInPx = parseFloat(item.left)
      const itemTopInPx = parseFloat(item.top)

      const widthPercent = (itemWidthInPx / areaRect.width) * 100
      const heightPercent = (itemHeightInPx / areaRect.height) * 100
      const leftPercent = (itemLeftInPx / areaRect.width) * 100
      const topPercent = (itemTopInPx / areaRect.height) * 100

      return {
        ...item,
        width: widthPercent,
        height: heightPercent,
        left: leftPercent,
        top: topPercent,
      }
    })
  }

  return (
    <>
      <div className='container'>
        <ToastContainer />
        <Row className='d-flex'>
          <div className='divSaveBtnClass'>
            <Button onClick={handleSubmit} className="saveBtnClass mb-2" type='submit'>
              <span className='d-flex align-items-center'><MdSaveAlt className='fs-5 me-1' />Salvar</span>
            </Button>
          </div>
          <div className='d-flex'>
          <Col>
            <div className='divMaquinasNovaPub shadowCustom h-100'>
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><TbLayoutGridAdd className='fs-5 me-1' />Adicione os itens ao template</span>
              </label>
              <div className="gridTemplateDefault p-3">
                <Button className='btnTemplateDefault' onClick={() => addItem('video')}>
                  <AiOutlineVideoCameraAdd className='fs-4 me-1' />Adicionar Vídeo
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('foto')}>
                  <LuImagePlus className='fs-4 me-1' />Adicionar Foto
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('texto')}>
                  <MdOutlineTextIncrease className='fs-4 me-1' />Adicionar Texto
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('lista')}>
                  <MdFormatListNumbered className='fs-4 me-1' />Adicionar Lista
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('data')} disabled={itemExists('data')}>
                  <IoCalendarOutline className='fs-4 me-1' />Data
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('semana')} disabled={itemExists('semana')}>
                  <BiCalendarWeek className='fs-4 me-1' />Dia da Semana
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('relogio')} disabled={itemExists('relogio')}>
                  <LuClock className='fs-4 me-1' />Relógio
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('euro')} disabled={itemExists('euro')}>
                  <MdEuroSymbol className='fs-4 me-1' />Euro
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('dolar')} disabled={itemExists('dolar')}>
                  <BiDollar className='fs-4 me-1' />Dollar
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('min')} disabled={itemExists('min')}>
                  <FaTemperatureArrowDown className='fs-4 me-1' />Mínima ºC
                </Button>
                <Button className='btnTemplateDefault' onClick={() => addItem('max')} disabled={itemExists('max')}>
                  <FaTemperatureArrowUp className='fs-4 me-1' />Máxima ºC
                </Button>
              </div>
            </div>
          </Col>
          <Col className='d-flex-column'>
            <Form.Group className='divMaquinasNovaPub shadowCustom'>
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><LuTextCursorInput className='fs-5 me-1' />Nome do Template</span>
              </label>
              <div className='p-2'>
                <Form.Control
                  required
                  type='text'
                  placeholder='Digite o nome do template'
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group className='divMaquinasNovaPub shadowCustom mt-2'>
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><PiSelectionBackgroundDuotone className='fs-5 me-1' />Plano de fundo</span>
              </label>
              <div className='p-2'>
                <select onChange={handleChangeBackground} className="form-select">
                    <option value=''>Selecione...</option>
                    {backgrounds.map((bg, index) => (
                      <option key={index} value={`${doSpaces}${bg.caminho}/${bg.arquivo}`}>{bg.label}</option>
                    ))}
                </select>
              </div>
            </Form.Group>

            <Form.Group className='divMaquinasNovaPub shadowCustom mt-2'>
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><FaUsers className='fs-5 me-1' />Clientes</span>
              </label>
              <div className='p-2'>
                <Select
                  placeholder='Selecione o Cliente'
                  id="input-group-edit"
                  value={clientToAssociate}
                  onChange={(e) => handleChangeCliente(e)}
                  options={clients}
                />
              </div>
            </Form.Group>
          </Col>
          </div>
        </Row>
        <Row className="container mt-2"
          style={{
            margin: '0px !important',
            padding: '0px !important',
          }}
        >{selectedItemIndex !== null && (
          <div className=' divMaquinasNovaPub shadowCustom'>
            <label className='d-flex justify-content-between fw-semibold borderDown p-2 bg-dark text-white text-nowrap'>
              <span>Atributos do item {items[selectedItemIndex].tipo}</span>
              <Button size='sm' className='deleteButton' onClick={removeSelectedItem} type='submit'>
                <span className='d-flex align-items-center'><FaTrash className='fs-6 me-1' />Remover Item</span>
              </Button>
            </label>
            <Row className='rowGridFilterItensClass'>
              <Col>
                {
                  items[selectedItemIndex].tipo !== 'relogio' ||
                    items[selectedItemIndex].tipo !== 'max' ||
                    items[selectedItemIndex].tipo !== 'data' ||
                    items[selectedItemIndex].tipo !== 'min' ||
                    items[selectedItemIndex].tipo !== 'dolar' ||
                    items[selectedItemIndex].tipo !== 'euro' ?
                    <div className='divMaquinasNovaPub'>
                      <Form.Group controlid='formBasicPassword' className='p-2'>
                        <label className='fw-semibold textColors text-nowrap'>
                          <span className='ms-1'>Nome {items[selectedItemIndex].tipo}:</span>
                        </label>
                        <Form.Control required type='text' placeholder='Nome' value={items[selectedItemIndex].nome} onChange={(e) => updateSelectedItem({ nome: e.target.value })} />
                      </Form.Group>
                    </div>
                    :
                    ''
                }
              </Col>
              {items[selectedItemIndex].tipo === 'texto' ||
                items[selectedItemIndex].tipo === 'data' ||
                items[selectedItemIndex].tipo === 'semana' ||
                items[selectedItemIndex].tipo === 'relogio' ||
                items[selectedItemIndex].tipo === 'max' ||
                items[selectedItemIndex].tipo === 'min' ||
                items[selectedItemIndex].tipo === 'dolar' ||
                items[selectedItemIndex].tipo === 'euro' ||
                items[selectedItemIndex].tipo === 'lista' ?
                <Col>
                  <div className='divMaquinasNovaPub'>

                    <Form.Group className='p-2' controlid='formBasicConfirmationPassword'>
                      <label className='fw-semibold textColors text-nowrap'>
                          <span className='ms-1'>Cor da fonte {items[selectedItemIndex].tipo}:</span>
                      </label>
                      <Form.Control className='w-100' required type='color' placeholder='Selecione o tempo em segundos' value={items[selectedItemIndex].fontColor} onChange={(e) => updateSelectedItem({ fontColor: e.target.value })} />
                    </Form.Group>
                  </div>
                </Col> : ''
              }
              <Col>
                <div className='divMaquinasNovaPub'>
                  <Form.Group className='p-2' controlid='formBasicConfirmationPassword'>
                      <label className='fw-semibold textColors text-nowrap'>
                        <span className='ms-1'>Tempo para {items[selectedItemIndex].tipo} aparecer:</span>
                      </label>
                    <Form.Control required type='number' placeholder='Selecione o tempo em segundos (Opcional)' value={items[selectedItemIndex].fadeIn} onChange={(e) => updateSelectedItem({ fadeIn: e.target.value })} />
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div className='divMaquinasNovaPub'>
                  <Form.Group className='p-2' controlid='formBasicConfirmationPassword'>
                  <label className='fw-semibold textColors text-nowrap'>
                    <span className='ms-1'>Tempo para {items[selectedItemIndex].tipo} desaparecer:</span>
                  </label>
                    <Form.Control required type='number' placeholder='Selecione o tempo em segundos' value={items[selectedItemIndex].fadeOut} onChange={(e) => updateSelectedItem({ fadeOut: e.target.value })} />
                  </Form.Group>
                </div>
              </Col>
              {items[selectedItemIndex].tipo === 'texto' ||
                items[selectedItemIndex].tipo === 'relogio' ||
                items[selectedItemIndex].tipo === 'data' ||
                items[selectedItemIndex].tipo === 'semana' ||
                items[selectedItemIndex].tipo === 'max' ||
                items[selectedItemIndex].tipo === 'min' ||
                items[selectedItemIndex].tipo === 'dolar' ||
                items[selectedItemIndex].tipo === 'euro' ||
                items[selectedItemIndex].tipo === 'lista' ?
                <Col>
                  <div className='divMaquinasNovaPub'>
                    <Form.Group className='p-2' controlid='formBasicConfirmationPassword'>
                        <label className='fw-semibold textColors text-nowrap'>
                          <span className='ms-1'>Tamanho da fonte de {items[selectedItemIndex].tipo}:</span>
                        </label>
                      <Form.Control min={1} required type='number' placeholder='Tamanho da fonte' value={items[selectedItemIndex].fontSize} onChange={(e) => updateSelectedItem({ fontSize: e.target.value })} />
                    </Form.Group>
                  </div>
                </Col> : ''
              }
              {items[selectedItemIndex].tipo === 'lista' ?
                <Col>
                  <div className='divMaquinasNovaPub'>
                    <Form.Group className='p-2' controlid='formBasicConfirmationPassword'>
                        <label className='fw-semibold textColors text-nowrap'>
                          <span className='ms-1'>Itens da {items[selectedItemIndex].tipo}:</span>
                        </label>
                      <Form.Control min={1} required type='text' placeholder="Separador ';'" value={items[selectedItemIndex].lista} onChange={(e) => updateSelectedItem({ lista: e.target.value })} />
                    </Form.Group>
                  </div>
                </Col> : ''
              }
            </Row>
          </div>
        )}
          <div id="dragAndDropArea" className='container mt-2 mb-4' style={{
            margin: '0px',
            padding: '0px',
            maxWidth: '100%',
            maxHeight: '100vh',
            // width: '100%',
            // height: '100vh',
            position: 'relative',
            border: '1px solid #ccc',
          }}
          >

            <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: backgroundContent }}></div>

            {items.map((item, index) => (
              <div
                key={index}
                className="resize-drag"
                data-index={index}
                style={{
                  width: `${item.width}px`,
                  height: `${item.height}px`,
                  transform: `translate(${item.left}px, ${item.top}px)`,
                  position: 'absolute',
                  border: '1px solid #fff',
                  backgroundColor: 'rgba(0,0,255,0.1)',
                }}
                onClick={() => handleItemClick(index)}
              >
                {item.nome}
              </div>
            ))}

          </div>
        </Row>
      </div>
    </>
  )
}

export default DragAndDropArea
