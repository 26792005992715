import Lottie from "lottie-react";
import animationBibliotecasLottie from "./bibliotecasAnimation.json";

const style = {
    height: 70,
    width: 60,
};

const AnimationBibliotecasLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationBibliotecasLottie} />;
};

export default AnimationBibliotecasLottie;