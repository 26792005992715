import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { FaUserCog } from 'react-icons/fa'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [activated, setActivated] = useState(false)
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editClient = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else {
            await api.put(`/api/client/${id}`, {
                name,
                ativo: activated
            }).then(() => {
                setName('')
                toast.success('Cliente editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/clientes') })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/clients/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.client.name)
                setActivated(res.data.client.ativo)
            })

        }

        fetchData()

        return () => {
            cancel = true
        }
        // eslint-disable-next-line
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><FaUserCog className='fs-1' /><h3 id='tittleH2'> Edição de cliente</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <NavLink to='/clientes' style={{ textDecoration: 'none' }}>
                            <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </NavLink>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='formGenMobile'>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='formGenMobile'>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    id='activeUser'
                                    checked={activated}
                                    onChange={(e) => setActivated(e.target.checked)}
                                    label='Ativar/Desativar'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                            <Col xs lg="2">
                                <Button onClick={editClient} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit