import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import NavBar from '../../components/Navbar/navbar'
import api from '../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { NavLink } from 'react-router-dom'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { PiComputerTower } from "react-icons/pi";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ModeloMaquinaEdit = () => {
  const [nome, setNome] = useState('')
  const [descricao, setDescricao] = useState('')
  const [fornecedores, setFornecedores] = useState([])
  const [idFornecedor, setIdFornecedor] = useState(null)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    let cancel = false

    const fetchModelo = async () => {
      const { data } = await api.get(`/api/modeloMaquina/edit/${id}`)
      setNome(data.nome)
      setDescricao(data.descricao)
      setIdFornecedor({ value: data.id_fornecedor, label: data.fornecedor.nome })

      const res = await api.get('/api/fornecedor/index')
      if (cancel) return

      let fornecedoresData = []

      res.data.fornecedores.forEach(fornecedores => {
        fornecedoresData.push({
          value: fornecedores.id,
          label: fornecedores.nome
        })
      })

      setFornecedores(fornecedoresData)
    }

    fetchModelo()

    return () => {
      cancel = true
    }
  }, [id])

  const handleChangeFornecedor = (selectedOption) => {
    setIdFornecedor(selectedOption)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await api.put(`/api/modeloMaquina/${id}`, {
        nome,
        descricao,
        id_fornecedor: idFornecedor.value
      })
      toast.success('Modelo de máquina atualizado com sucesso!')
      navigate('/modelosMaquina')
    } catch (error) {
      toast.error('Erro ao atualizar modelo de máquina.')
    }
  }

  return (
    <>
      <NavBar />
      <div id='divContainer' className='container mt-3'>
        <div className='headerContainer editcreateMobile'>
          <div className='divheaderTittleMobile'>
            <div id='divHeaderTittle'><PiComputerTower className='fs-1' /><h3 id='tittleH2'> Editar Modelo Máquina</h3></div>
          </div>

          <div className='divbtnVoltarMobile'>
            <div>
              <NavLink to='/modelosMaquina' style={{ textDecoration: 'none' }}>
                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
              </NavLink>
            </div>
          </div>
        </div>

        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className='fw-semibold'>Nome</Form.Label>
            <Form.Control className="shadowCustom" type="text" placeholder="Nome" value={nome} onChange={e => setNome(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className='fw-semibold'>Descrição</Form.Label>
            <Form.Control className="shadowCustom" as="textarea" rows={3} placeholder="Descrição" value={descricao} onChange={e => setDescricao(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className='fw-semibold'>Fornecedor</Form.Label>
            <Select
              className='shadowCustom rounded-2'
              id="input-group-edit"
              value={idFornecedor}
              onChange={handleChangeFornecedor}
              options={fornecedores}
            />
          </Form.Group>
          <Row className='mb-3'>
            <Col xs lg="2">
              <Button className='btnCreateMobile' id='buttonNew' type="submit">
                Editar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default ModeloMaquinaEdit
