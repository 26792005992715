import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsBookHalf } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cookies from 'js-cookie'
import Table from 'react-bootstrap/Table'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [clientToAssociate, setClientToAssociate] = useState('')
    const [clients, setClients] = useState([])
    const [name, setName] = useState('')
    const [sistema, setSistema] = useState(false)
    const [upload, setUpload] = useState(false)
    const [permiteExclusao, setPermiteExclusao] = useState(false)
    const [bibliotecaPrivada, setBibliotecaPrivada] = useState(false)
    const [activated, setActivated] = useState(false)
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editBiblioteca = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (clientToAssociate === '') {
            toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
        } else {
            await api.put(`/api/biblioteca/${id}`, {
                ambienteToAssociate: Cookies.get('ambienteId'),
                clientToAssociate,
                name,
                sistema,
                upload,
                permiteExclusao,
                bibliotecaPrivada,
                ativo: activated,
            }).then(() => {
                setName('')
                toast.success('Biblioteca editada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/bibliotecas') })
            })
        }
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/biblioteca/${id}`).then(async (res) => {
                if (cancel) return

                setName(res.data.biblioteca.name)
                setSistema(res.data.biblioteca.sistema)
                setUpload(res.data.biblioteca.upload)
                setPermiteExclusao(res.data.biblioteca.permite_exclusao)
                setBibliotecaPrivada(res.data.biblioteca.biblioteca_privada)
                setActivated(res.data.biblioteca.ativo)

                await api.post(`api/client/getClientByAmbienteId`, {
                    ambiente: res.data.bibliotecaAmbiente
                }).then((res) => {
                    let clientsData = [{ label: 'Todos', value: 'todos' }]

                    res.data.clients.forEach(client => {
                        clientsData.push({
                            value: client.id,
                            label: client.name
                        })
                    })

                    setClients(clientsData)
                })
                setClientToAssociate(res.data.bibliotecaClient)
            })

            await api.post(`api/client/getClientByAmbienteId`, {
                ambiente: Cookies.get('ambienteId')
            }).then((res) => {
                let clientsData = [{ label: 'Todos', value: 'todos' }]

                res.data.clients.forEach(client => {
                    clientsData.push({
                        value: client.id,
                        label: client.name
                    })
                })

                setClients(clientsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BsBookHalf className='fs-1' /><h3 id='tittleH2'> Editar biblioteca</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/bibliotecas' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className='fw-semibold'>Clientes</Form.Label>
                                    <Select className='input-group-edit' id='input-group-edit' value={clientToAssociate} onChange={(e) => setClientToAssociate(e)} options={clients} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Table className='shadowCustom marginTopMobile' striped bordered>
                                    <thead>
                                        <tr>
                                            <th>Selecionar Permissões</th>
                                        </tr>
                                    </thead>
                                    <tbody className='border-1'>
                                        <tr>

                                            <label style={{ display: 'block', width: '100%', cursor: 'pointer' }}>
                                                <Form.Check
                                                    type='switch'
                                                    id='input-group-edit'
                                                    checked={sistema}
                                                    onChange={(e) => setSistema(e.target.checked)}
                                                    label='Sistema'
                                                />
                                            </label>

                                        </tr>
                                        <tr>

                                            <label style={{ display: 'block', width: '100%', cursor: 'pointer' }}>
                                                <Form.Check
                                                    type='switch'
                                                    id='input-group-edit'
                                                    checked={upload}
                                                    onChange={(e) => setUpload(e.target.checked)}
                                                    label='Permite upload'
                                                />
                                            </label>

                                        </tr>
                                        <tr>

                                            <label style={{ display: 'block', width: '100%', cursor: 'pointer' }}>
                                                <Form.Check
                                                    type='switch'
                                                    id='input-group-edit'
                                                    checked={permiteExclusao}
                                                    onChange={(e) => setPermiteExclusao(e.target.checked)}
                                                    label='Permite exclusão'
                                                />
                                            </label>

                                        </tr>
                                        <tr>

                                            <label style={{ display: 'block', width: '100%', cursor: 'pointer' }}>
                                                <Form.Check
                                                    type='switch'
                                                    id='input-group-edit'
                                                    checked={bibliotecaPrivada}
                                                    onChange={(e) => setBibliotecaPrivada(e.target.checked)}
                                                    label='Biblioteca privada'
                                                />
                                            </label>

                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editBiblioteca} id='buttonNew' type='submit'>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit