import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink, useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import { MdOutlineFactCheck } from 'react-icons/md'
import Cookies from 'js-cookie'
import PreviewLojista from '../../components/PreviewLojista'
import { FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineBlock } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import { CiMoneyCheck1 } from "react-icons/ci";
import { IoStorefrontOutline } from "react-icons/io5";
import { MdOutlinePreview } from "react-icons/md";

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const ValidationLojista = () => {
    const history = useNavigate()
    const [publicacao, setPublicacao] = useState('')
    const [dataInicio, setDataInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [horaInicio, setHoraInicio] = useState('')
    const [horaFim, setHoraFim] = useState('')
    const [userPermissions, setUserPermissions] = useState([])
    const [rejectReason, setRejectReason] = useState('')

    const customId = 'success-toast-id'

    let { id } = useParams()

    async function handleApprove(e) {
        e.preventDefault()

        await api.put(`/api/publicacaoLojistaValidation/approve/${id}`).then(() => {
            toast.success('Publicação aprovada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/publicacaoLojista') })
        })
    }

    async function handleReject(e) {
        e.preventDefault()

        if (rejectReason === '') {
            toast.error('Por favor informe o motivo da rejeição.', { autoClose: 3000 })
        } else {
            await api.put(`/api/publicacaoLojistaValidation/reject/${id}`, {
                rejection_reason: rejectReason
            }).then(() => {
                toast.success('Publicação rejeitada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/publicacaoLojista') })
            })
        }
    }

    const getPublicacao = async () => {
        const response = await api.get(`/api/publicacaoLojistaValidation/${id}`)
        setPublicacao(response.data)

        setDataInicio(response.data.publicacao.data_inicio.split(' ')[0])
        setHoraInicio(response.data.publicacao.data_inicio.split(' ')[1])

        if (response.data.publicacao.data_fim) {
            setDataFim(response.data.publicacao.data_fim.split(' ')[0])
            setHoraFim(response.data.publicacao.data_fim.split(' ')[1])
        }

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    useEffect(() => {
        getPublicacao()
    }, [])

    const [showFormRejeitar, setFormRejeitar] = useState(false);

    const toggleValidationLojista = () => {
        setFormRejeitar(!showFormRejeitar);
    };

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer'>
                    <div>
                        <div id='divHeaderTittle'><MdOutlineFactCheck className='fs-1' /><h3 id='tittleH2'> Validação</h3></div>
                    </div>

                    <div style={{ display: 'flex' }}>
                        <div>
                            <NavLink to='/publicacaoLojista' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Row>
                        <Col xs='6'>
                            <Table striped bordered className='shadowCustom'>
                                <thead className='bg-dark'>
                                    <tr>
                                        <th className='text-white text-center fs-5' colSpan={2}>
                                            <span className='d-flex align-items-center justify-content-center'>
                                                <IoStorefrontOutline style={{ marginRight: '0.5rem' }} />Lojas
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}>
                                            <span className='text-white fw-lighter'>{publicacao.lojas?.map(loja => `${loja}, `)}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Col>
                                                <InputGroup>
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Data inicio</InputGroup.Text>
                                                    <input
                                                        type='date'
                                                        name='data_inicio'
                                                        className='text-center form-control valid fw-semibold'
                                                        id='data_inicio'
                                                        aria-describedby='data_inicio'
                                                        aria-invalid='false'
                                                        disabled
                                                        required
                                                        value={dataInicio}
                                                    />
                                                </InputGroup>
                                                <InputGroup className='mt-3'>
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Hora inicio</InputGroup.Text>
                                                    <input
                                                        type='time'
                                                        name='hora_inicio'
                                                        step='1'
                                                        className='text-center form-control inteiro fw-semibold'
                                                        id='hora_inicio'
                                                        aria-describedby='hora_inicio'
                                                        required
                                                        disabled
                                                        value={horaInicio}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </td>
                                        <td>
                                            <Col>
                                                <InputGroup>

                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Data fim</InputGroup.Text>
                                                    <input
                                                        type='date'
                                                        name='data_fim'
                                                        className='text-center form-control valid fw-semibold'
                                                        id='data_fim'
                                                        aria-describedby='data_fim'
                                                        aria-invalid='false'
                                                        disabled
                                                        value={dataFim}
                                                    />
                                                </InputGroup>

                                                <InputGroup className='mt-3'>
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Hora fim</InputGroup.Text>
                                                    <input
                                                        type='time'
                                                        name='hora_fim'
                                                        step='1'
                                                        className='text-center form-control inteiro fw-semibold'
                                                        id='hora_fim'
                                                        disabled
                                                        aria-describedby='hora_fim'
                                                        value={horaFim}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col>
                            <Table striped bordered className='shadowCustom'>
                                <thead className='bg-dark'>
                                    <tr>
                                        <th colSpan={2}>
                                            <span className='d-flex align-items-center justify-content-center fs-5 text-white'>
                                                <MdOutlinePreview style={{marginRight: '0.5rem'}} />Preview
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <PreviewLojista material={{
                                                tipo: publicacao.publicacao?.id_template ? 'zip' : publicacao.url?.split('.')[publicacao.url?.split('.').length - 1] === 'mp4' ? 'video' : 'imagem',
                                                url: publicacao.url
                                            }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <div className='d-flex justify-content-center divMaquinasNovaPub bg-dark'>
                        <h6 className='text-white mt-2'>
                            <CiMoneyCheck1 className='fs-4' /> Aprovar a veiculação acima ?</h6>
                    </div>
                </Row>
                <Row className="mb-5 divMaquinasNovaPub shadowCustom">
                    <div className='d-flex justify-content-center mt-1 mb-1'>
                        <Button className='text-center m-1 btnAprovClass' onClick={(e) => handleApprove(e)}>
                            <FaRegCheckCircle className='fs-5' />
                            <span> Aprovar</span>
                        </Button>
                        <Button className='m-1 deleteButton' onClick={toggleValidationLojista}>
                            <MdOutlineBlock className='fs-5' />
                            <span> Rejeitar</span>
                        </Button>
                    </div>
                    <div>
                        {showFormRejeitar && (
                            <div>
                                <Form.Group className="mb-3">
                                    <Form.Label className='fw-semibold mt-1'>Informe abaixo o motivo para rejeitar a publicação</Form.Label>
                                    <Form.Control as="textarea" rows={3} value={rejectReason} onChange={(e) => setRejectReason(e.target.value)} />
                                </Form.Group>
                                <Button className='mb-2 btnGenClass' onClick={(e) => handleReject(e)}>
                                    <GoChecklist className='fs-5' /> Confirmar</Button>
                            </div>
                        )}
                    </div>
                </Row>
            </div>
        </>
    )
}

export default ValidationLojista