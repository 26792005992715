import React, { useState, useEffect } from 'react'
import api from '../../services/api'
import { useNavigate, Link, NavLink } from 'react-router-dom'
import { Offcanvas, Navbar, Container } from 'react-bootstrap'
import { TiThMenuOutline } from 'react-icons/ti'
import Cookies from 'js-cookie'
import { RiDashboard3Fill } from 'react-icons/ri'
import { HiUsers } from 'react-icons/hi'
import { FaUserLock, FaUsers, FaBook } from 'react-icons/fa'
import { BiSolidUserAccount, BiSolidBookContent, BiNews } from 'react-icons/bi'
import { BsFillCloudFill, BsFillGrid3X3GapFill, BsClipboardData, BsPlayBtnFill } from 'react-icons/bs'
import UserAvatarWithInitials from '../../components/UserAvatarWithInitials/UserAvatarWithInitials'
import Dropdown from 'react-bootstrap/Dropdown'
import DarkMode from '../DarkMode/DarkMode'
import { IoLocationSharp, IoCloudUpload } from 'react-icons/io5'
import { HiUserGroup } from 'react-icons/hi2'
import { HiTemplate } from 'react-icons/hi'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import Button from 'react-bootstrap/Button'
import { ToastContainer, toast } from 'react-toastify'
import AnimationTrocaAmbienteLottie from '../AnimationTrocaAmbienteLottie/AnimationTrocaAmbienteLottie'
import LogoPublicador from './logo_atmo_publicador.png'
import { IoGameController } from 'react-icons/io5'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import { MdOutlineVideoSettings, MdOutlineScreenshotMonitor } from 'react-icons/md'
import { FaRegListAlt } from 'react-icons/fa'
import { FaChartBar } from 'react-icons/fa'
import { CiServer } from 'react-icons/ci'
import { MdOutlineEditNote } from 'react-icons/md'
import { MdOutlineBarChart } from 'react-icons/md'
import { MdOutlineFitScreen } from 'react-icons/md'
import { BiMovie } from 'react-icons/bi'
import { MdOutlineBuild } from "react-icons/md";
import { HiOutlineCommandLine } from "react-icons/hi2";
import { PiComputerTowerBold } from "react-icons/pi";
import { FaTruckRampBox } from "react-icons/fa6";


import './styleNavbar.css'
import '../../index.css'
import '../MobileModeStyle/navbarMobileStyle.css'

export default function Sidebar() {

    const history = useNavigate()

    const handleLogout = async (e) => {
        e.preventDefault()

        try {
            api.post('/api/logout', { ambienteToken: Cookies.get('ambienteToken') })
                .then(response => {
                    console.log(response.data)
                    Cookies.remove('token')
                    Cookies.remove('userId')
                    Cookies.remove('ambienteToken')
                    Cookies.remove('lojista')

                    if (response.data.ambienteToken) {
                        history(`/login/${response.data.ambienteToken}`)
                    } else {
                        history('/login')
                    }

                })
                .catch(error => {
                    console.log(error)
                })

        } catch (error) {
            console.error('Erro ao fazer logout:', error)
        }
    }

    const [show, setShow] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [userPermissions, setUserPermissions] = useState([])
    const [ambiente, setAmbiente] = useState('')
    const [ambientes, setAmbientes] = useState([])
    const customId = 'success-toast-id'

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    const showEditUserPage = (userId) => {
        history(`/user/edit/${userId}`)
    }

    const getPermissions = async () => {
        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)
        setUserPermissions(responsePermissions.data.permissions)
        setAmbientes(responsePermissions.data.userAmbiente)
    }

    const changeEnvironment = async () => {
        if (ambiente === '') {
            toast.error('Por favor selecione um ambiente', { autoClose: 3000 })
        } else {
            await api.get(`api/userChangeAmbiente/${ambiente.value}`).then((res) => {
                Cookies.remove('ambienteId')
                Cookies.remove('ambienteLogoUrl')
                Cookies.remove('lojista')
                Cookies.set('ambienteId', ambiente.value, { expires: 7, secure: false })
                Cookies.set('ambienteLogoUrl', res.data.logoUrl, { expires: 7, secure: false })
                Cookies.set('lojista', res.data.lojista, { expires: 7, secure: false })
                setModalShow(false)
                history('/dashboard')
                window.location.reload()
            })
        }
    }

    const [handleButton, sethandleButton] = useState(true)

    useEffect(() => {
        const lojista = Cookies.get('lojista')

        if (lojista === '1') {
            sethandleButton(false)
        } else {
            sethandleButton(true)
        }
    }, [])

    function ChangeEnvironmentModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationTrocaAmbienteLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalChangeEnvironmentBody'>
                    <h4 className='divText'>Trocar Ambiente</h4>
                    <Form.Group className='mb-3 mt-3' style={{ width: '80%' }}>
                        <Select
                            id='input-group-edit'
                            value={ambiente}
                            onChange={(e) => setAmbiente(e)}
                            options={ambientes}
                            placeholder='Selecione o Ambiente...'
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={changeEnvironment}>Salvar</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    useEffect(() => {
        getPermissions()
    }, [])

    return (
        <>
            <Navbar>
                <ToastContainer />

                <ChangeEnvironmentModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <Container className='containerNavbar'>
                    <Navbar.Brand className='d-flex align-items-center' >
                        {handleButton && (
                            <div onClick={handleShow} className='button-canvas mx-auto px-3'>
                                <TiThMenuOutline className='TiThMenu'></TiThMenuOutline>
                            </div>
                        )}
                        <div className='divLogoNavbar'>
                            {
                                Cookies.get('ambienteLogoUrl') !== 'null' && (
                                    <NavLink to='/dashboard' className='text-decoration-none'>            
                                        <img className='imgNavbarMobile' id='thumb' style={{ height: '1.7rem' }} src={Cookies.get('ambienteLogoUrl')} alt='Logo Navbar' />
                                    </NavLink>
                                )
                            }
                        </div>
                    </Navbar.Brand>
                    <div className='d-flex align-items-center'>
                        <NavLink to='/publicacao/new' className='text-decoration-none'>
                            {handleButton && (
                                <button className='btnCriarPubNavbar'>
                                    <MdOutlineVideoSettings className='MdOutlineVideoSettingsClass' />
                                    <span className='spanCriarPubNavbar downloadDashButtonMobile'>Criar Publicação</span>
                                </button>
                            )}
                        </NavLink>
                        <DarkMode />
                        <Dropdown
                            drop='down-centered'
                            className='d-flex align-items-center'>
                            <Dropdown.Toggle variant='success' id='dropdown-basic' type='submit' className='btnUserAvatarWithInitials'>
                                <UserAvatarWithInitials id={Cookies.get('userId')} name={Cookies.get('userName')} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='profileMenu'>
                                <Dropdown.Item className='dropdown-item fw-semibold profileItem' onClick={() => showEditUserPage(Cookies.get('userId'))}>Perfil</Dropdown.Item>
                                {handleButton && (
                                    <Dropdown.Item className='dropdown-item fw-semibold profileItem' onClick={() => setModalShow(true)}>Trocar ambiente</Dropdown.Item>
                                )}
                                <Dropdown.Divider className='lineDividerDropPerfil' />
                                <Dropdown.Item onClick={handleLogout} className='dropdown-item text-danger fw-semibold profileItem'>Sair</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className='divImgNavbar'>
                            <img style={{ height: '40px', marginLeft: '20px' }} src={LogoPublicador} alt='atmoLogo'></img>
                        </div>
                    </div>
                </Container>
            </Navbar>
            <Offcanvas
                className='bg-color-sidebar'
                show={show}
                onHide={handleClose}
            >
                <Offcanvas.Header className='offCanvasHeader' closeButton>
                    <Offcanvas.Title className='offCanvasTitle'>
                        {
                            Cookies.get('ambienteLogoUrl') !== 'null' ? (
                                <img id='thumb' className='imgCanvasHeaderTitle' src={Cookies.get('ambienteLogoUrl')} alt='Logo Navbar' />
                            ) : 'ATMO PUBLICADOR'
                        }
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <hr className='hrLineDivider'></hr>
                <Offcanvas.Body>
                    <div>
                        <Link to='/dashboard' className='classLink'>
                            <div className='divLink'>
                                <h5 className='divText'><MdOutlineBarChart className='classIcon' />Dashboard</h5>
                            </div>
                        </Link>
                        <Link to='/publicacoes' className='classLink'>
                            <div className='divLink'>
                                <h5 className='divText'><BsPlayBtnFill className='classIcon' />Publicações</h5>
                            </div>
                        </Link>
                        {userPermissions.some((permission) => permission.name === 'index_local') ? (
                            <Link to='/locais' className='classLink'>
                                <div className='divLink'>
                                    <h5 className='divText'><IoLocationSharp className='classIcon' />Locais</h5>
                                </div>
                            </Link>) : (
                            ''
                        )}
                        <Link to='/grupos' className='classLink'>
                            <div className='divLink'>
                                <h5 className='divText'><HiUserGroup className='classIcon' />Grupos</h5>
                            </div>
                        </Link>
                        <Link to='/bibliotecas' className='classLink'>
                            <div className='divLink'>
                                <h5 className='divText'><FaBook className='classIcon' />Bibliotecas</h5>
                            </div>
                        </Link>
                        {userPermissions.some((permission) => permission.name === 'aprovacoes') ? (
                            <Dropdown
                                className='d-flex align-items-center'>
                                <Dropdown.Toggle variant='success' id='dropdown-basic' className='newsDropDown' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white', border: 'none', width: '100%', padding: 0, height: '53px' }}>
                                    <div className='divLink'>
                                        <h5 className='divText'><AiOutlineSafetyCertificate className='classIcon' />Aprovações</h5>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadowCustom borderClassMb' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white' }}>
                                    <Link to='/aprovacao' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><BsPlayBtnFill className='classIcon' />Publicação</Dropdown.Item>
                                    </Link>

                                    {userPermissions.some((permission) => permission.name === 'approver') ? (
                                        <Link to='/approvals' className='classLink'>
                                            <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><BiNews className='classIcon' />Noticías</Dropdown.Item>
                                        </Link>
                                    ) : (
                                        ''
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            ''
                        )}
                        {userPermissions.some((permission) => permission.name === 'index_template') ? (
                            <Dropdown
                                className='d-flex align-items-center'>
                                <Dropdown.Toggle variant='success' id='dropdown-basic' className='newsDropDown' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white', border: 'none', width: '100%', padding: 0, height: '53px' }}>
                                    <div className='divLink'>
                                        <h5 className='divText'><MdOutlineFitScreen className='classIcon' />Templates</h5>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadowCustom borderClassMb' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white' }}>
                                    <Link to='/templates' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><HiTemplate className='classIcon' />Templates</Dropdown.Item>
                                    </Link>

                                    <Link to='/template/padrao/index' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><BiMovie className='classIcon' />Templates DND</Dropdown.Item>
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            ''
                        )}
                        {userPermissions.some((permission) => permission.name === 'index_noticia') ? (
                            <Dropdown
                                className='d-flex align-items-center'>
                                <Dropdown.Toggle variant='success' id='dropdown-basic' className='newsDropDown' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white', border: 'none', width: '100%', padding: 0, height: '53px' }}>
                                    <div className='divLink'>
                                        <h5 className='divText'><BiNews className='classIcon' />Notícias</h5>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadowCustom borderClassMb' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white' }}>
                                    <Link to='/noticias' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><BiNews className='classIcon' />Notícias</Dropdown.Item>
                                    </Link>

                                    <Link to='/provedores' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><CiServer className='classIcon' />Provedores</Dropdown.Item>
                                    </Link>

                                    <Link to='/editorias' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><MdOutlineEditNote className='classIcon' />Editorias</Dropdown.Item>
                                    </Link>

                                </Dropdown.Menu>

                            </Dropdown>
                        ) : (
                            ''
                        )}
                        {userPermissions.some((permission) => permission.name === 'index_relatorio') ? (
                            <Dropdown
                                className='d-flex align-items-center'>
                                <Dropdown.Toggle variant='success' id='dropdown-basic' className='newsDropDown' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white', border: 'none', width: '100%', padding: 0, height: '53px' }}>
                                    <div className='divLink'>
                                        <h5 className='divText'><FaRegListAlt className='classIcon' />Relatórios</h5>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadowCustom borderClassMb' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white' }}>
                                    <Link to='/dashboardPublicacao' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><FaChartBar className='classIcon' />Publicação</Dropdown.Item>
                                    </Link>

                                    <Link to='/maquinas/screenshots' className='classLink'>
                                        <Dropdown.Item className='profileItem' style={{ color: document.querySelector('body').getAttribute('data-theme') === 'dark' ? 'white' : '#212529' }} as='button'><MdOutlineScreenshotMonitor className='classIcon' />Screenshots</Dropdown.Item>
                                    </Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            ''
                        )}
                        {userPermissions.some((permission) => permission.name === 'administracao') ? (
                            <Dropdown
                                className='d-flex align-items-center'>
                                <Dropdown.Toggle variant='success' id='dropdown-basic' className='newsDropDown' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white', border: 'none', width: '100%', padding: 0, height: '53px' }}>
                                    <div className='divLink'>
                                        <h5 className='divText'><MdOutlineBuild className='classIcon' />Administração</h5>
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className='shadowCustom borderClassMb' style={{ backgroundColor: document.querySelector('body').getAttribute('data-theme') === 'dark' ? '#212529' : 'white' }}>

                                    <Link to='/ambientes' className='classLink'>
                                        <div className='divLink'>
                                            <h5 className='divText'><BsFillCloudFill className='classIcon' />Ambientes</h5>
                                        </div>
                                    </Link>

                                    <Link to='/clientes' className='classLink'>
                                        <div className='divLink'>
                                            <h5 className='divText'><HiUsers className='classIcon' />Clientes</h5>
                                        </div>
                                    </Link>

                                    <Link to='/conteudo' className='classLink'>
                                        <div className='divLink'>
                                            <h5 className='divText'><BiSolidBookContent className='classIcon' />Conteúdo</h5>
                                        </div>
                                    </Link>
                                    {userPermissions.some((permission) => permission.name === 'index_permission') ? (
                                        <Link to='/permissions' className='classLink'>
                                            <div className='divLink'>
                                                <h5 className='divText'><FaUserLock className='classIcon' />Permissões</h5>
                                            </div>
                                        </Link>
                                    ) : (
                                        ''
                                    )}
                                    {userPermissions.some((permission) => permission.name === 'index_role') ? (
                                        <Link to='/roles' className='classLink'>
                                            <div className='divLink'>
                                                <h5 className='divText'><BiSolidUserAccount className='classIcon' />Perfis</h5>
                                            </div>
                                        </Link>
                                    ) : (
                                        ''
                                    )}
                                    <Link to='/users' className='classLink'>
                                        <div className='divLink'>
                                            <h5 className='divText'><FaUsers className='classIcon' />Usuários</h5>
                                        </div>
                                    </Link>
                                    <Link to='/grades' className='classLink'>
                                        <div className='divLink'>
                                            <h5 className='divText'><BsFillGrid3X3GapFill className='classIcon' />Grades</h5>
                                        </div>
                                    </Link>

                                    <Link to='/uploadExcel' className='classLink'>
                                        <div className='divLink'>
                                            <h5 className='divText'><IoCloudUpload className='classIcon' />Upload Excel</h5>
                                        </div>
                                    </Link>

                                    {userPermissions.some((permission) => permission.name === 'activity_logs') ? (
                                        <Link to='/logs' className='classLink'>
                                            <div className='divLink'>
                                                <h5 className='divText'><BsClipboardData className='classIcon' />Logs</h5>
                                            </div>
                                        </Link>
                                    ) : (
                                        ''
                                    )}

                                    {userPermissions.some((permission) => permission.name === 'commands') ? (
                                        <>
                                            <Link to='/comandos' className='classLink'>
                                                <div className='divLink'>
                                                    <h5 className='divText'><IoGameController className='classIcon' />Comandos</h5>
                                                </div>
                                            </Link>

                                            <Link to='/comandosMaquina' className='classLink'>
                                                <div className='divLink'>
                                                    <h5 className='divText'><HiOutlineCommandLine className='classIcon' />Comandos Máquina</h5>
                                                </div>
                                            </Link>
                                        </>
                                    ) : (
                                        ''
                                    )}

{userPermissions.some((permission) => permission.name === 'index_fornecedor') ? (
                                    <Link to='/fornecedores' className='classLink'>
                                        <div className='divLink'>
                                                <h5 className='divText'><FaTruckRampBox className='classIcon' />Fornecedores</h5>
                                        </div>
                                    </Link>
                                ) : (
                                    ''
                                )}

                                <Link to='/modelosMaquina' className='classLink'>
                                    <div className='divLink'>
                                            <h5 className='divText'><PiComputerTowerBold className='classIcon' />Modelos Maquina</h5>
                                    </div>
                                </Link>

                                </Dropdown.Menu>
                            </Dropdown>
                        ) : (
                            ''
                        )}

                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}


