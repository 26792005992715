import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsBookHalf } from 'react-icons/bs'
import { BiLayerPlus } from "react-icons/bi";
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import Spinner from 'react-bootstrap/Spinner';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginMediaPreview,
    FilePondPluginFileMetadata,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize)

const EditMaterial = () => {
    const history = useNavigate()
    const [arquivoFile, setArquivoFile] = useState('')
    const [produtoLojista, setProdutoLojista] = useState(false)
    const [productName, setProductName] = useState('')
    const [productCode, setProductCode] = useState('')
    const [linha, setLinha] = useState('')
    const [marca, setMarca] = useState('')
    const [peso, setPeso] = useState('')

    const customId = 'success-toast-id'
    let { id, idMaterial } = useParams()

    const onFileChange = (files) => {
        if (files.length > 0) {
            setArquivoFile(files[0].file)
        } else {
            setArquivoFile(null)
        }
    }
    //Logica de bloquear o botao até atualizar a tela
    const [isLoading, setIsLoading] = useState(false);

    const editMaterial = async (e) => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);

        e.preventDefault()

        if (produtoLojista && (productName === '' || productCode === '' || linha === '' || marca === '' || peso === '')) {
            toast.error('Por favor preencha todos os campos do produto lojista.', { autoClose: 3000 })
        } else {
            const formData = new FormData()
            formData.append('arquivo', arquivoFile)
            formData.append('id_biblioteca', id)
            formData.append('id_material', idMaterial)
            formData.append('product_name', productName)
            formData.append('product_code', productCode)
            formData.append('linha', linha)
            formData.append('marca', marca)
            formData.append('produto_lojista', produtoLojista)
            formData.append('peso', peso)

            try {
                await api.post('/api/material/update', formData)

                toast.success(`Material "${arquivoFile.name}" editado com sucesso!`, {
                    toastId: customId,
                    autoClose: 3000,
                    onClose: history(`/bibliotecas/show/${id}`),
                })
            } catch (error) {
                toast.error('Erro ao editar material.', {
                    toastId: customId,
                    autoClose: 3000,
                })
                console.error(error)
            }
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/material/${idMaterial}`).then(async (res) => {
                if (cancel) return

                setProdutoLojista(res.data.material.produto_lojista)

                if (res.data.produto) {
                    setProductName(res.data.produto.nome)
                    setProductCode(res.data.produto.codigo_interno)
                    setLinha(res.data.produto.linha)
                    setMarca(res.data.produto.marca)
                    setPeso(res.data.produto.peso)
                }
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [idMaterial])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BsBookHalf className='fs-1' /><h3 id='tittleH2'> Editar material</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <Button id='buttonBack' onClick={() => history(`/bibliotecas/show/${id}`)}><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='rowGenForm'>
                            <Col>
                                <Table className='shadowCustom' striped bordered>
                                    <tbody className='border-1'>
                                        <tr>
                                            <label style={{ display: 'block', width: '100%', cursor: 'pointer' }}>
                                                <Form.Check
                                                    className='fw-semibold'
                                                    type='switch'
                                                    id='produtoLojista'
                                                    checked={produtoLojista}
                                                    onChange={(e) => setProdutoLojista(e.target.checked)}
                                                    label='Produto Lojista ?'
                                                    style={{ marginTop: '5px', marginBottom: '5px' }}
                                                />
                                            </label>
                                        </tr>
                                    </tbody>
                                </Table>

                                {
                                    produtoLojista ? (
                                        <div>
                                            <Form.Group className='mb-3' controlid='formBasicProductName'>
                                                <Form.Label className='fw-semibold'>Nome do produto</Form.Label>
                                                <Form.Control className='input-group-edit' id='productName' type='text' placeholder='nome' value={productName} onChange={(e) => setProductName(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className='mb-3' controlid='formBasicProductName'>
                                                <Form.Label className='fw-semibold'>Código Interno</Form.Label>
                                                <Form.Control className='input-group-edit' id='productCode' type='text' placeholder='código' value={productCode} onChange={(e) => setProductCode(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className='mb-3' controlid='formBasicLinha'>
                                                <Form.Label className='fw-semibold'>Linha</Form.Label>
                                                <Form.Control className='input-group-edit' id='linha' type='text' placeholder='linha' value={linha} onChange={(e) => setLinha(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className='mb-3' controlid='formBasicPeso'>
                                                <Form.Label className='fw-semibold'>Peso</Form.Label>
                                                <Form.Control className='input-group-edit' id='peso' type='text' placeholder='peso' value={peso} onChange={(e) => setPeso(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group className='mb-3' controlid='formBasicMarca'>
                                                <Form.Label className='fw-semibold'>Marca</Form.Label>
                                                <Form.Control className='input-group-edit' id='marca' type='text' placeholder='marca' value={marca} onChange={(e) => setMarca(e.target.value)} />
                                            </Form.Group>
                                        </div>
                                    ) : ''
                                }
                            </Col>
                            <Col>
                                <Table className='shadowCustom' striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th><BiLayerPlus className='fs-4 m-1' />Adicionar Material</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div >
                                                    <FilePond
                                                        labelIdle='<span class="filepond--label-action filepond--drop-label">Arraste e solte seus arquivos ou clique aqui para selecionar</span>'
                                                        files={arquivoFile ? [{ source: arquivoFile }] : []}
                                                        onupdatefiles={onFileChange}
                                                        allowMultiple={false}
                                                        maxFiles={1}
                                                        acceptedFileTypes={['image/*', 'video/*', 'application/zip', 'application/x-zip-compressed']}
                                                        maxFileSize="200MB"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                {/* <Button onClick={editMaterial}>
                                    Salvar
                                </Button> */}
                                <Button className='btnCreateMobile' onClick={editMaterial} disabled={isLoading} variant='success' id='buttonNew'>
                                    {isLoading ? (
                                        <>
                                            <Spinner animation="border" />
                                        </>
                                    ) : (
                                        'Salvar'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default EditMaterial