import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { FaUserCog } from 'react-icons/fa'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [activated, setActivated] = useState(false)
    const [lojista, setLojista] = useState(false)
    const [consultor, setConsultor] = useState(false)
    const [roles, setRoles] = useState([])
    const [clients, setClients] = useState([])
    const [clientsToAssociate, setClientsToAssociate] = useState([])
    const [ambientes, setAmbientes] = useState([])
    const [ambientesToAssociate, setAmbientesToAssociate] = useState([])
    const [locais, setLocais] = useState([])
    const [locaisToAssociate, setLocaisToAssociate] = useState('')
    const [rolesToAssociate, setRolesToAssociate] = useState([])
    const [selectAll, setSelectAll] = useState(false)
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editUser = async (e) => {
        e.preventDefault()

        if (name === '' || email === '') {
            toast.error('Por favor preencha todos os campos.', { autoClose: 3000 })
        } else if (email.indexOf('@') === -1) {
            toast.error('O campo email deve conter o caractere @.', { autoClose: 3000 })
        } else if (password !== confPassword) {
            toast.error('Senha e confirmação de senha não conferem!', { autoClose: 3000 })

            setPassword('')
            setConfPassword('')
        } else if (password && password.length < 6) {
            toast.error('A senha deve possuir no mínimo 6 caracteres.', { autoClose: 3000 })

            setPassword('')
            setConfPassword('')
        } else {
            await api.put(`/api/user/${id}`, {
                name,
                email,
                password,
                active: activated,
                rolesToAssociate,
                ambientesToAssociate,
                clientsToAssociate,
                locaisToAssociate,
                lojista,
                consultor
            }).then(() => {
                setName('')
                setEmail('')
                setPassword('')
                setConfPassword('')
                toast.success('Usuário editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/users') })
            })
        }

    }

    const handleChangeAmbiente = async (e) => {
        setAmbientesToAssociate(e)

        await api.post(`api/client/getClientByAmbientesIds`, {
            ambientes: e
        }).then((res) => {
            let clientsData = []

            res.data.clients.forEach(client => {
                clientsData.push({
                    value: client.id,
                    label: client.name
                })
            })

            setClients(clientsData)
        })
    }

    const handleAtmoUser = async (e) => {
        setAmbientesToAssociate(e)

        await api.post(`api/client/getClientByAmbientesIds`, {
            ambientes: e
        }).then((res) => {
            let clientsData = []

            res.data.clients.forEach(client => {
                clientsData.push({
                    value: client.id,
                    label: client.name
                })
            })

            setClientsToAssociate(clientsData)
        })
    }

    const handleChangeClient = async (e) => {
        setClientsToAssociate(e)

        await api.post(`api/local/getLocaisByClientsIds`, {
            clients: e
        }).then((res) => {
            let locaisData = []

            res.data.locais.forEach(local => {
                locaisData.push({
                    value: local.id,
                    label: local.nome
                })
            })

            setLocais(locaisData)
        })
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            handleAtmoUser(ambientes);
        } else {
            setAmbientesToAssociate([]);
            setClientsToAssociate([]);
        }
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/user/${id}`).then(async (res) => {
                if (cancel) return

                setName(res.data.user.name)
                setEmail(res.data.user.email)
                setActivated(res.data.user.active)
                setLojista(res.data.user.lojista)
                setConsultor(res.data.user.consultor)
                setLocaisToAssociate(res.data.userLocais)

                let ambientesData = []

                res.data.ambientes.forEach(ambiente => {
                    ambientesData.push({
                        value: ambiente.id,
                        label: ambiente.name
                    })
                })

                setAmbientes(ambientesData)
                setAmbientesToAssociate(res.data.userAmbiente)

                await api.post(`api/client/getClientByAmbientesIds`, {
                    ambientes: res.data.userAmbiente
                }).then((res) => {
                    let clientsData = []

                    res.data.clients.forEach(client => {
                        clientsData.push({
                            value: client.id,
                            label: client.name
                        })
                    })

                    setClients(clientsData)
                })

                let rolesData = []

                res.data.roles.forEach(role => {
                    rolesData.push({
                        value: role.id,
                        label: role.name
                    })
                })

                setRolesToAssociate(rolesData)

                setClientsToAssociate(res.data.userClient)

                await api.post(`api/local/getLocaisByClientsIds`, {
                    clients: res.data.userClient
                }).then((res) => {
                    let locaisData = []

                    res.data.locais.forEach(local => {
                        locaisData.push({
                            value: local.id,
                            label: local.nome
                        })
                    })

                    setLocais(locaisData)
                })
            })

            await api.get(`api/role/index`).then(res => {
                if (cancel) return

                let rolesData = []

                res.data.roles.forEach(role => {
                    rolesData.push({
                        value: role.id,
                        label: role.name
                    })
                })

                setRoles(rolesData)
            })

        }

        fetchData()

        return () => {
            cancel = true
        }
        // eslint-disable-next-line
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><FaUserCog className='fs-1' /><h3 id='tittleH2'> Edição de usuário</h3></div>
                    </div>
                    <div className='divbtnVoltarMobile'>
                        <NavLink to='/users' style={{ textDecoration: 'none' }}>
                            <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </NavLink>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='formGenMobile'>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicEmail'>
                                    <Form.Label className='fw-semibold'>Email</Form.Label>
                                    <Form.Control className='input-group-edit' type='email' placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicPassword'>
                                    <Form.Label className='fw-semibold'>Senha</Form.Label>
                                    <Form.Control className='input-group-edit' type='password' placeholder='senha' value={password} onChange={(e) => setPassword(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicConfirmationPassword'>
                                    <Form.Label className='fw-semibold'>Confirmação de senha</Form.Label>
                                    <Form.Control className='input-group-edit' type='password' placeholder='confirmação de senha' value={confPassword} onChange={(e) => setConfPassword(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Perfis</Form.Label>
                                    <Select className='input-group-edit' value={rolesToAssociate} onChange={(e) => setRolesToAssociate(e)} options={roles} isMulti={true} />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Ambientes</Form.Label>
                                    <Select className='input-group-edit' value={ambientesToAssociate} onChange={(e) => handleChangeAmbiente(e)} options={ambientes} isMulti={true} />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Clientes</Form.Label>
                                    <Select className='input-group-edit' value={clientsToAssociate} onChange={(e) => handleChangeClient(e)} options={clients} isMulti={true} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    id='lojista'
                                    checked={lojista}
                                    onChange={(e) => setLojista(e.target.checked)}
                                    label='Lojista?'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Check 
                                    type='switch'
                                    id='consultor'
                                    checked={consultor}
                                    onChange={ (e) => setConsultor(e.target.checked) }
                                    label='Consultor?'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>   
                        </Row>

                        <Row>
                            <Col>
                                <Form.Check 
                                    type='switch'
                                    id='selectAllSwitch'
                                    checked={selectAll}
                                    onChange={(e) => handleSelectAll(e)}
                                    label='Atmo?'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>   
                        </Row>

                        {
                            lojista || consultor ? (
                                <Col>
                                    <Form.Group className='mb-3'>
                                        <Form.Label className='fw-semibold'>Locais</Form.Label>
                                        <Select className='input-group-edit' value={locaisToAssociate} onChange={(e) => setLocaisToAssociate(e)} options={locais} isMulti={true} />
                                    </Form.Group>
                                </Col>
                            ) : ''
                        }

                        <Row>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    id='activeUser'
                                    checked={activated}
                                    onChange={(e) => setActivated(e.target.checked)}
                                    label='Ativar/Desativar'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editUser} id='buttonNew' type='submit'>Editar</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit