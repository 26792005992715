import Lottie from "lottie-react";
import animationForgotPassword from "./AnimationForgotPassword.json";

const style = {
    width: 200,
};

const AnimationForgotPassword = () => {
    return <Lottie loop={true} style={style} animationData={animationForgotPassword} />;
};

export default AnimationForgotPassword;