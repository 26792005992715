import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { Badge } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import AnimationPubLottie from './AnimationPubLottie/AnimationPubLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { RxOpenInNewWindow } from 'react-icons/rx'
import moment from 'moment'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { FaCheckDouble } from 'react-icons/fa6'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import { GoBlocked } from 'react-icons/go'
import { IoMdStopwatch } from 'react-icons/io'
import { LuFolderInput } from 'react-icons/lu'
import { BsBroadcast } from "react-icons/bs";
import { LuGalleryVerticalEnd } from 'react-icons/lu'
import { Tooltip } from 'react-tooltip'
import { IoCalendarOutline } from 'react-icons/io5'
import { FaRegListAlt } from 'react-icons/fa'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import timeGridPlugin from '@fullcalendar/timegrid'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { MdOutlinePermMedia } from 'react-icons/md'
import { PiIdentificationCard } from 'react-icons/pi'
import '@fullcalendar/core/locales-all'
import '@fullcalendar/core/locales/pt-br'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { MdOutlineLocalGroceryStore } from 'react-icons/md'
import { MdStorefront } from 'react-icons/md'
import { LuComputer } from 'react-icons/lu'
import { RiVoiceRecognitionLine } from 'react-icons/ri'
import { FaArrowRotateRight } from 'react-icons/fa6'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { LuLayoutTemplate } from 'react-icons/lu'
import { BiWindows } from 'react-icons/bi'
import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/Preview'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'
import '../../components/MobileModeStyle/calendarioMobileStyle.css'
import PreviewLojista from '../../components/PreviewLojista'

const Index = () => {
    const history = useNavigate()
    const [publicacoes, setPublicacoes] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [publicacaoIdToDelete, setPublicacaoIdToDelete] = useState('')
    const [publicacaoNameToDelete, setPublicacaoNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [publicacoesPerPage] = useState(10)
    const indexOfLastPublicacao = currentPage * publicacoesPerPage
    const indexOfFirstPublicacao = indexOfLastPublicacao - publicacoesPerPage
    const currentPublicacoes = publicacoes.slice(indexOfFirstPublicacao, indexOfLastPublicacao)
    const nPages = Math.ceil(publicacoes.length / publicacoesPerPage)
    const [materialPreview, setMaterialPreview] = useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [selectedPublicacao, setSelectedPublicacao] = useState(null)
    const [modalCalendarShow, setModalCalendarShow] = useState(false)
    const [fullscreen, setFullscreen] = useState(true)

    const getPublicacoes = async () => {
        const response = await api.get('/api/publicacaoLojista/index')
        setPublicacoes(response.data.publicacoes)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeletePublicacaoConfirmationModal = (publicacaoId, publicacaoName) => {
        setModalShow(true)
        setPublicacaoIdToDelete(publicacaoId)
        setPublicacaoNameToDelete(publicacaoName)
    }

    const deletePublicacao = async (publicacaoId) => {
        await api.delete(`/api/publicacaoLojista/${publicacaoId}`).then(() => {
            getPublicacoes()
            setModalShow(false)
            toast.success('Publicação deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function handlePreviewInfos(props) {
        setMaterialPreview(props)
        handleShow()
    }

    function handleFilterPubs(type) {
        setFilter(type)
    }

    function DeletePublicacaoConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de publicacao.</h4>
                    <p>
                        Você tem certeza de que deseja remover a publicacao {props.publicacaoname} ?
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deletePublicacao(props.publicacaoid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditPublicacaoPage = (publicacaoId) => {
        history(`/publicacaoLojista/edit/${publicacaoId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    const filteredPublicacoes = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (filter === 'APROVADA') {
            if (search !== '') {
                return publicacoes.filter(publicacao => publicacao.status_aprovacao === 'APROVADA' && (publicacao.id.toString().toLowerCase().includes(lowerSearch) || publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch)))
            } else {
                return publicacoes.filter(publicacao => publicacao.status_aprovacao === 'APROVADA')
            }
        } if (filter === 'REJEITADA') {
            if (search !== '') {
                return publicacoes.filter(publicacao => publicacao.status_aprovacao === 'REJEITADA' && (publicacao.id.toString().toLowerCase().includes(lowerSearch) || publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch)))
            } else {
                return publicacoes.filter(publicacao => publicacao.status_aprovacao === 'REJEITADA')
            }
        } if (filter === 'PENDENTE') {
            if (search !== '') {
                return publicacoes.filter(publicacao => publicacao.status_aprovacao === 'PENDENTE' && (publicacao.id.toString().toLowerCase().includes(lowerSearch) || publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch)))
            } else {
                return publicacoes.filter(publicacao => publicacao.status_aprovacao === 'PENDENTE')
            }
        } if (filter === 'ARQUIVADA') {
            if (search !== '') {
                return publicacoes.filter(publicacao => publicacao.data_fim && new Date(publicacao.data_fim) < new Date() && (publicacao.id.toString().toLowerCase().includes(lowerSearch) || publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch)))
            } else {
                return publicacoes.filter(publicacao => publicacao.data_fim && new Date(publicacao.data_fim) < new Date())
            }
        } if (filter === 'VEICULANDO') {
            if (search !== '') {
                return publicacoes.filter(publicacao => new Date(publicacao.data_inicio) < new Date() && (new Date(publicacao.data_fim) > new Date() || publicacao.data_fim === null) && publicacao.status_aprovacao === 'APROVADA' && (publicacao.id.toString().toLowerCase().includes(lowerSearch) || publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch)))
            } else {
                return publicacoes.filter(publicacao => new Date(publicacao.data_inicio) < new Date() && (new Date(publicacao.data_fim) > new Date() || publicacao.data_fim === null) && publicacao.status_aprovacao === 'APROVADA')
            }
        } else if (search !== '') {
            return publicacoes.filter((publicacao) => publicacao.id.toString().toLowerCase().includes(lowerSearch) || publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch))
        } else {
            return currentPublicacoes
        }
    }, [search, publicacoes, currentPublicacoes, filter])

    useEffect(() => {
        getPublicacoes()
    }, [])

    const openModal = (publicacao) => {
        setSelectedPublicacao(publicacao)
        setModalCalendarShow(true)
    }

    const [viewMode, setViewMode] = useState('tabela') // Estado para controlar o modo de exibição (tabela ou calendário)

    const toggleViewMode = (mode) => {
        setViewMode(mode)
    }

    const [rotation, setRotation] = useState(0)

    const rotateLeft = () => {
        setRotation(prevRotation => prevRotation - 90) // Girar 90 graus para a esquerda
    }

    const rotateRight = () => {
        setRotation(prevRotation => prevRotation + 90) // Girar 90 graus para a direita
    }

    const [isPressed, setIsPressed] = useState(false)

    const handleButtonClick = () => {
        setIsPressed(prevState => !prevState)
    }

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <DeletePublicacaoConfirmationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    publicacaoid={publicacaoIdToDelete}
                    publicacaoname={publicacaoNameToDelete}
                />

                <div className='headerContainer'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><AnimationPubLottie /><h3 id='tittleH2'>Publicações</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group'>
                        <InputGroup id='input-group' className='input-group'>
                            <InputGroup.Text id='search'><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder='pesquisar'
                                aria-label='pesquisar'
                                aria-describedby='search'
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>

                        {
                            userPermissions.some((permission) => permission.name === 'store_pub') ? (
                                <NavLink className='btnVoltarMobile' to='/publicacaoLojista/new' style={{ textDecoration: 'none' }}>
                                    <Button className='borderbtnCreateEdit' id='buttonNew'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Nova Publicação</span></Button>
                                </NavLink>
                            ) : ''
                        }
                    </div>
                </div>

                <ToastContainer />

                <div className='container overflow-auto mt-2'>
                    <ButtonGroup className='mb-2'>
                        <Button variant='secondary' className='d-flex align-items-center' onClick={() => toggleViewMode('tabela')} active={viewMode === 'tabela'}><FaRegListAlt className='fs-5 me-2' />Tabela</Button>
                        <Button variant='secondary' className='d-flex align-items-center' onClick={() => toggleViewMode('calendario')} active={viewMode === 'calendario'}><IoCalendarOutline className='fs-5 me-2' />Calendário</Button>
                    </ButtonGroup>
                    { viewMode === 'tabela' && (
                        <div>
                            <div className='divShadowClass'>
                                <div className='d-flex'>
                                    <ButtonGroup className='w-100'>
                                        <Button className={`btnColortxt rounded-bottom-0 borderClass1 ${isPressed ? 'pressed' : ''}`} variant='light'
                                            onClick={() => {
                                                handleButtonClick()
                                                handleFilterPubs('')
                                            }}>
                                            <LuGalleryVerticalEnd className='fs-5' /> Todos
                                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>{publicacoes.length}</span>
                                        </Button>
                                        <Button className='btnColortxt borderClass2' variant='success' onClick={() => handleFilterPubs('APROVADA')}>
                                            <IoMdCheckmarkCircleOutline className='fs-4 text-success' /> Aprovadas
                                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>{publicacoes.filter(publicacao => publicacao.status_aprovacao === 'APROVADA').length}</span>
                                        </Button>
                                        <Button className='btnColortxt borderClass2' variant='danger' onClick={() => handleFilterPubs('REJEITADA')}>
                                            <GoBlocked className='fs-4 text-danger' /> Rejeitadas
                                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>{publicacoes.filter(publicacao => publicacao.status_aprovacao === 'REJEITADA').length}</span>
                                        </Button>
                                        <Button className='btnColortxt borderClass2' variant='warning' onClick={() => handleFilterPubs('PENDENTE')}>
                                            <IoMdStopwatch className='fs-4 text-warning' /> Pendentes
                                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>{publicacoes.filter(publicacao => publicacao.status_aprovacao === 'PENDENTE').length}</span>
                                        </Button>
                                        <Button className='btnColortxt borderClass2' variant='secondary' onClick={() => handleFilterPubs('ARQUIVADA')}>
                                            <LuFolderInput className='fs-4 text-secondary' /> Arquivadas
                                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>{publicacoes.filter(publicacao => publicacao.data_fim && new Date(publicacao.data_fim) < new Date()).length}</span>
                                        </Button>
                                        <Button className='btnColortxt rounded-bottom-0 borderClass3' onClick={() => handleFilterPubs('VEICULANDO')}>
                                            <BsBroadcast className='fs-4 text-primary' /> Veiculando
                                            <span style={{ marginLeft: '0.5rem', fontWeight: 'bolder' }}>{(publicacao => new Date(publicacao.data_inicio) < new Date() && (new Date(publicacao.data_fim) > new Date() || publicacao.data_fim === null) && publicacao.status_aprovacao === 'APROVADA').length}</span>
                                        </Button>
                                    </ButtonGroup>
                                </div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>#ID</th>
                                            <th className='text-center d-flex justify-content-center'>
                                                <div className='w-100 d-flex justify-content-evenly'>
                                                    <span className='spanClassMaterial d-flex align-items-center'><MdOutlinePermMedia className='m-1' />Material</span><span className='spanClassTemplate d-flex align-items-center'><LuLayoutTemplate className='m-1' />Template</span>
                                                </div>
                                            </th>
                                            <th className='text-center'>Produto</th>
                                            <th className='text-center'>Loja</th>
                                            <th className='text-center'>Maquinas</th>
                                            <th className='text-center text-nowrap'>Data/Hora inicio</th>
                                            <th className='text-center'>Data/Hora fim</th>
                                            <th className='text-center'>Situação</th>
                                            <th className='text-center'>Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPublicacoes.map((publicacao, index) => (
                                            <tr
                                                key={publicacao.id}
                                                className={publicacao.ativo ? '' : 'table-danger'}
                                            >
                                                <td className='text-center'>{publicacao.id}</td>
                                                <td className='text-center'>
                                                    <div className='d-flex justify-content-center'>
                                                        <button className={publicacao.url_template ? 'btnMaterialTemplate p-1 fw-medium d-flex' : 'btnMaterialTemplate p-1 fw-medium d-flex'} onClick={() => handlePreviewInfos({
                                                            tipo: publicacao.url_template ? 'zip' : publicacao.url_material.split('.')[publicacao.url_material.split('.').length - 1] === 'mp4' ? 'video' : 'imagem',
                                                            url: publicacao.url_template ?? publicacao.url_material
                                                        })} >
                                                            {publicacao.url_template ? <LuLayoutTemplate className='colorIconTemplate' /> : <MdOutlinePermMedia className='colorIconMaterial' />}
                                                            {publicacao.nome_material ? publicacao.nome_material : publicacao.nome_template}</button>
                                                    </div>
                                                </td>

                                                <td className='text-center'>{publicacao.nome_produto ?? 'Sem produto'}</td>
                                                <td className='text-center'>{publicacao.loja ?? 'Sem loja'}</td>
                                                <td className='text-center'>
                                                    <Badge
                                                        bg='secondary'
                                                        data-tooltip-id='Maquinas'
                                                        data-tooltip-content={publicacao.maquinas}
                                                        data-tooltip-place='right'
                                                    >{publicacao.maquinas.length}
                                                    </Badge>
                                                    <Tooltip id='Maquinas' />
                                                </td>
                                                <td className='text-center'>
                                                    <Badge className='bg-opacity-50 fs-6 fw-medium badgeCustomDataInicioFim'>
                                                        <div>
                                                            <div className='mb-1'>
                                                                {moment(publicacao.data_inicio).format('DD/MM/YYYY')}</div>
                                                            <div>
                                                                {moment(publicacao.data_inicio).format('HH[h]mm[min]')}</div>
                                                        </div>
                                                    </Badge>
                                                </td>
                                                <td className='text-center'>
                                                    <Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>
                                                        <div>
                                                            {publicacao.data_fim ? (
                                                                <>
                                                                    <div className='mb-1'>
                                                                        {moment(publicacao.data_fim).format('DD/MM/YYYY')}
                                                                    </div>
                                                                    <div>
                                                                        {moment(publicacao.data_fim).format('HH[h]mm[min]')}
                                                                    </div>
                                                                </>
                                                            ) : 'Sem data fim'}
                                                        </div>
                                                    </Badge>
                                                </td>
                                                <td className='text-center'>{publicacao.status_aprovacao ?? 'SEM STATUS'}</td>
                                                <td className='text-center'>
                                                    <div>
                                                        <div className='d-flex justify-content-evenly'>
                                                            <Button
                                                                className='btnColorAprov'
                                                                data-tooltip-id='Aprovar'
                                                                data-tooltip-content='Aprovar'
                                                                data-tooltip-place='top'
                                                                onClick={() => history(`/publicacaoLojista/validationLojista/${publicacao.id}`)}
                                                                disabled={publicacao.status_aprovacao === 'PENDENTE' ? false : true}
                                                            >
                                                                <FaCheckDouble />
                                                                <Tooltip id='Aprovar' />
                                                            </Button>
                                                            {
                                                                userPermissions.some((permission) => permission.name === 'edit_pub') ? (
                                                                    <Button
                                                                        className='editButton'
                                                                        data-tooltip-id='Editar'
                                                                        data-tooltip-content='Editar'
                                                                        data-tooltip-place='top'
                                                                        onClick={() => showEditPublicacaoPage(publicacao.id)}
                                                                        disabled={publicacao.status_aprovacao === 'PENDENTE' ? false : true}
                                                                    >
                                                                        <BsPencilSquare />
                                                                        <Tooltip id='Editar' />
                                                                    </Button>
                                                                ) : ''
                                                            }

                                                            {
                                                                userPermissions.some((permission) => permission.name === 'delete_pub') ? (
                                                                    <Button
                                                                        className='deleteButton'
                                                                        data-tooltip-id='Excluir'
                                                                        data-tooltip-content='Excluir'
                                                                        data-tooltip-place='top'
                                                                        onClick={() => showDeletePublicacaoConfirmationModal(publicacao.id, publicacao.id)}>
                                                                        <FaTrash />
                                                                        <Tooltip id='Excluir' />
                                                                    </Button>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            <Pagination
                                nPages={nPages}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                    ) }
                    { viewMode === 'calendario' && (
                        <div className='mt-2 mb-4 widthMobileCalendar'>
                            <FullCalendar
                                viewClassNames='colorFullCalendar'
                                eventClick={(eventInfo) => openModal(eventInfo)}
                                locale='pt-br'
                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin]}
                                initialView='dayGridMonth'
                                dayMaxEvents='true'
                                dayMaxEventRows={10}
                                themeSystem='bootstrap5'
                                navLinks='true'
                                events={filteredPublicacoes.map(publicacao => ({
                                    title: `${publicacao.nome_template ? publicacao.nome_template : publicacao.nome_material}`,
                                    start: publicacao.data_inicio,
                                    end: publicacao.data_fim,
                                    customInfo: { id_pub: publicacao.id, startDate: publicacao.data_inicio, hasEnd: publicacao.data_fim ? true : false, situacao: publicacao.status_aprovacao, produto: publicacao.nome_produto, loja: publicacao.loja, maquinas: publicacao.maquinas, material: publicacao.url_material, template: publicacao.url_template }
                                }))}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                }}
                                buttonText={{
                                    today: 'Hoje',
                                    month: 'Mês',
                                    week: 'Semana',
                                    day: 'Dia'
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Modal show={show} onHide={handleClose} fullscreen={fullscreen}>
                <Modal.Header closeButton>
                    <Modal.Title className='textColors d-flex align-items-center'><BiWindows className='fs-4 me-2' />Preview</Modal.Title>
                    <div className='divArrowRotate shadowCustom'>
                        <FaArrowRotateLeft className='arrowDirectionDivModal arrowRotateRigth m-1' onClick={rotateLeft} />
                        <FaArrowRotateRight className='arrowDirectionDivModal arrowRotateLeft m-1' onClick={rotateRight} />
                    </div>
                </Modal.Header>
                <Modal.Body className='d-flex align-items-center justify-content-center'>
                    <div className='rotated-div' style={{ transform: `rotate(${rotation}deg)`, width: '70%' }}>
                        <PreviewLojista material={materialPreview} />
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={modalCalendarShow} onHide={() => setModalCalendarShow(false)}>
                <Modal.Header closeButton className='bg-dark text-white'>
                    <Modal.Title className='d-flex align-items-center'><BiMessageSquareDetail className='me-2' />Detalhes da Publicação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { selectedPublicacao && (
                        <div>
                            <div className='d-flex justify-content-end'>
                                <button className={selectedPublicacao.url_template ? 'shadowCustom btnPreviewModalCalendar' : 'shadowCustom btnPreviewModalCalendar'} onClick={() => handlePreviewInfos({
                                    tipo: selectedPublicacao.event._def.extendedProps.customInfo.template ? 'zip' : (selectedPublicacao.event._def.extendedProps.customInfo.material && selectedPublicacao.event._def.extendedProps.customInfo.material.split('.')[selectedPublicacao.event._def.extendedProps.customInfo.material.split('.').length - 1] === 'mp4') ? 'video' : 'imagem',
                                    url: selectedPublicacao.event._def.extendedProps.customInfo.template ?? selectedPublicacao.event._def.extendedProps.customInfo.material
                                })} >Preview
                                    <RxOpenInNewWindow className='m-1' />
                                    {selectedPublicacao.nome_material ? selectedPublicacao.nome_material : selectedPublicacao.nome_template}</button>
                            </div>
                            <div className='divMaquinasNovaPub rounded-1 p-1'>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <PiIdentificationCard className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>#ID:</label>
                                    {selectedPublicacao.event._def.extendedProps.customInfo.id_pub}
                                </p>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <MdOutlinePermMedia className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Material/Template:</label>
                                    {selectedPublicacao.event._def.title}
                                </p>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <MdOutlineLocalGroceryStore className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Produto:</label>
                                    {selectedPublicacao.event._def.extendedProps.customInfo.produto ?? 'Sem produto'}
                                </p>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <MdStorefront className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Loja:</label>
                                    {selectedPublicacao.event._def.extendedProps.customInfo.loja ?? 'Sem loja'}
                                </p>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <LuComputer className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Maquinas:</label>
                                    {selectedPublicacao.event._def.extendedProps.customInfo.maquinas ?? 'Sem máquinas'}
                                </p>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <IoCalendarOutline className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Data/Hora Início:</label>
                                    {moment(selectedPublicacao.event._def.extendedProps.customInfo.startDate).format('DD/MM/YYYY [|] HH[h]mm[min]')}
                                </p>
                                <p className='fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <IoCalendarOutline className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Data/Hora Fim:</label>
                                    {selectedPublicacao.event._def.extendedProps.customInfo.hasEnd ? (
                                        moment(selectedPublicacao.event._instance.range.end).format('DD/MM/YYYY [|] HH[h]mm[min]')
                                    ) : (
                                        <span className='text-danger'>Sem data fim.</span>
                                    )}
                                </p>
                                <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'>
                                    <RiVoiceRecognitionLine className='me-1 fs-5' />
                                    <label className='fw-medium me-2'>Situação:</label>
                                    {selectedPublicacao.event._def.extendedProps.customInfo.situacao ?? 'Sem situação'}
                                </p>
                            </div>
                            <div className='d-flex justify-content-between mt-2 divModalMobileCalendar'>
                                {/* <OverlayTrigger trigger='click' placement='left' overlay={popover} className='testeeeee'>
                                    <Button onClick={() => setDetails(selectedPublicacao.event._def.extendedProps.customInfo.segmentacao)} className='shadowCustom fw-medium' variant='secondary'><IoMdArrowDropleft />Segmentação
                                    </Button>
                                </OverlayTrigger>
                                {/* <div className='divBtnGrpMobileCalendar'>
                                    {
                                        userPermissions.some((permission) => permission.name === 'edit_pub') ? (
                                            <Button
                                                className='editButton'
                                                data-tooltip-id='Editar'
                                                data-tooltip-content='Editar'
                                                data-tooltip-place='top'
                                                onClick={() => showEditPublicacaoPage(selectedPublicacao.event._def.extendedProps.customInfo.id_pub)}
                                                disabled={selectedPublicacao.event._def.extendedProps.customInfo.situacao === 'PENDENTE' ? false : true}
                                            >
                                                <BsPencilSquare />
                                                <Tooltip id='Editar' />
                                                Editar
                                            </Button>
                                        ) : ''
                                    }

                                    {
                                        userPermissions.some((permission) => permission.name === 'delete_pub') ? (
                                            <Button
                                                className='deleteButton'
                                                data-tooltip-id='Excluir'
                                                data-tooltip-content='Excluir'
                                                data-tooltip-place='top'
                                                onClick={() => showDeletePublicacaoConfirmationModal(selectedPublicacao.event._def.extendedProps.customInfo.id_pub)}>
                                                <FaTrash />
                                                <Tooltip id='Excluir' />
                                                Excluir
                                            </Button>
                                        ) : ''
                                    }
                                </div> */}
                            </div>
                        </div>
                    ) }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Index