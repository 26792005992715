import Lottie from "lottie-react";
import animationGradesLottie from "./gradesAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationGradesLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationGradesLottie} />;
};

export default AnimationGradesLottie;