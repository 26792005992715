import Lottie from "lottie-react";
import animationGruposLottie from "./gruposAnimation.json";

const style = {
    height: 70,
    width: 60,
};

const AnimationGruposLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationGruposLottie} />;
};

export default AnimationGruposLottie;