import Lottie from "lottie-react";
import animationComandosMaquinaLottie from "./comandosMaquinaAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationComandosMaquinaLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationComandosMaquinaLottie} />;
};

export default AnimationComandosMaquinaLottie;