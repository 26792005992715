import React, { useEffect, useState, useMemo } from 'react'
import api from '../../services/api.js'
import NavBar from '../../components/Navbar/navbar.js'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Table from 'react-bootstrap/Table'
import { AiOutlineSearch } from 'react-icons/ai'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import { FaFileAlt } from 'react-icons/fa'
import Badge from 'react-bootstrap/Badge'
import Cookies from 'js-cookie'

import '../../components/styleComponents/styleDashPubContainer.css'

const RelatorioDashboardPublicacao = () => {
    const [search, setSearch] = useState('')
    const [pubs, setPubs] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [pubsPerPage] = useState(10)
    const indexOfLastPub = currentPage * pubsPerPage
    const indexOfFirstPub = indexOfLastPub - pubsPerPage
    const currentPubs = pubs.slice(indexOfFirstPub, indexOfLastPub)
    const nPages = Math.ceil(pubs.length / pubsPerPage)

    const navigate = useNavigate()

    const handleRelatorioPubVoltar = () => {
        navigate('/dashboardPublicacao')
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/publicacoesDashboard/${Cookies.get('ambienteId')}`).then(async (res) => {
                if (cancel) return
    
                setPubs(res.data.publicacoes)
            })
        }

        fetchData()

        return () => { 
            cancel = true
        }
    }, [])


    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    const handleDownload = async () => {
        try {
            const response = await api.get(`/api/downloadPubs/${Cookies.get('ambienteId')}`, {
                responseType: 'blob',
            })
        
            const fileName = response.headers['x-filename'] || response.headers['X-Filename'] || 'default_filename.xlsx'
        
            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }))
        
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
        
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
        } catch (error) {
            console.error(error)
        }
    }

    const filteredPublicacoes = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentPubs
        } else {
            return pubs.filter((pub) => pub.id.toString().toLowerCase().includes(lowerSearch) || pub.nome_template?.toLowerCase().includes(lowerSearch) || pub.nome_material?.toLowerCase().includes(lowerSearch))
        }
    }, [search, currentPubs, pubs])

    return (
        <>
            <NavBar />
            <div id='divContainer' className='container'>
                <div className='headerContainer d-flex justify-content-between widthCLass'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><FaFileAlt className='fs-1' /><h3 id='tittleH2'> Relatório de Publicações</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group divMobiledlfexclass widthCLass'>
                        <div>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id='search'><AiOutlineSearch /></InputGroup.Text>
                                <Form.Control
                                    placeholder='Pesquisar'
                                    aria-label='pesquisar'
                                    aria-describedby='search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>
                        </div>
                        <div className='d-flex gap-2 divMobileGap widthCLass'>
                            <Button className='downloadDashButton' onClick={handleDownload}>
                                <HiOutlineDocumentDownload className='fs-4' />
                                <span>Download</span>
                            </Button>
                            <Button className='btnVoltarMobile' id='buttonBack' onClick={handleRelatorioPubVoltar}>
                                <BsArrowReturnLeft style={{ marginRight: '3px' }} />
                                <span>Voltar</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='container overflow-auto mt-2'>
                    <Table id='tableList' striped bordered hover>
                        <thead>
                            <tr id='trList'>
                                <th className='text-center'>#ID</th>
                                <th className='text-center d-flex justify-content-center'>
                                    <div className='w-100 d-flex justify-content-evenly'>
                                        <span className='spanClassMaterial'>Material</span><span className='spanClassTemplate'>Template</span>
                                    </div>
                                </th>
                                <th className='text-center'>Data/inicio</th>
                                <th className='text-center'>Data/fim</th>
                                <th className='text-center'>Segmentação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPublicacoes.map((pub, index) => (
                                <tr key={pub.id}>
                                    <td className='text-center'>{ pub.id }</td>
                                    <td className='text-center'>{ pub.nome_template ? <button className='shadowCustom btnMaterialTemplate p-2 fw-medium spanClassTemplate'>{pub.nome_template}</button> : <button className='shadowCustom btnMaterialTemplate p-2 fw-medium spanClassMaterial'>{pub.nome_material}</button> }</td>
                                    <td className='text-center'>{ <Badge className='bg-opacity-50 fs-6 fw-medium badgeCustomDataInicioFim'>{pub.data_inicio}</Badge> }</td>
                                    <td className='text-center'>{ <Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{pub.data_fim ?? 'Sem data fim'}</Badge> }</td>
                                    <td className='text-center'> { pub.maquinas.map(maquina => (<Badge key={maquina.pivot.id_maquina} className='bg-opacity-50 fs-6 fw-medium badgeCustomDataInicioFim mt-2'>{`MAQUINA - ${maquina.name}`}</Badge>)) } { pub.locais.map(local => (<Badge key={local.pivot.id_local} className='bg-opacity-50 fs-6 fw-medium badgeCustomDataInicioFim mt-2'>{`LOCAL - ${local.nome}`}</Badge>)) } { pub.grupos.map(grupo => (<Badge key={grupo.pivot.id_grupo} className='bg-opacity-50 fs-6 fw-medium badgeCustomDataInicioFim mt-2'>{`GRUPO - ${grupo.nome}`}</Badge>)) } </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </>
    )
}

export default RelatorioDashboardPublicacao