import Lottie from "lottie-react";
import animationConteudoLottie from "./conteudoAnimation.json";

const style = {
    height: 70,
    width: 60,
};

const AnimationConteudoLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationConteudoLottie} />;
};

export default AnimationConteudoLottie;