import React, { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import api from '../../services/api.js'
import { Button, Form, Label, Input, Container, Alert } from 'reactstrap'
import Cookies from 'js-cookie'
import atmoLogo from './atmo publicador (3).png'
import WhatsAppButton from "./WhatsAppButton/WhatsAppButton.js";
import { TbLockX } from "react-icons/tb";

import './style.css'
import './stylebackground.css'
function LoginLojista() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showSpinner, setShowSpinner] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    let { routeToken } = useParams()

    const handleLogin = async (e) => {
        e.preventDefault()
        setShowSpinner(true);

        try {
            const response = await api.post(`/api/authenticate`, {
                email,
                password,
                routeToken
            })

            const token = response.data.token

            Cookies.set("token", token, { expires: 7, secure: false })
            Cookies.set("userId", response.data.userId, { expires: 7, secure: false })
            Cookies.set("userName", response.data.userName, { expires: 7, secure: false })
            Cookies.set("ambienteId", response.data.ambienteId, { expires: 7, secure: false })
            Cookies.set("ambienteLogoUrl", response.data.ambienteLogoUrl, { expires: 7, secure: false })
            Cookies.set("ambienteToken", response.data.ambienteToken, { expires: 7, secure: false })
            Cookies.set("lojista", response.data.lojista, { expires: 7, secure: false })
            navigate("/publicacaoLojista")

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setError("E-mail ou senha incorretos."); // Define a mensagem de erro para credenciais inválidas
            } else {
                setError("Erro ao fazer login."); // Outros erros
            }
            console.error('Erro ao fazer login:', error)
        } finally {
            setShowSpinner(false);
        }
    }

    const handleClearError = () => {
        setError(null);
    };

    const handleForgotPassword = () => {
        navigate("/passwordResetLink");
    };

    return (
        <>
            <div className='backgroundColorLoginLojista d-flex align-items-center'>
                    
                    <Container className='containerLoginLojista'>
                        <div className='d-flex flex-column align-items-center'>
                            <div className='divSizeImgLogo'>
                                <img className='sizeImgLogo' src={atmoLogo} alt='Logo Atmo' />
                            </div>
                            <div className='borderCustomLogin'>
                                <div className='d-flex justify-content-center divtxtLogin'>
                                    <label className='labeltxtLogin'>Login</label>
                                </div>
                                <div className='divInputLogin'>
                                    <Label for="email"></Label>
                                    <Input
                                        className='inputScale'
                                        placeholder='Email'
                                        type="email"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                        onClick={handleClearError}
                                    />
                                    <Form onSubmit={handleLogin}>
                                        <Label for="password"></Label>
                                        <Input
                                            className='inputScale'
                                            placeholder='Senha'
                                            type="password"
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                            onClick={handleClearError}
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                        />

                                    </Form>
                                    {error && <Alert className='alertClass d-flex align-items-center' color="danger"><TbLockX className='fs-5 marginAlertClass' />{error}</Alert>}
                                </div>
                                <div className='divbtnEntrarLogin'>
                                    <Button className='btnEntrarLogin' type="submit" onClick={handleLogin} disabled={showSpinner}>{showSpinner ? <div className="spinner-border spinner-border-sm" role="status"></div> : <div className='fw-semibold'>Entrar</div>}</Button>
                                </div>
                            </div>

                        </div>
                        <div className='divforgotPwd'>
                            <Label className='forgotPwdBtn' id='forgotPassword' onClick={handleForgotPassword}>Esqueceu a senha ?</Label>
                        </div>
                    </Container>
                <div className='div-whatsapp-button'>
                    <WhatsAppButton />
                </div>
            </div>
        </>
    )
}

export default LoginLojista
