import Lottie from "lottie-react";
import fornecedorAnimationLottie from "./fornecedorAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationFornecedorLottie = () => {
    return <Lottie loop={true} style={style} animationData={fornecedorAnimationLottie} />;
};

export default AnimationFornecedorLottie;