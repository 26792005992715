import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import NavBar from '../../components/Navbar/navbar'
import api from '../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NavLink } from 'react-router-dom'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { FaTruckRampBox } from "react-icons/fa6";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Edit = () => {
  const [nome, setNome] = useState('')
  const [contato, setContato] = useState('')
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchFornecedor = async () => {
      const { data } = await api.get(`/api/fornecedor/edit/${id}`)
      setNome(data.nome)
      setContato(data.contato)
    }
    fetchFornecedor()
  }, [id])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await api.put(`/api/fornecedor/${id}`, { nome, contato })
      toast.success('Fornecedor atualizado com sucesso!')
      navigate('/fornecedores')
    } catch (error) {
      toast.error('Erro ao atualizar fornecedor.')
    }
  }

  return (
    <>
      <NavBar />
      <div className='container mt-3'>
        <div className='headerContainer editcreateMobile'>
          <div className='divheaderTittleMobile'>
            <div id='divHeaderTittle'><FaTruckRampBox className='fs-1' /><h3 id='tittleH2'> Editar Fornecedor</h3></div>
          </div>

          <div className='divbtnVoltarMobile'>
            <div>
              <NavLink to='/fornecedores' style={{ textDecoration: 'none' }}>
                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
              </NavLink>
            </div>
          </div>
        </div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label className='fw-semibold'>Nome</Form.Label>
            <Form.Control className="shadowCustom" type="text" placeholder="Enter name" value={nome} onChange={e => setNome(e.target.value)} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className='fw-semibold'>Contato</Form.Label>
            <Form.Control className="shadowCustom" type="text" placeholder="Enter contact" value={contato} onChange={e => setContato(e.target.value)} required />
          </Form.Group>
          <Row className='mb-3'>
            <Col xs lg="2">
              <Button className='btnCreateMobile' id='buttonNew' type="submit">
                Editar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  )
}

export default Edit
