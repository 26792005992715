import Lottie from "lottie-react";
import animationModeloMaquinasLottie from "./maquinasModeloAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationModeloMaquinasLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationModeloMaquinasLottie} />;
};

export default AnimationModeloMaquinasLottie;