import Lottie from "lottie-react";
import animationNoticiasLottie from "./noticiasAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationNoticiasLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationNoticiasLottie} />;
};

export default AnimationNoticiasLottie;