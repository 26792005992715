import Lottie from "lottie-react";
import animationMateriaisLottie from "./materiaisAnimation.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationMateriaisLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationMateriaisLottie} />;
};

export default AnimationMateriaisLottie;