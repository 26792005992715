import Lottie from "lottie-react";
import animationTrocaAmbienteLottie from "./trocaAmbienteAnimation.json";

const style = {
    width: 200,
    height: 200,
};

const AnimationTrocaAmbienteLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationTrocaAmbienteLottie} />;
};

export default AnimationTrocaAmbienteLottie;