import Table from 'react-bootstrap/Table'

const MachineDetails2 = ({ data }) => {

    return (
        <div className='container overflow-auto mt-2' style={{ height: '50vh' }}>
            <Table id='tableList' striped bordered hover>
                <thead>
                    <tr id="trList">
                        <th className='text-center'>Tipo</th>
                        <th className='text-center'>Data</th>
                        <th className='text-center'>IP</th>
                    </tr>
                </thead>
                <tbody>
                    {data.comunicacoes.map((item, index) => (
                        <tr key={index}>
                            <td className='text-center'>{item.tipo_comunicacao}</td>
                            <td className='text-center'>{item.data}</td>
                            <td className='text-center'>{item.ip}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}
  
export default MachineDetails2