import Lottie from "lottie-react";
import animationLocaisLottie from "./locaisAnimation.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationLocaisLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationLocaisLottie} />;
};

export default AnimationLocaisLottie;