import React, { useEffect, useState } from 'react'
import api from '../../services/api.js'
import NavBar from '../../components/Navbar/navbar.js'
import Button from 'react-bootstrap/Button'
import AnimationDashPubLottie from './AnimationDashLottie/AnimationDashPubLottie.js'
import ChartVideosFotosPub from './ChartjsDashPubComponents/chartVideoFotos.js'
import ChartTemplatesPub from './ChartjsDashPubComponents/chartTemplatesPub.js'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import '../../components/styleComponents/styleDashPubContainer.css'

const DashboardPublicacao = () => {
    const [totalMateriais, setTotalMateriais] = useState('')
    const [labelsMateriais, setLabelsMateriais] = useState('')
    const [qtdMateriais, setQtdMateriais] = useState('')
    
    const [totalTemplates, setTotalTemplates] = useState('')
    const [labelsTemplates, setLabelsTemplates] = useState('')
    const [qtdTemplates, setQtdTemplates] = useState('')

    const navigate = useNavigate()

    const handleRelatorioPub = () => {
        navigate('/dashboardPublicacao/relatorioPublicacao')
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/publicacoesDashboard/${Cookies.get('ambienteId')}`).then(async (res) => {
                if (cancel) return
        
                setTotalMateriais(res.data.totalMateriais)
                setLabelsMateriais(res.data.graphMateriaisLabels)
                setQtdMateriais(res.data.graphMateriaisQtds)

                setTotalTemplates(res.data.totalTemplates)
                setLabelsTemplates(res.data.graphTemplatesLabels)
                setQtdTemplates(res.data.graphTemplatesQtds)
            })
        }

        fetchData()

        return () => { 
            cancel = true
        }
    }, [])

    return (
        <>
            <NavBar />
            <div id='divContainer' className='headerContainer container'>
                <div className='divheaderTittleMobile'>
                    <div id='divHeaderTittle'><AnimationDashPubLottie /><h3 id='tittleH2'>Dashboard</h3><h3 id='tittleH2'>Publicações</h3></div>
                </div>
                <div className='w-100 d-grid justify-content-end'>
                    <Button className='btnRelatorioDashPubClass d-flex align-items-center' onClick={handleRelatorioPub}>
                        <HiOutlineDocumentReport className='fs-5' /><label>Relatório</label>
                    </Button>
                </div>
            </div>
            <div className='container overflow-auto mt-2'>
                <Row className='rowGapCLass'>
                    <Col>
                        <div>
                            <ChartVideosFotosPub total={totalMateriais} labels={labelsMateriais} qtd={qtdMateriais} />
                        </div>
                    </Col>
                    <Col>
                        <div>
                            <ChartTemplatesPub total={totalTemplates} labels={labelsTemplates} qtd={qtdTemplates} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default DashboardPublicacao