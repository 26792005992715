import Lottie from "lottie-react";
import animationDashboardLottie from "./dashboardAnimation.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationDashboardLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationDashboardLottie} />;
};

export default AnimationDashboardLottie;