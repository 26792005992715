import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsNewspaper } from 'react-icons/bs'
import { BsCloudPlusFill } from "react-icons/bs";
import { FaTrash } from 'react-icons/fa'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import { BiLayerPlus } from "react-icons/bi";
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [link, setLink] = useState('')
    const [validade, setValidade] = useState(0)
    const [allEditorias, setAllEditorias] = useState([])
    const [selectedEditoria, setSelectedEditoria] = useState('')
    const [texto, setTexto] = useState('')
    const [selectedAmbientes, setSelectedAmbientes] = useState([])
    const [allAmbientes, setAllAmbientes] = useState([])
    const [templateToAssociate, setTemplateToAssociate] = useState('')
    const [material, setMaterial] = useState('')

    const customId = 'success-toast-id'
    let { id } = useParams()

    const editNoticia = async (e) => {
        e.preventDefault()

        if (texto === '') {
            toast.error('Por favor preencha o campo de texto.', { autoClose: 3000 })
        } else if (link === '') {
            toast.error('Por favor preencha o campo de link da imagem.', { autoClose: 3000 })
        } else if (selectedEditoria.length === 0) {
            toast.error('Por favor selecione uma editoria.', { autoClose: 3000 })
        } else if (selectedAmbientes.length === 0) {
            toast.error('Por favor selecione pelo menos um ambiente.', { autoClose: 3000 })
        } else {
            await api.put(`/api/noticia/${id}`, {
                imagem: link,
                id_editoria: selectedEditoria,
                validade,
                texto,
                ambientes: selectedAmbientes,
                validade
            }).then(() => {
                setTexto('')
                toast.success('Noticia editada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/noticias') })
            })
        }

    }

    const handleSelectAllAmbientes = (e) => {
        e.preventDefault()

        setSelectedAmbientes(allAmbientes)
    }

    const handleRemoveAllAmbientes = (e) => {
        e.preventDefault()

        setSelectedAmbientes([])
    }

    const isValidLink = (link) => {
        return link && /^https?:\/\/.+/i.test(link);
    };

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/noticia/${id}`).then((res) => {
                if (cancel) return

                setLink(res.data.noticia.imagem)
                setAllEditorias(res.data.allEditorias)
                setSelectedEditoria(res.data.associatedEditoria)
                setTexto(res.data.noticia.texto)
                setAllAmbientes(res.data.allAmbientes)
                setSelectedAmbientes(res.data.associatedAmbientes)
                setValidade(res.data.validade)
            })

            await api.get(`api/conteudos/getConteudoNoticia/${Cookies.get("ambienteId")}`).then((res) => {
                if (cancel) return
                
                setMaterial(res.data.material)
                setTemplateToAssociate(res.data.templates[0])
            })

        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BsNewspaper className='fs-1' /><h3 id='tittleH2'> Edição de Notícia</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/noticias' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicImage'>
                                    <Form.Label className='fw-semibold'>Link da imagem</Form.Label>
                                    <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='link' value={link} onChange={(e) => setLink(e.target.value)} />
                                </Form.Group>
                                <Form.Group className='mb-3' controlid='formBasicValidade'>
                                    <Form.Label className='fw-semibold'>Validade em horas</Form.Label>
                                    <Form.Control className='input-group-edit' id='input-group-edit' type='number' placeholder='validade' value={validade} onChange={(e) => setValidade(e.target.value)} min="0" step="1" />
                                </Form.Group>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Editoria</Form.Label>
                                    <Select className='input-group-edit' value={selectedEditoria} onChange={(e) => setSelectedEditoria(e)} options={allEditorias} isMulti={false} />
                                </Form.Group>
                                <Form.Group className='mb-3' controlid='formBasicTexto'>
                                    <Form.Label className='fw-semibold'>Texto</Form.Label>
                                    <Form.Control className='input-group-edit' as="textarea" id='input-group-edit' type='text' placeholder='texto' value={texto} onChange={(e) => setTexto(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Table className='shadowCustom' striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className='d-flex justify-content-between flexDirectionMobile'>
                                                <div>
                                                    <BiLayerPlus className='fs-4 m-1' />
                                                    Adicionar Ambientes
                                                </div>
                                                <ButtonGroup>
                                                    <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Adicionar todos os ambientes" onClick={(e) => handleSelectAllAmbientes(e)}><BsCloudPlusFill className='fs-4' /> Todos os ambientes</Button>
                                                    <Button data-bs-toggle="tooltip" data-bs-placement="top" title="Excluir tudo" variant='danger' onClick={(e) => handleRemoveAllAmbientes(e)}><FaTrash className='fs-5' /></Button>
                                                </ButtonGroup>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Form.Group className='mb-3'>
                                                    <Select className='input-group-edit text-black' value={selectedAmbientes} onChange={(e) => setSelectedAmbientes(e)} options={allAmbientes} isMulti={true} />
                                                </Form.Group>
                                            </td>
                                        </tr>
                                        {isValidLink(link) && (
                                            <tr>
                                                <td>
                                                    {material?.id != null ? 
                                                        (
                                                            <iframe
                                                                title='iframeTemplate'
                                                                style={{
                                                                    width: '100%',
                                                                    height: '200px',
                                                                    marginBottom: '5px',
                                                                    border: 'none',
                                                                }}
                                                                src={`${templateToAssociate.arquivo}foto=${link}&texto=${texto}&editoria=${selectedEditoria.label}`}
                                                            />
                                                        )
                                                        :
                                                        (
                                                            <img
                                                                className="rounded mx-auto d-block"
                                                                style={{ alignContent: 'center', maxWidth: '100%', maxHeight: '210px' }}
                                                                src={link}
                                                                alt="Preview da imagem selecionada"
                                                            />
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg='2'>
                                <Button className='btnCreateMobile' onClick={editNoticia} id='buttonNew' type='submit'>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit