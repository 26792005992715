import Lottie from "lottie-react";
import animationTemplatesLottie from "./templatesAnimation.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationTemplatesLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationTemplatesLottie} />;
};

export default AnimationTemplatesLottie;