import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsPlayBtnFill } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import { MdLibraryAdd } from "react-icons/md"
import InputGroup from 'react-bootstrap/InputGroup'
import { IoHourglass, IoDocumentTextOutline } from "react-icons/io5"
import { Spinner } from 'react-bootstrap'
import io from 'socket.io-client'
import Modal from 'react-bootstrap/Modal'
import { BiLayerPlus } from "react-icons/bi"
import { FilePond } from 'react-filepond'
import { SiMaterialdesignicons } from "react-icons/si"
import { IoMdImages } from "react-icons/io"
import { TbTemplate } from "react-icons/tb"
import { PiLineSegmentsBold } from "react-icons/pi"
import { LuFileQuestion } from "react-icons/lu"
import { FaFileCirclePlus } from "react-icons/fa6"
import { FaRegFileVideo, FaRegListAlt } from "react-icons/fa"

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [arquivoFile, setArquivoFile] = useState('')
    const [biblioteca, setBiblioteca] = useState([])
    const [tipoMaterial, setTipoMaterial] = useState('')
    const [materiaisToAssociate, setMateriaisToAssociate] = useState('')
    const [materiais, setMateriais] = useState([])
    const [templatesToAssociate, setTemplatesToAssociate] = useState('')
    const [templates, setTemplates] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [dolar, setDolar] = useState(false)
    const [euro, setEuro] = useState(false)
    const [min, setMin] = useState(false)
    const [max, setMax] = useState(false)
    const [activated, setActivated] = useState(false)
    const [produtoLojista] = useState(false)
    const [productName] = useState('')
    const [productCode] = useState('')
    const [linha] = useState('')
    const [marca] = useState('')
    const [peso] = useState('')
    const [selectedBibliotecaId, setSelectedBibliotecaId] = useState('')
    const [conteudosToAssociate, setConteudosToAssociate] = useState('')
    const [conteudos, setConteudos] = useState([])
    const [selecaoToAssociate, setSelecaoToAssociate] = useState([])
    const [gruposToAssociate, setGruposToAssociate] = useState([])
    const [grupos, setGrupos] = useState([])
    const [locaisToAssociate, setLocaisToAssociate] = useState([])
    const [locais, setLocais] = useState([])
    const [maquinasToAssociate, setMaquinasToAssociate] = useState([])
    const [maquinas, setMaquinas] = useState([])
    const [dataInicio, setDataInicio] = useState('')
    const [horaInicio, setHoraInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [horaFim, setHoraFim] = useState('')
    const [doSpaces, setDoSpaces] = useState('')
    const [templateData, setTemplateData] = useState({})
    const [queryString, setQueryString] = useState('')
    const [iframeUrl, setIframeUrl] = useState('')
    const [templateInfos, setTemplateInfos] = useState([])
    const customId = 'success-toast-id'
    const [botaoHabilitado, setBotaoHabilitado] = useState(true)
    const [exibindoSpinner, setExibindoSpinner] = useState(false)
    const [showModal, setShowModal] = useState(false)
    let { id } = useParams()

    const onFileChange = (files) => {
        if (files.length > 0) {
            setArquivoFile(files[0].file)
        } else {
            setArquivoFile(null)
        }
    }

    const handleClose = () => setShowModal(false)
    const handleShow = () => setShowModal(true)

    const createMaterial = async (e) => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 5000)

        e.preventDefault()

        if (produtoLojista && (productName === '' || productCode === '' || linha === '' || marca === '' || peso === '')) {
            toast.error('Por favor preencha todos os campos do produto lojista.', { autoClose: 3000 })
        } else {
            const formData = new FormData()
            formData.append('arquivo', arquivoFile)
            formData.append('id_biblioteca', selectedBibliotecaId)
            formData.append('product_name', productName)
            formData.append('product_code', productCode)
            formData.append('linha', linha)
            formData.append('marca', marca)
            formData.append('produto_lojista', produtoLojista)
            formData.append('peso', peso)

            if (!arquivoFile) {
                toast.error('Por favor, selecione um arquivo.', { autoClose: 3000 })
            } else {
                try {
                    await api.post('/api/material/store', formData)
                    toast.success(`Material "${arquivoFile.name}" criado com sucesso!`, {
                        toastId: customId,
                        autoClose: 3000,
                    })
                    selectedBibliotecaId('')
                    setArquivoFile('')
                    setShowModal(false)
                } catch (error) {
                    toast.error('Erro ao criar material.', {
                        toastId: customId,
                        autoClose: 3000,
                    })
                    console.error(error)
                }
            }
        }

    }

    const handleChange = (e) => {
        setSelectedBibliotecaId(e.target.value)
    }

    const createPost = async (e) => {

        if (!botaoHabilitado || exibindoSpinner) {
            return
        }

        // Inicia a exibição do Spinner
        setExibindoSpinner(true)

        // Lógica de salvamento aqui (simulada com um setTimeout)
        setTimeout(() => {
            // Desabilita o botão após o salvamento
            setBotaoHabilitado(false)
            // Para de exibir o Spinner
            setExibindoSpinner(false)
            // Habilita o botão novamente após 5 segundos        
            setBotaoHabilitado(true)
        }, 4000) // Tempo de espera simulado de 2 segundos (substitua pela lógica real)

        e.preventDefault()

        if (tipoMaterial === '') {
            toast.error('Por favor selecione o tipo de material.', { autoClose: 3000 })
        } else if (materiaisToAssociate === '' && templatesToAssociate.length === '') {
            toast.error('Por favor selecione um material.', { autoClose: 3000 })
        } else if (conteudosToAssociate === '') {
            toast.error('Por favor selecione um conteúdo.', { autoClose: 3000 })
        } else if (maquinasToAssociate.length === 0 && locaisToAssociate.length === 0 && gruposToAssociate.length === 0) {
            toast.error('Por favor selecione uma segmentação.', { autoClose: 3000 })
        } else if (dataInicio === '' || horaInicio === '') {
            toast.error('Por favor selecione data e hora de início da publicação.', { autoClose: 3000 })
        } else {
            await api.put(`/api/publicacao/${id}`, {
                conteudosToAssociate,
                maquinasToAssociate,
                locaisToAssociate,
                gruposToAssociate,
                materiaisToAssociate,
                templatesToAssociate,
                templateData,
                dataInicio,
                horaInicio,
                dataFim,
                horaFim,
                activated,
                templateInfos,
                tipoMaterial,
                queryString
            }).then(() => {
                setMateriaisToAssociate([])
                setConteudosToAssociate('')
                setMaquinasToAssociate([])
                setLocaisToAssociate([])
                setGruposToAssociate([])
                setDataInicio('')
                setQueryString('')
                setHoraInicio('')
                setDataFim('')
                setHoraFim('')
                setTipoMaterial('')
                setTemplateInfos([])
                toast.success('Publicação editada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/publicacoes') })
            })
        }
    }

    useEffect(() => {
        io(process.env.REACT_APP_UPDATE_PLAYLIST_SOCKET, { transports: ['websocket'] })

        let cancel = false

        async function getMateriais(id_template, templateDado) {
            api.get(`api/materiais/getMateriais`).then(res => {
                if (cancel) return

                let materiaisData = []

                setDoSpaces(res.data.spaces)

                res.data.materiais.forEach(material => {
                    materiaisData.push({
                        value: {
                            type: material.type,
                            ...material,
                            arquivo: material.arquivo,
                        },
                        label: material.label,
                        imageUrl: `${doSpaces}${material.caminho}/${material.arquivo}`
                    })
                })

                setMateriais(materiaisData)

                let templatesData = []

                res.data.templates.forEach(template => {
                    templatesData.push({
                        value: {
                            ...template,
                            arquivo: template.arquivo,
                        },
                        label: template.label
                    })
                })

                if (id_template) {
                    let templateToSelect = templatesData.filter(item => item.value && item.value.value === id_template)

                    setTemplatesToAssociate(templateToSelect[0])

                    getTemplateDado(templateDado, templateToSelect[0])
                }

                setTemplates(templatesData)
            })
        }

        async function fetchData() {
            await api.get(`api/publicacao/${id}`).then((res) => {
                if (cancel) return

                getMateriais(res.data.publicacao.id_template, res.data.templateDado)

                setConteudosToAssociate(res.data.publicacao.id_conteudo)
                setActivated(res.data.publicacao.ativo)
                setQueryString(res.data.publicacao.cache_parametros)

                let dataInicio = res.data.publicacao.data_inicio
                let arrayDataHoraInicio = dataInicio.split(' ')
                let dataInicioFormatada = arrayDataHoraInicio[0]
                let horaInicioFormatada = arrayDataHoraInicio[1]

                setDataInicio(dataInicioFormatada)
                setHoraInicio(horaInicioFormatada)

                if (res.data.publicacao.data_fim) {
                    let dataFim = res.data.publicacao.data_fim
                    let arrayDataHoraFim = dataFim.split(' ')
                    let dataFimFormatada = arrayDataHoraFim[0]
                    let horaFimFormatada = arrayDataHoraFim[1]

                    setDataFim(dataFimFormatada)
                    setHoraFim(horaFimFormatada)
                }

                if (res.data.material != null) {
                    let materiaisData = []

                    materiaisData.push({
                        value: {
                            type: res.data.material.tipo,
                            ...res.data.material,
                            arquivo: res.data.material.arquivo,
                        },
                        label: res.data.material.nome,
                        imageUrl: `${doSpaces}${res.data.material.caminho}/${res.data.material.arquivo}`
                    })

                    setMateriaisToAssociate(materiaisData[0])
                    if (materiaisData[0].value.tipo === 'imagem') {
                        setTipoMaterial({ value: 'Imagem', label: 'Imagem' })
                    } else {
                        setTipoMaterial({ value: 'Video', label: 'Video' })
                    }
                } else {
                    setTipoMaterial({ value: 'Template', label: 'Template' })
                }

                var segmentacaoData = []
                if (res.data.maquinas != null) {
                    res.data.maquinas.forEach((maquina) => {
                        const maquinasObject = {
                            value: maquina.value,
                            label: maquina.label,
                            type: 'maquina',
                        }

                        segmentacaoData.push(maquinasObject)
                    })
                }

                if (res.data.locais != null) {
                    res.data.locais.forEach((local) => {
                        const locaisObject = {
                            value: local.value,
                            label: local.label,
                            type: 'local',
                        }

                        segmentacaoData.push(locaisObject)
                    })
                }

                if (res.data.grupos != null) {
                    res.data.grupos.forEach((grupo) => {
                        const gruposOnject = {
                            value: grupo.value,
                            label: grupo.label,
                            type: 'grupo',
                        }

                        segmentacaoData.push(gruposOnject)
                    })

                    setGruposToAssociate(res.data.grupos)
                }
                handleSelectOptions(segmentacaoData)
            })

            fetch('https://economia.awesomeapi.com.br/last/USD-BRL,EUR-BRL')
                .then(response => response.json())
                .then(data => {
                    setEuro(data.EURBRL.bid)
                    setDolar(data.USDBRL.bid)
                })
                .catch(error => console.error('Erro ao buscar dados da API:', error))

            fetch('https://api.openweathermap.org/data/2.5/forecast/daily?lang=pt&units=metric&q=S%C3%A3o%20Paulo&appid=110694ce97ac8f42ef2b095cd80a6681&cnt=7')
                .then(response => response.json())
                .then(data => {
                    setMax(Math.round(data.list[0].temp.max))
                    setMin(Math.round(data.list[0].temp.min))
                })
                .catch(error => console.error('Erro ao buscar dados da API:', error))

            api.get(`api/conteudos/getConteudos`).then(res => {
                if (cancel) return

                let conteudosData = []

                res.data.conteudos.forEach(conteudo => {
                    conteudosData.push({
                        value: conteudo.value,
                        label: conteudo.label
                    })
                })

                setConteudos(conteudosData)
            })

            api.get(`api/maquinas/getMaquinas`).then(res => {
                if (cancel) return

                let maquinasData = []

                res.data.maquinas.forEach(maquina => {
                    maquinasData.push({
                        value: maquina.value,
                        label: maquina.label
                    })
                })

                setMaquinas(maquinasData)
            })

            await api.get('/api/biblioteca/index').then(res => {
                if (cancel) return
                setBiblioteca(res.data.biblioteca)
            })

            api.get(`api/locais/getLocais`).then(res => {
                if (cancel) return

                let locaisData = []

                res.data.locais.forEach(local => {
                    locaisData.push({
                        value: local.value,
                        label: local.label
                    })
                })

                setLocais(locaisData)
            })

            api.get(`api/grupos/getGrupos`).then(res => {
                if (cancel) return

                let gruposData = []

                res.data.grupos.forEach(grupo => {
                    gruposData.push({
                        value: grupo.value,
                        label: grupo.label
                    })
                })

                setGrupos(gruposData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id, doSpaces])

    const getTemplateDado = (dadosTemplate, selectedTemplate) => {
        dadosTemplate.forEach((dado) => {

            let match = dado.valor.match(/[^/]+$/)
            match = match ? match[0] : null

            var e = dado.id_tipo_dado !== 3 ? {imageUrl: dado.valor, label: match, value: dado.valor} : dado.valor

            setTemplateInfos((prevData) => {
                const updateData = { ...prevData, [dadosTemplate]: [e, dadosTemplate, dado.id_tipo_dado] }
                return updateData
            })

            setTemplateData((prevData) => {
                const updatedData = {
                    ...prevData,
                    [dado.dado]: e,
                }

                const updatedQueryString = Object.entries(updatedData)
                    .filter(([key, value]) => value !== undefined && value !== '')
                    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value.value ?? value)}`)
                    .join('&')

                const iframeUrl = `${selectedTemplate.value.arquivo}${updatedQueryString}`
                setIframeUrl(iframeUrl)

                return updatedData
            })
        })
    }

    const handleDadoInput = (e, dadoDado, id_tipo) => {
        setTemplateInfos((prevData) => {
            const updateData = { ...prevData, [dadoDado]: [e, dadoDado, id_tipo] }
            return updateData
        })

        setTemplateData((prevData) => {
            const updatedData = {
                ...prevData,
                [dadoDado]: e,
            }

            const updatedQueryString = Object.entries(updatedData)
                .filter(([key, value]) => value !== undefined && value !== '')
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value.value ?? value)}`)
                .join('&')

            setQueryString(updatedQueryString)

            const iframeUrl = `${templatesToAssociate.value.arquivo}${updatedQueryString}`
            setIframeUrl(iframeUrl)

            return updatedData
        })
    }

    const formatOptionLabel = ({ label, imageUrl }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={doSpaces + imageUrl}
                alt={label}
                style={{ width: 100, height: 80, marginRight: 10 }}
            />
            {label}
        </div>
    )

    const formatOptionLabelVid = ({ label, imageUrl }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <video className='mt-2' style={{ width: 100, height: 80, marginRight: 10 }} autoPlay loop muted controls={false} src={imageUrl} />
            {label}
        </div>
    )

    const handleSelectOptions = (selectedOptions) => {
        const gruposOptions = selectedOptions.filter((option) => option.type === 'grupo')
        const locaisOptions = selectedOptions.filter((option) => option.type === 'local')
        const maquinasOptions = selectedOptions.filter((option) => option.type === 'maquina')

        setGruposToAssociate(gruposOptions)
        setLocaisToAssociate(locaisOptions)
        setMaquinasToAssociate(maquinasOptions)

        setSelecaoToAssociate(selectedOptions)
    }

    const handleDataInicioChange = (e) => {
        setDataInicio(e.target.value)
    }

    const handleHoraInicioChange = (e) => {
        setHoraInicio(e.target.value)
    }

    const handleDataFimChange = (e) => {
        setDataFim(e.target.value)
    }

    const handleHoraFimChange = (e) => {
        setHoraFim(e.target.value)
    }

    const opcoes = [
        { value: 'Template', label: 'Template' },
        { value: 'Video', label: 'Video' },
        { value: 'Imagem', label: 'Imagem' },
    ]

    const videos = materiais.filter((material) => {
        const ext = material.value.arquivo.toLowerCase().split('.').pop()
        return ['mp4', 'avi', 'mov', 'wmv', 'webm'].includes(ext)
    }).map(material => ({
        value: `${material.value.caminho}/${material.value.arquivo}`,
        label: material.label,
        imageUrl: material.imageUrl
    }))

    const img = materiais.filter((material) => {
        const ext = material.value.arquivo.toLowerCase().split('.').pop()
        return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext)
    }).map(material => ({
        value: `${material.value.caminho}/${material.value.arquivo}`,
        label: material.label,
        imageUrl: material.imageUrl
    }))

    const img2 = materiais.filter((material) => {
        const ext = material.value.arquivo.toLowerCase().split('.').pop()
        return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(ext)
    }).map(material => ({
        value: `${doSpaces}${material.value.caminho}/${material.value.arquivo}`,
        label: material.label,
        imageUrl: `${doSpaces}${material.value.caminho}/${material.value.arquivo}`
    }))

    const vid2 = materiais.filter((material) => {
        const ext = material.value.arquivo.toLowerCase().split('.').pop()
        return ['mp4', 'avi', 'mov', 'wmv', 'webm'].includes(ext)
    }).map(material => ({
        value: `${doSpaces}${material.value.caminho}/${material.value.arquivo}`,
        label: material.label,
        imageUrl: `${doSpaces}${material.value.caminho}/${material.value.arquivo}`
    }))

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BsPlayBtnFill className='fs-1' /><h3 id='tittleH2'> Edição de Publicação</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/publicacoes' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div className='formGenMobile'>
                    <Row className='rowGenForm'>
                        <Col xs lg='7' className='colGapClass'>

                            <Form>
                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                    <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white'>
                                        <span><LuFileQuestion className='fs-5' /> Tipo Material</span>
                                    </label>
                                    <Select
                                        className='p-2'
                                        id='materiais'
                                        value={tipoMaterial}
                                        onChange={(e) => {
                                            setTipoMaterial(e)
                                            setMateriaisToAssociate('')
                                            setTemplatesToAssociate('')
                                            setTemplateData('')
                                        }}
                                        options={opcoes}
                                    />
                                </Form.Group>
                            </Form>
                            <Form>
                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                    <label htmlFor="segmentacao" className='fw-semibold borderDown p-2 bg-dark text-white'>
                                        <span><PiLineSegmentsBold className='fs-5' /> Segmentação</span>
                                    </label>
                                    <Select
                                        placeholder="Selecione a segmentação"
                                        className='p-2'
                                        required
                                        id="segmentacao"
                                        value={selecaoToAssociate}
                                        onChange={handleSelectOptions}
                                        options={[
                                            ...grupos.map((grupo) => ({ ...grupo, type: 'grupo' })),
                                            ...locais.map((local) => ({ ...local, type: 'local' })),
                                            ...maquinas.map((maquina) => ({ ...maquina, type: 'maquina' })),
                                        ]}
                                        getOptionLabel={(option) => {
                                            switch (option.type) {
                                                case 'grupo':
                                                    return `Grupo - ${option.label}`
                                                case 'local':
                                                    return `Local - ${option.label}`
                                                case 'maquina':
                                                    return `Maquina - ${option.label}`
                                                default:
                                                    return option
                                            }
                                        }}
                                        isMulti={true}
                                    />
                                </Form.Group>
                            </Form>
                            {tipoMaterial.value === 'Imagem' ? (
                                <>
                                    <Form>
                                        <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                            <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white d-flex justify-content-between align-items-center'>
                                                <span><SiMaterialdesignicons className='fs-5' /> Material</span>
                                                <Button size='sm' variant="secondary" onClick={handleShow}>
                                                    <span className='d-flex align-items-center'>
                                                        <FaFileCirclePlus className='fs-5 me-1 text-info' /> Adicionar Material
                                                    </span>
                                                </Button>
                                            </label>
                                            <Select
                                                placeholder="Selecione o material"
                                                className='p-2'
                                                id="materiais"
                                                value={materiaisToAssociate}
                                                onChange={(e) => setMateriaisToAssociate(e)}
                                                options={img}
                                                formatOptionLabel={formatOptionLabel}
                                            />
                                        </Form.Group>
                                    </Form>

                                    {materiaisToAssociate && materiaisToAssociate.label !== '' && (
                                        materiaisToAssociate.value && (
                                            <div className='w-100'>
                                                <img className='mt-2' style={{ width: '100%', marginBottom: '5px' }} src={typeof materiaisToAssociate.value !=="object" ? doSpaces +  materiaisToAssociate.value : doSpaces + materiaisToAssociate.value.caminho + "/" + materiaisToAssociate.value.arquivo } alt='Imagem' />
                                            </div>
                                        )
                                    )}
                                </>
                            ) : ""}

                            {tipoMaterial.value === 'Video' ? (
                                <>
                                    <Form>
                                        <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                            <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white d-flex justify-content-between align-items-center'>
                                                <span><SiMaterialdesignicons className='fs-5' /> Material</span>
                                                <Button size='sm' variant="secondary" onClick={handleShow}>
                                                    <span className='d-flex align-items-center'>
                                                        <FaFileCirclePlus className='fs-5 me-1 text-info' /> Adicionar Material
                                                    </span>
                                                </Button>
                                            </label>
                                            <Select
                                                placeholder="Selecione o material"
                                                className='p-2'
                                                id="materiais"
                                                value={materiaisToAssociate}
                                                onChange={(e) => setMateriaisToAssociate(e)}
                                                options={videos}
                                                formatOptionLabel={formatOptionLabelVid}
                                            />
                                        </Form.Group>
                                    </Form>

                                    {materiaisToAssociate && materiaisToAssociate.label !== '' && (
                                        materiaisToAssociate.value && (
                                            <div className='w-100'>
                                                <video className='mt-2' style={{ width: '100%' }}  muted loop autoPlay controls src={typeof materiaisToAssociate.value !=="object" ? doSpaces +  materiaisToAssociate.value : doSpaces + materiaisToAssociate.value.caminho + "/" + materiaisToAssociate.value.arquivo } />
                                            </div>
                                        )
                                    )}
                                </>
                            ) : ""}

                            {tipoMaterial.value === 'Template' ? (
                                <>
                                    <Form>
                                        <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                            <label htmlFor="templates" className='fw-semibold borderDown p-2 bg-dark text-white'>
                                                <span><TbTemplate className='fs-5' /> Template</span>
                                            </label>
                                            <Select
                                                placeholder="Selecione o template"
                                                className='p-2'
                                                id="templates"
                                                value={templatesToAssociate}
                                                onChange={(e) => setTemplatesToAssociate(e)}
                                                options={templates}
                                            />
                                        </Form.Group>
                                    </Form>

                                    {templatesToAssociate && templatesToAssociate.label !== '' && (
                                        templatesToAssociate.value && (
                                            <div className='w-100'>
                                                {templatesToAssociate.value.dadosTemplate.map((dado) => (
                                                    <div className="mb-3 mt-2" key={`template-${dado.id}`}>
                                                        {dado.id_tipo_dado === 1 && (
                                                            <Form>
                                                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                                                    <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white d-flex justify-content-between align-items-center'>
                                                                        <span><FaRegFileVideo /> Vídeo</span>
                                                                    </label>
                                                                    <Select
                                                                        placeholder="Selecione o material"
                                                                        name={dado.dado}
                                                                        className='p-2'
                                                                        id={dado.dado}
                                                                        value={templateData[dado.dado] || ''}
                                                                        onChange={(e) => handleDadoInput(e, dado.dado, dado.id_tipo_dado)}
                                                                        options={vid2}
                                                                        required
                                                                        formatOptionLabel={formatOptionLabelVid}
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        )}
                                                        {dado.id_tipo_dado === 2 && (
                                                            <Form>
                                                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                                                    <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white d-flex justify-content-between align-items-center'>
                                                                        <span><IoMdImages /> Imagem</span>
                                                                    </label>
                                                                    <Select
                                                                        placeholder="Selecione o material"
                                                                        name={dado.dado}
                                                                        className='p-2'
                                                                        id={dado.dado}
                                                                        value={templateData[dado.dado] || ''}
                                                                        onChange={(e) => handleDadoInput(e, dado.dado, dado.id_tipo_dado)}
                                                                        options={img2}
                                                                        required
                                                                        formatOptionLabel={formatOptionLabel}
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        )}
                                                        {dado.id_tipo_dado === 3 && (
                                                            <Form>
                                                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                                                    <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white d-flex justify-content-between align-items-center'>
                                                                        <span><IoDocumentTextOutline /> Texto</span>
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder='digite o texto'
                                                                        name={dado.dado}
                                                                        style={{
                                                                            boxSizing: 'border-box',
                                                                            margin: '.5rem',
                                                                            borderRadius: '5px',
                                                                            width: 'calc(100% - (2*.5rem))',
                                                                            padding: '5px',
                                                                        }}
                                                                        id={dado.dado}
                                                                        value={templateData[dado.dado] || ''}
                                                                        onChange={(e) => handleDadoInput(e.target.value, dado.dado, dado.id_tipo_dado)}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        )}
                                                        {dado.id_tipo_dado === 4 && (
                                                            <Form>
                                                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                                                    <label htmlFor="materiais" className='fw-semibold borderDown p-2 bg-dark text-white d-flex justify-content-between align-items-center'>
                                                                        <span><FaRegListAlt /> Lista</span>
                                                                    </label>
                                                                    <Select
                                                                        placeholder="Selecione o texto"
                                                                        name={dado.dado}
                                                                        className='p-2'
                                                                        id={dado.dado}
                                                                        value={templateData[dado.dado] || ''}
                                                                        onChange={(e) => handleDadoInput(e, dado.dado, dado.id_tipo_dado)}
                                                                        options={dado.lista && (dado.lista.split(';').map((opcao, index) => (
                                                                            {
                                                                                value: opcao,
                                                                                label: opcao,
                                                                            }
                                                                        )))}
                                                                        required
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        )}
                                                    </div>
                                                ))}
                                                <iframe
                                                    title='templatePreview'
                                                    style={{
                                                        width: '100%',
                                                        height: '200px',
                                                        marginBottom: '5px',
                                                        border: 'none',
                                                    }}
                                                    src={iframeUrl !== '' ? `${iframeUrl}&maximahoje=${max}&minimahoje=${min}&valoreuro=${euro}&valordolar=${dolar}` : `${templatesToAssociate.value.arquivo}maximahoje=${max}&minimahoje=${min}&valoreuro=${euro}&valordolar=${dolar}`}
                                                />
                                            </div>
                                        )
                                    )}
                                </>
                            ) : ""}
                        </Col>
                        <Col xs lg='5'>
                            <Table className='shadowCustom' striped bordered hover variant="dark">
                                <thead className='bg-dark'>
                                    <tr>
                                        <th>
                                            <h6 className='text-white text-center'>
                                                <MdLibraryAdd /> Adicione conteúdo
                                            </h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='border-1'>
                                    {conteudos.map((conteudo, index) => (
                                        <tr key={`conteudo_container_${index}`}>
                                            <td>
                                                <label style={{ display: 'block', width: '100%', cursor: 'pointer', paddingLeft: '1vw' }}>
                                                    <Form.Check
                                                        className='fw-semibold'
                                                        key={`conteudo-${conteudo.value}`}
                                                        type="switch"
                                                        id={`conteudo-${conteudo.id}`}
                                                        label={conteudo.label}
                                                        value={conteudo.value}
                                                        checked={conteudo.value === conteudosToAssociate}
                                                        onChange={() => setConteudosToAssociate(conteudo.value)}
                                                    />
                                                </label>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Table striped bordered className='shadowCustom'>
                                <thead className='bg-dark'>
                                    <tr>
                                        <th colSpan={2}>
                                            <h6 className="text-white text-center">
                                                <IoHourglass className='mb-1' /> Periodo
                                            </h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='rowGenForm'>
                                        <td>
                                            {/* data e hora fim */}
                                            <Col>
                                                <h5 className="bg-success text-white p-1 mb-3 rounded text-center">
                                                    Inicio
                                                </h5>
                                                <InputGroup>
                                                    <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Data</InputGroup.Text>
                                                    <input
                                                        type="date"
                                                        name="data_inicio"
                                                        className="text-center form-control valid fw-semibold"
                                                        id="data_inicio"
                                                        aria-describedby="data_inicio"
                                                        aria-invalid="false"
                                                        value={dataInicio}
                                                        onChange={handleDataInicioChange}
                                                        required
                                                    />
                                                </InputGroup>
                                                <InputGroup className='mt-3'>
                                                    <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Hora</InputGroup.Text>
                                                    <input
                                                        type="time"
                                                        name="hora_inicio"
                                                        step="1"
                                                        className="text-center form-control inteiro fw-semibold"
                                                        id="hora_inicio"
                                                        aria-describedby="hora_inicio"
                                                        value={horaInicio}
                                                        onChange={handleHoraInicioChange}
                                                        required
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </td>
                                        <td>
                                            <Col>
                                                <h5 className="bg-danger text-white p-1 mb-3 rounded text-center">
                                                    Final
                                                </h5>
                                                <InputGroup>
                                                    <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Data</InputGroup.Text>
                                                    <input
                                                        type="date"
                                                        name="data_fim"
                                                        className="text-center form-control valid fw-semibold"
                                                        id="data_fim"
                                                        aria-describedby="data_fim"
                                                        aria-invalid="false"
                                                        value={dataFim}
                                                        onChange={handleDataFimChange}
                                                        required
                                                    />
                                                </InputGroup>
                                                <InputGroup className='mt-3'>
                                                    <InputGroup.Text className='bg-dark text-white' id="basic-addon1">Hora</InputGroup.Text>
                                                    <input
                                                        type="time"
                                                        name="hora_fim"
                                                        step="1"
                                                        className="text-center form-control inteiro fw-semibold"
                                                        id="hora_fim"
                                                        value={horaFim}
                                                        onChange={handleHoraFimChange}
                                                        aria-describedby="hora_fim"
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg='2'>
                            <Form.Check
                                className='mb-3 mt-3'
                                type='switch'
                                id='activeUser'
                                checked={activated}
                                onChange={(e) => setActivated(e.target.checked)}
                                label='Ativar/Desativar'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg='2'>
                            <Button className='btnCreateMobile' onClick={createPost} id='buttonNew' type='submit' disabled={!botaoHabilitado}>
                                {exibindoSpinner ? (
                                    <>
                                        <Spinner as="span" animation="border" size='sm' role="status" aria-hidden="true" style={{ marginRight: '7px' }} />
                                        <span className="sr-only ml-3">Salvando...</span>
                                    </>
                                ) : (
                                    'Salvar'
                                )}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='textColors'>Novo material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table className='shadowCustom' striped bordered>
                        <thead className='bg-dark'>
                            <tr>
                                <th className='text-white'><BiLayerPlus className='fs-4 m-1' />Adicionar Material</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <select className='w-100 cursorPointerClass' value={selectedBibliotecaId} onChange={handleChange}>
                                        <option value="">Clique aqui para selecionar uma biblioteca</option>
                                        {biblioteca.map((item) => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div >
                                        <FilePond
                                            labelIdle='<span class="filepond--label-action filepond--drop-label">Arraste e solte seus arquivos ou clique aqui para selecionar</span>'
                                            files={arquivoFile ? [{ source: arquivoFile }] : []}
                                            onupdatefiles={onFileChange}
                                            allowMultiple={false}
                                            maxFiles={1}
                                            acceptedFileTypes={['image/*', 'video/*', 'application/zip', 'application/x-zip-compressed']}
                                            maxFileSize="200MB"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='saveBtnModalClass' onClick={createMaterial} disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <Spinner animation="border" />
                            </>
                        ) : (
                            'Salvar'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Edit
