import Lottie from "lottie-react";
import screenshotsAnimationLottie from "./screenshotsAnimation.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationScreenshotsLottie = () => {
    return <Lottie loop={true} style={style} animationData={screenshotsAnimationLottie} />;
};

export default AnimationScreenshotsLottie;