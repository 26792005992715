import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { AiFillCloud } from 'react-icons/ai'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [color, setColor] = useState('#000000')
    const [logoFile, setLogoFile] = useState('')
    const [activated, setActivated] = useState(0)
    const [approval, setApproval] = useState(0)
    const [lojista, setlojista] = useState(0)
    const [selectedConteudo, setSelectedConteudo] = useState(0)
    const [conteudosLojista, setConteudosLojista] = useState([])
    const customId = 'success-toast-id'
    let { id } = useParams()

    const handleColorChange = (event) => {
        setColor(event.target.value)
    }

    const editAmbiente = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        if (logoFile) {
            formData.append('logo', logoFile)
        }

        formData.append('name', name)
        formData.append('color_navbar', color)
        formData.append('ativo', activated === true || activated === 1 ? 1 : 0)
        formData.append('approval', approval === true || approval === 1 ? 1 : 0)
        formData.append('id_conteudo_lojista', selectedConteudo)
        formData.append('lojista', lojista === true || lojista === 1 ? 1 : 0)

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else {
            await api.post(`/api/ambiente/update/${id}`, formData).then((res) => {
                setName('')
                toast.success('Ambiente editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/ambientes') })
            }).catch((error) => {
                toast.error('Erro ao editar ambiente.', { toastId: customId, autoClose: 3000 })

                console.error(error)
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/ambiente/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.ambiente.name)
                setColor(res.data.ambiente.color_navbar)
                setActivated(res.data.ambiente.ativo)
                setApproval(res.data.ambiente.approvals)
                setlojista(res.data.ambiente.lojista)
                setSelectedConteudo(res.data.ambiente.id_conteudo_lojista)
            })

            api.get(`api/getConteudosByAmbienteId`).then(res => {
                if (cancel) return
    
                setConteudosLojista(res.data.conteudos)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
        // eslint-disable-next-line
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><AiFillCloud className='fs-1' /><h3 id='tittleH2'> Edição de ambiente</h3></div>
                    </div>
                    <div className='divbtnVoltarMobile'>
                        <NavLink to='/ambientes' style={{ textDecoration: 'none' }}>
                            <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </NavLink>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='formGenMobile'>
                        <Row>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlId="logo_navbar">
                                    <Form.Label className='fw-semibold'>Logo navbar:</Form.Label>
                                    <Form.Control
                                        className='input-group-edit'
                                        type='file'
                                        name='logo'
                                        onChange={(e) => setLogoFile(e.target.files[0])}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs="auto">
                                <Form.Group className='mb-3' controlId="color_navbar">
                                    <Form.Label className='fw-semibold'>Cor navbar:</Form.Label>
                                    <Form.Control
                                        className='input-group-edit'
                                        type='color'
                                        value={color}
                                        onChange={handleColorChange}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='activeAmbiente'
                                        checked={activated}
                                        onChange={(e) => setActivated(e.target.checked)}
                                        label='Ativar/Desativar'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='lojista'
                                        checked={approval}
                                        onChange={(e) => setApproval(e.target.checked)}
                                        label='Aprovações'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Check
                                        type='switch'
                                        id='approval'
                                        checked={lojista}
                                        onChange={(e) => setlojista(e.target.checked)}
                                        label='Lojista'
                                        style={{ marginTop: '8px', marginBottom: '5px' }}
                                    />
                                </Col>
                            </Row>

                            {
                                lojista ? (
                                    <Form.Group className='mb-3 col-3' controlId='formBasicConteudo'>
                                        <Form.Label className='fw-semibold'>Conteúdo</Form.Label>
                                        <Form.Select
                                            className="fw-medium shadowCustom"
                                            id='input-group-edit'
                                            name='conteúdo'
                                            value={selectedConteudo}
                                            onChange={(e) =>
                                                setSelectedConteudo(e.target.value)
                                            }>
                                            <option value="">Selecione...</option>

                                            {conteudosLojista.map((option, index) => (
                                                <option key={index} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                ) : ''
                            }
                        </Row>

                        <Row>
                            <Col xs={2}>
                                <Button onClick={editAmbiente} id='buttonNew' type='submit'>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit