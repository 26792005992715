import Lottie from "lottie-react";
import animationComandoLottie from "./comandoAnimation.json";

const style = {
    height: 70,
    width: 60,
};

const AnimationComandoLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationComandoLottie} />;
};

export default AnimationComandoLottie;