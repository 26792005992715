import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill, BsFillCloudFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlineCheck } from 'react-icons/ai'
import { GiCancel } from 'react-icons/gi'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'

import AnimationNoticiasLottie from './AnimationNoticiasLottie/AnimationNoticiasLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import AnimationWarningApproveNewsLottie from '../../components/AnimationWarningApproveNewsConfim/AnimationWarningApproveNewsConfimLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Approvals = () => {
    const [noticias, setNoticias] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [ambientesPopOver, setAmbientesPopover] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalApproveShow, setModalApproveShow] = useState(false)
    const [noticiaIdToRepprove, setNoticiaIdToRepprove] = useState('')
    const [noticiaIdToApprove, setNoticiaIdToApprove] = useState('')
    const [noticiaNameToApprove, setNoticiaNameToApprove] = useState('')
    const [noticiaNameToRepprove, setNoticiaNameToRepprove] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [noticiasPerPage] = useState(10)
    const indexOfLastNoticia = currentPage * noticiasPerPage
    const indexOfFirstNoticia = indexOfLastNoticia - noticiasPerPage
    const currentNoticias = noticias.slice(indexOfFirstNoticia, indexOfLastNoticia)
    const nPages = Math.ceil(noticias.length / noticiasPerPage)

    const getNoticias = async () => {
        const response = await api.get('/api/noticia/approvals')
        setNoticias(response.data.noticias)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)
        setUserPermissions(responsePermissions.data.permissions)
    }

    const showRepproveNoticiaConfirmationModal = (noticiaId, noticiaName) => {
        setModalShow(true)
        setNoticiaIdToRepprove(noticiaId)
        setNoticiaNameToRepprove(noticiaName)
    }

    const showApproveNoticiaConfirmationModal = (noticiaId, noticiaName) => {
        setModalApproveShow(true)
        setNoticiaIdToApprove(noticiaId)
        setNoticiaNameToApprove(noticiaName)
    }

    const repproveNoticia = async (noticiaId) => {
        await api.delete(`/api/noticia/${noticiaId}`).then(() => {
            getNoticias()
            setModalShow(false)
            toast.success('Noticia reprovada com sucesso!', { autoClose: 3000 })
        })
    }

    const approveNoticia = async (noticiaId) => {
        await api.get(`/api/approveNoticia/${noticiaId}`).then(() => {
            getNoticias()
            setModalApproveShow(false)
            toast.success('Noticia aprovada com sucesso!', { autoClose: 3000 })
        })
    }

    function RepproveNoticiaConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header id="modalHeader" closeButton>
                    <div id="divModalTitle">
                        <Modal.Title id="modalTitle">
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id="modalBody">
                    <h4>Reprovação de Noticia</h4>
                    <p>
                        Tem certeza que deseja reprovar a noticia {props.noticianame}?
                    </p>
                </Modal.Body>

                <Modal.Footer id="modalFooter">
                    <Button id="yesButton" onClick={() => repproveNoticia(props.noticiaid)}>
                        Sim
                    </Button>
                    <Button id="noButton" onClick={props.onHide}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    function ApproveNoticiaConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header id="modalHeader" closeButton>
                    <div id="divModalTitle">
                        <Modal.Title id="modalTitle">
                            <AnimationWarningApproveNewsLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id="modalBody">
                    <h4>Aprovação de Notícia</h4>
                    <p>
                        Tem certeza que deseja aprovar a noticia {props.noticianame}?
                    </p>
                </Modal.Body>

                <Modal.Footer id="modalFooter">
                    <Button id="yesButton" onClick={() => approveNoticia(props.noticiaid)}>
                        Sim
                    </Button>
                    <Button id="noButton" onClick={props.onHide}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;
    
        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };
    
        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }
    
        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        );
    };

    const getAmbientesPopOver = async (idNoticia) => {
        await api.get(`/api/noticia/getAssociatedAmbientes/${idNoticia}`).then((res) => {
            setAmbientesPopover(res.data.ambientes)
        })
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Segmentação</Popover.Header>
            <Popover.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                {
                    ambientesPopOver.map(ambiente => (
                        <strong key={ambiente}>{ambiente}</strong>
                    ))
                }
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        getNoticias()
    }, [])

    const filteredNoticias = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentNoticias
        } else {
            return noticias.filter(
                (noticia) =>
                    noticia.texto.toLowerCase().includes(lowerSearch) ||
                    noticia.id.toString().toLowerCase().includes(lowerSearch) ||
                    noticia.imagem.toString().toLowerCase().includes(lowerSearch) ||
                    noticia.nome_editoria.toString().toLowerCase().includes(lowerSearch)
            )
        }
    }, [search, noticias, currentNoticias])

    return (
        <>
            <NavBar />

            <div id='divContainer' className="container">
                <RepproveNoticiaConfirmationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    noticiaid={noticiaIdToRepprove}
                    noticianame={noticiaNameToRepprove}
                />

                <ApproveNoticiaConfirmationModal
                    show={modalApproveShow}
                    onHide={() => setModalApproveShow(false)}
                    noticiaid={noticiaIdToApprove}
                    noticianame={noticiaNameToApprove}
                />

                <div className="headerContainer">
                    <div className='divheaderTittleMobile'>
                        <div id="divHeaderTittle">
                            <AnimationNoticiasLottie />
                            <h3 id="tittleH2">Aprovações de Notícias</h3>
                        </div>
                    </div>

                    <div id='div-input-group' className='div-input-group'>
                        <InputGroup className='input-group' id="input-group">
                            <InputGroup.Text id="search">
                                <AiOutlineSearch />
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </div>

                <ToastContainer />

                <div className='container overflow-auto mt-2'>
                    <Table id="tableList" striped bordered hover>
                        <thead>
                            <tr>
                                <th className="text-center">#ID</th>
                                <th className="text-center">Editoria</th>
                                <th className="text-center">Texto</th>
                                <th className="text-center">Imagem</th>
                                <th className="text-center">Ambientes</th>
                                <th className="text-center">Data Final</th>
                                <th className="text-center">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredNoticias.map((noticia, index) => (
                                <tr key={noticia.id}>
                                    <td className="text-center">{noticia.id}</td>
                                    <td className="text-center">{noticia.nome_editoria}</td>
                                    <td className="text-center">{noticia.texto}</td>
                                    <td className="text-center">
                                        <img id='thumb' style={{ height: '70px' }} src={noticia.imagem} alt="Imagem" />
                                    </td>
                                    <td className="text-center">
                                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                            <Button className="editButton" onClick={() => getAmbientesPopOver(noticia.id)}>
                                                <BsFillCloudFill /> Ambientes
                                            </Button>
                                        </OverlayTrigger>
                                    </td>
                                    <td className="text-center">{noticia.data_fim}</td>
                                    <td className="text-center">
                                        {userPermissions.some((permission) => permission.name === 'edit_noticia') ? (
                                            <Button className="editButton btnTableMoBile me-2" style={{ backgroundColor: '#008000' }} onClick={() => showApproveNoticiaConfirmationModal(noticia.id, noticia.id)}>
                                                <AiOutlineCheck style={{ fontSize: 'larger' }} /> Aprovar
                                            </Button>
                                        ) : (
                                            ''
                                        )}

                                        {userPermissions.some((permission) => permission.name === 'delete_noticia') ? (
                                            <Button
                                                className="deleteButton btnTableMoBile"
                                                onClick={() => showRepproveNoticiaConfirmationModal(noticia.id, noticia.id)}
                                            >
                                                <GiCancel style={{ fontSize: 'larger' }} /> Reprovar
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        </>
    )
}

export default Approvals
