import Lottie from "lottie-react";
import animationPermissionLottie from "./permissionAnimation.json";

const style = {
    height: 70,
};

const AnimationPermissionLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationPermissionLottie} />;
};

export default AnimationPermissionLottie;