import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { GrPersonalComputer } from 'react-icons/gr'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'
import { Col, Row } from 'react-bootstrap'

const Create = () => {
    const history = useNavigate()
    const [gradeToAssociate, setGradeToAssociate] = useState('')
    const [grades, setGrades] = useState([])
    const [modelo, setModelo] = useState([])
    const [selectedModelo, setSelectedModelo] = useState('')
    const [name, setName] = useState('')
    const [monitores, setMonitores] = useState('')
    const [layout, setLayout] = useState('')
    const [patrimonio, setPatrimonio] = useState('')
    const [monitoramento, setMonitoramento] = useState(false)
    const [bloqueio, setBloqueio] = useState(false)
    const [webcam, setWebcam] = useState(false)
    const [dataInstalacao, setDataInstalacao] = useState('')
    const customId = 'success-toast-id'

    let { idLocal } = useParams()

    const createMaquina = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (gradeToAssociate === '') {
            toast.error('Por favor selecione uma grade.', { autoClose: 3000 })
        } else if (patrimonio === '') {
            toast.error('Por favor preencha o patrimônio.', { autoClose: 3000 })
        } else if (layout === '') {
            toast.error('Por favor selecione um layout.', { autoClose: 3000 })
        } else if (selectedModelo === '') {
            toast.error('Por favor selecione um modelo.', { autoClose: 3000 })
        } else if (monitores === '') {
            toast.error('Por favor selecione a quantidade de monitores.', { autoClose: 3000 })
        } else if (dataInstalacao === '') {
            toast.error('Por favor selecione a data de instalação.', { autoClose: 3000 })
        } else {
            await api.post('/api/maquina/store', {
                modelo: selectedModelo['value'],
                gradeToAssociate,
                name,
                monitores,
                layout,
                patrimonio,
                monitoramento,
                bloqueio,
                webcam,
                dataInstalacao,
                idLocal
            }).then(() => {
                setName('')
                setMonitores('')
                setLayout('')
                setModelo('')
                setPatrimonio('')
                setMonitoramento('')
                setBloqueio('')
                setWebcam('')
                setDataInstalacao('')
                toast.success('Máquina criada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history(`/maquinas/${idLocal}`) })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/grade/index`).then((res) => {
                if (cancel) return

                let gradesData = []

                res.data.grades.forEach(grade => {
                    gradesData.push({
                        value: grade.id,
                        label: grade.name
                    })
                })

                setGrades(gradesData)
            })

            await api.get('api/modeloMaquina/index').then((res) => {
                if (cancel) return
    
                let modelosData = []

                res.data.modelos.forEach(modelos => {
                    modelosData.push({
                        value: modelos.id,
                        label: modelos.nome
                    })
                })
    
                setModelo(modelosData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    const handleChangeModelo = async (e) => {
        setSelectedModelo(e)
    }

    const optionsLayout = ['Vertical', 'Horizontal'];
    const optionsMonitor = ['1', '2', '3', '4', '5'];

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><GrPersonalComputer className='fs-1' /><h3 id='tittleH2'> Nova máquina</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <Button onClick={() => history(`/maquinas/${idLocal}`)} id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row xs lg="3" className='d-flex align-items-end flexDirectionMobile'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control  required className='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Grade</Form.Label>
                                    <Select  className='input-group-edit' value={gradeToAssociate} onChange={(e) => setGradeToAssociate(e)} options={grades} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Patrimônio</Form.Label>
                                    <Form.Control  className='input-group-edit' type='text' placeholder='patrimonio' value={patrimonio} onChange={(e) => setPatrimonio(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className='d-flex align-items-end flexDirectionMobile'>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Monitores</Form.Label>
                                    <Form.Select
                                         className='input-group-edit'
                                        name='monitores'
                                        value={monitores}
                                        onChange={(e) =>
                                            setMonitores(e.target.value)
                                        }>
                                        <option value="">Selecione uma opção</option>
                                        {optionsMonitor.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mb-3'>
                                    <Form.Label className='fw-semibold'>Layout da Máquina</Form.Label>
                                    <Form.Select
                                        className='input-group-edit'
                                        name='layout'
                                        value={layout}
                                        onChange={(e) =>
                                            setLayout(e.target.value)
                                        }>
                                        <option value="">Selecione uma opção</option>
                                        {optionsLayout.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className='mb-3'>
                            <Form.Label className='fw-semibold'>Modelos de Máquina</Form.Label>
                            <Select
                                className='shadowCustom'
                                id="input-group-edit"
                                value={selectedModelo}
                                onChange={(e) => handleChangeModelo(e)}
                                options={modelo}
                            />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label className='fw-semibold'>Data de instalação</Form.Label>
                            <Form.Control  className='input-group-edit'
                                name='dataInstalacao'
                                type='date'
                                value={dataInstalacao}
                                required
                                onChange={(e) =>
                                    setDataInstalacao(e.target.value)
                                }
                            />
                        </Form.Group>
                        <Row xs lg="3" className='d-flex align-items-end mb-3'>
                            <Col>
                                <Form.Check
                                    className='fw-semibold'
                                    type='switch'
                                    checked={monitoramento}
                                    onChange={(e) => setMonitoramento(e.target.checked)}
                                    label='Monitoramento'
                                    style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    className='fw-semibold'
                                    type='switch'
                                    checked={bloqueio}
                                    onChange={(e) => setBloqueio(e.target.checked)}
                                    label='Bloqueio'
                                    style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                                />
                            </Col>
                            <Col>
                                <Form.Check
                                    className='fw-semibold'
                                    type='switch'
                                    checked={webcam}
                                    onChange={(e) => setWebcam(e.target.checked)}
                                    label='Webcam'
                                    style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={createMaquina} id='buttonNew' type='submit'>
                                    Criar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Create