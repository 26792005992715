import Lottie from "lottie-react";
import animationEditoriasLottie from "./editoriasAnimation.json";

const style = {
    height: 70,
    width: 70,
};

const AnimationEditoriasLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationEditoriasLottie} />;
};

export default AnimationEditoriasLottie;