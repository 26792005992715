import React, { useEffect, useState } from 'react'
import api from '../../services/api'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import AnimationDashboardLottie from './AnimationDashboardLottie/AnimationDashboardLottie'
import ChartTotalMachines from './ChartjsDashComponents/chartTotalMachines'
import { Form, Row, Col } from 'react-bootstrap'
import { Chart as ChartJS } from 'chart.js/auto'
import { FaSearch } from 'react-icons/fa'

import './styleDashboard.css'
import './ChartjsDashComponents/styleCardsCharts.css'
import '../../components/MobileModeStyle/dashboardMobileStyle.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'

const Dashboard = () => {
    const [totalMachines, setTotalMachines] = useState(0)
    const [onlineMachines, setOnlineMachines] = useState(0)
    const [offlineMachines, setOfflineMachines] = useState(0)
    const [alertMachines, setAlertMachines] = useState(0)
    const [clientes, setCliente] = useState([])
    const [selectedRegion, setSelectedRegion] = useState(-1)
    const [regions, setRegions] = useState([['AC', 'AM', 'AP', 'PA', 'RO', 'RR', 'TO'], ['AL', 'BA', 'CE', 'MA', 'PB', 'PE', 'PI', 'RN', 'SE'], ['DF', 'GO', 'MT', 'MS'], ['ES', 'MG', 'RJ', 'SP'], ['PR', 'RS', 'SC']])
    const [selectedClient, setSelectedClient] = useState(-1)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/api/dashboard')

                setTotalMachines(response.data.totalMaquinas)
                setOnlineMachines(response.data.maquinasOnline)
                setOfflineMachines(response.data.maquinasOffline)
                setAlertMachines(response.data.maquinasAlerta)
                setCliente(response.data.clientes)
            } catch (error) {
                console.error(error)
            }
        }

        fetchData()
    }, [])

    const handleSearch = async () => {
        const response = await api.post(`/api/dashboardFiltered/${selectedClient}`, { region: selectedRegion })

        setTotalMachines(response.data.totalMaquinas)
        setOnlineMachines(response.data.maquinasOnline)
        setOfflineMachines(response.data.maquinasOffline)
        setAlertMachines(response.data.maquinasAlerta)
    }

    return (
        <>
            <NavBar />
            <div className='headerContainer container'>
                <div className='divheaderTittleMobile'>
                    <div id='divHeaderTittle'><AnimationDashboardLottie /><h3 id='tittleH2'>Dashboard</h3></div>
                </div>
                <Form>
                    <Row className='mb-0'>
                        <Form.Group controlId='client'>
                            <div className='colSelectDashMobile d-flex justify-content-end'>
                                <div className='d-flex'>
                                    <label className='d-flex align-items-center textColors ms-1 me-1 fw-semibold'>Cliente:</label>
                                    <select className="input-group-edit w-100 heightMobileCLass" onChange={(e) => setSelectedClient(e.target.value)}>
                                        {clientes.map((cliente, index) => (
                                            <option key={cliente.value} value={cliente.value}>{cliente.label}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className='d-flex'>
                                    <label className='d-flex align-items-center textColors ms-1 me-1 fw-semibold'>Região:</label>
                                    <select className="input-group-edit w-100 heightMobileCLass" onChange={(e) => setSelectedRegion(e.target.value)}>
                                        <option value={-1}>Todos</option>
                                        {regions.map((region, index) => (
                                            <option key={index} value={region}>{index === 0 ? 'Norte' : index === 1 ? 'Nordeste' : index === 2 ? 'Centro-Oeste' : index === 3 ? 'Sudeste' : index === 4 ? 'Sul' : ''}</option>
                                        ))}
                                    </select>
                                </div>

                                <Button onClick={handleSearch} className='buttonSeachChart'>
                                    <FaSearch />
                                </Button>
                            </div>
                        </Form.Group>
                    </Row>
                </Form>
            </div>
            <div className='divContainerDash container'>
                <div className='divAllCardsDash'>
                    <div className='divCardDash colorCardTotal'>
                        <h5 className='fontSizeMobile'>Total</h5>
                        <p className='fs-4 text-decoration-underline'>
                            {totalMachines}
                        </p>
                    </div>
                    <div className='divCardDash colorCardOnline'>
                        <h6 className='fontSizeMobile'>Maquinas Online</h6>
                        <p className='fs-4 text-decoration-underline'>{onlineMachines}</p>
                    </div>
                    <div className='divCardDash colorCardOffline'>
                        <h6 className='fontSizeMobile'>Maquinas Offline</h6>
                        <p className='fs-4 text-decoration-underline'>{offlineMachines}</p>
                    </div>
                    <div className='divCardDash colorCardAlerta'>
                        <h6 className='fontSizeMobile'>Maquinas em Alerta</h6>
                        <p className='fs-4 text-decoration-underline'>{alertMachines}</p>
                    </div>
                </div>
                <div className='container mt-4 mb-5'>
                    <ChartTotalMachines online={onlineMachines} offline={offlineMachines} alerta={alertMachines} total={totalMachines} />
                </div>
            </div>
        </>
    )
}

export default Dashboard