import { Container, Image, Row, Col } from 'react-bootstrap'

const MachineDetails3 = ({ data }) => {

    return (
        <div className='container overflow-auto mt-2'style={{ height: '50vh' }}>
            <Container>
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {data.screenshots.map((image, index) => (
                        <Col xs={3} key={`image_${index}`} style={{ marginTop: '8px', marginRight: '8px' }}>
                            <p className='textColors' style={{ textAlign: 'center' }}>{image.data}</p>
                            <a href={image.caminho} target="_blank" rel="noreferrer">
                                <Image src={image.caminho} alt='screenshot' style={{ width: '197px', height: '118px' }} />
                            </a>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}
  
export default MachineDetails3