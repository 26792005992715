import React from 'react';
import NavBar from '../../components/Navbar/navbar'
import DragAndDropArea from '../../components/DragAndDrop/create'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { RiImageEditLine } from "react-icons/ri";

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Create = () => {

  return (
    <>
      <NavBar />
      <div id='divContainer' className='container'>
        <div className='headerContainer editcreateMobile'>
          <div className='divheaderTittleMobile'>
            <div id='divHeaderTittle'><RiImageEditLine className='fs-1' /><h3 id='tittleH2'> Criação de Template</h3></div>
          </div>

          <div>
            <div>
              <NavLink to='/template/padrao/index' style={{ textDecoration: 'none' }}>
                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <DragAndDropArea />
    </>
  )
}

export default Create