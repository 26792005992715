import Lottie from "lottie-react";
import animationRolesLottie from "./rolesAnimation.json";

const style = {
    height: 70,
};

const AnimationRolesLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationRolesLottie} />;
};

export default AnimationRolesLottie;