import Lottie from "lottie-react";
import animationLogsLottie from "./logsAnimation.json";

const style = {
    height: 60,
    width: 60,
};

const AnimationLogsLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationLogsLottie} />;
};

export default AnimationLogsLottie;