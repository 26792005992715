import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { IoGameController } from 'react-icons/io5'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Edit = () => {
    const history = useNavigate()
    const [descricao, setDescricao] = useState('')
    const [comando, setComando] = useState('')
    const [ativo, setAtivo] = useState(false)
    const customId = 'success-toast-id'
    let { id } = useParams()

    const editarComando = async (e) => {
        e.preventDefault()

        if (descricao === '') {
            toast.error('Por favor preencha o campo de descrição.', { autoClose: 3000 })
        } else if (comando === '') {
            toast.error('Por favor preencha o campo de comando.', { autoClose: 3000 })
        } else {
            await api.put(`/api/comandos/${id}`, {
                descricao,
                comando,
                ativo
            }).then(() => {
                setDescricao('')
                setComando('')
                toast.success('Conteúdo editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/comandos') })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/comandos/${id}`).then((res) => {
                if (cancel) return

                setDescricao(res.data.comando.descricao)
                setComando(res.data.comando.comando)
                setAtivo(res.data.comando.ativo)
            })

        }

        fetchData()

        return () => {
            cancel = true
        }
        // eslint-disable-next-line
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><IoGameController className='fs-1' /><h3 id='tittleH2'> Edição de comando</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/comandos' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Descrição</Form.Label>
                                    <Form.Control className="shadowCustom" id='input-group-edit' type='text' placeholder='Nome' value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Comando</Form.Label>
                                    <Form.Control className="shadowCustom" id='input-group-edit' type='text' placeholder='Nome' value={comando} onChange={(e) => setComando(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Check
                                    type='switch'
                                    id='input-group-edit'
                                    checked={ativo}
                                    onChange={(e) => setAtivo(e.target.checked)}
                                    label='Ativar/Desativar'
                                    style={{ marginTop: '8px', marginBottom: '5px' }}
                                />
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col xs lg="2">
                                <Button className='btnCreateMobile' onClick={editarComando} id='buttonNew' type='submit'>
                                    Editar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit