import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill, BsReverseBackspaceReverse } from 'react-icons/bs'
import { AiOutlineSafetyCertificate } from 'react-icons/ai'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import AnimationConteudoLottie from './AnimationConteudoLottie/AnimationConteudoLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import Popover from 'react-bootstrap/Popover'
import Loading from '../../components/LoaderComponent/loader'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/Preview'
import Preview from '../../components/Preview'

const Index = () => {
    const history = useNavigate()
    const [conteudo, setConteudo] = useState([])
    const [doSpaces, setDoSpaces] = useState('')
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [conteudoIdToDisapproved, setConteudoIdToDisapproved] = useState('')
    const [search, setSearch] = useState('')
    const [details, setDetails] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [conteudoPerPage] = useState(10)
    const indexOfLastConteudo = currentPage * conteudoPerPage
    const indexOfFirstConteudo = indexOfLastConteudo - conteudoPerPage
    const currentConteudo = conteudo.slice(indexOfFirstConteudo, indexOfLastConteudo)
    const nPages = Math.ceil(conteudo.length / conteudoPerPage)
    const [materialPreview, setMaterialPreview] = useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([])

    const getConteudo = async () => {

        setLoading(true);

        try {
        const [permissionsResponse, userPermissionsResponse, conteudoResponse] = await Promise.all([
            api.get('/api/permission/index'),
            api.get(`api/user/${Cookies.get('userId')}`),
            api.get('/api/publicacoes/aprovacao')
        ]);

        setPermissions(permissionsResponse.data.permissions);
        setUserPermissions(userPermissionsResponse.data.permissions);
        setConteudo(conteudoResponse.data.conteudo);
        setDoSpaces(conteudoResponse.data.spaces);
        
        // Opcional: Mensagem de sucesso
        toast.success('Dados carregados com sucesso!', { autoClose: 3000 });
    } catch (error) {
        console.error('Erro:', error);
        // Opcional: Mensagem de erro
        toast.error('Erro ao carregar dados.', { autoClose: 3000 });
    } finally {
        setLoading(false);
    }

        const response = await api.get('/api/publicacoes/aprovacao')
        setConteudo(response.data.conteudo)

        setDoSpaces(response.data.spaces)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showReprovacaoConfirmationModal = (conteudoId) => {
        setModalShow(true)
        setConteudoIdToDisapproved(conteudoId)
    }

    const reprovacaoConteudo = async (conteudoId) => {
        await api.post(`/api/publicacoes/reprovacao/${conteudoId}`).then(() => {
            getConteudo()
            setModalShow(false)
            toast.success('Conteúdo reprovado com sucesso!', { autoClose: 3000 })
        })
    }


    const AprovacaoConteudo = async (conteudoId) => {
        await api.post(`/api/publicacoes/aprovacao/${conteudoId}`).then(() => {
            getConteudo()
            toast.success('Conteúdo aprovado com sucesso!', { autoClose: 3000 })
        })
    }

    function DisapprovedConteudoConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Reprovação de conteúdo.</h4>
                    <p>
                        Você tem certeza de que deseja reprovar o conteúdo {props.localconteudo} ?
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => reprovacaoConteudo(props.conteudoid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Segmentação:</Popover.Header>
            <Popover.Body>
                {details.grupos?.map((grupo, index) => (
                    <div key={`pub-seg-grupo-${grupo.nome}`}>
                        <p className='mr-2'>{`GRUPO - ${grupo.nome}`}</p>
                    </div>
                ))}

                {details.locais?.map((local, index) => (
                    <div key={`pub-seg-local-${local.nome}`}>
                        <p className='mr-2'>{`LOCAL - ${local.nome}`}</p>
                    </div>
                ))}

                {details.maquinas?.map((maquina, index) => (
                    <div key={`pub-seg-maquina-${maquina.nome}`}>
                        <p className='mr-2'>{`MÁQUINA - ${maquina.nome}`}</p>
                    </div>
                ))}
            </Popover.Body>
        </Popover>
    )

    const showEditConteudoPage = (conteudoId) => {
        history(`/conteudo/edit/${conteudoId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getConteudo()
    }, [])

    function handlePreviewInfos(props) {
        setMaterialPreview(props)
        handleShow()
    }

    const filteredConteudo = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentConteudo
        } else {
            return conteudo.filter((conteudo) => conteudo.publicacao.publicacao_id.toString().toLowerCase().includes(lowerSearch) || conteudo.publicacao.data_fim?.toLowerCase().includes(lowerSearch) || conteudo.publicacao.data_inicio.toLowerCase().includes(lowerSearch) || conteudo.publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch))
        }
    }, [search, conteudo, currentConteudo])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className='container'>
                    <DisapprovedConteudoConfirmaitonModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        conteudoid={conteudoIdToDisapproved}
                    />

                    <div id='divContainer' className="container">
                        <DisapprovedConteudoConfirmaitonModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            conteudoid={conteudoIdToDisapproved}
                        />

                        <div className='headerContainer'>
                            <div className='divheaderTittleMobile'>
                                <div id='divHeaderTittle'><AnimationConteudoLottie /><h3 id='tittleH2'>Aprovação</h3></div>
                            </div>

                            <div id='div-input-group' className='div-input-group'>
                                <InputGroup id="input-group" className='input-group'>
                                    <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                                    <Form.Control
                                        placeholder="pesquisar"
                                        aria-label="pesquisar"
                                        aria-describedby="search"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                        </div>

                        <ToastContainer />

                        <div className='container overflow-auto mt-2'>
                            <Table id="tableList" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th className='text-center'>ORDEM</th>
                                        <th className='text-center'>#ID</th>
                                        <th className='text-center'>
                                            <Badge variant="warning" style={{ marginRight: '3px' }}>Material</Badge>
                                            <Badge variant="warning">Template</Badge>
                                        </th>
                                        <th className='text-center'>DATA INICIO</th>
                                        <th className='text-center'>DATA FIM</th>
                                        <th className='text-center'>SEGMENTAÇÃO</th>
                                        <th className='text-center'>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredConteudo.map((publicacao, index) => (
                                        <tr key={publicacao.publicacao.publicacao_id} className={publicacao.publicacao.pub_ativo ? '' : 'table-danger'}>
                                            <td className='text-center'>{publicacao.publicacao.ordem}</td>
                                            <td className='text-center'>{publicacao.publicacao.publicacao_id}</td>
                                            <td className='text-center'>
                                                <Button style={{ backgroundColor: '#0B5ED7', color: '#fff' }} variant="primary" onClick={() => handlePreviewInfos(publicacao.publicacao)} >{publicacao.publicacao.nome_material ? publicacao.publicacao.nome_material.split('.')[1] == 'zip' ? publicacao.publicacao.template_nome : publicacao.publicacao.nome_material.split('.')[0] : ''}</Button>
                                            </td>
                                            <td className='text-center'>{publicacao.publicacao.data_inicio}</td>
                                            <td className='text-center'>{publicacao.publicacao.data_fim ?? 'Sem data fim'}</td>
                                            <td className='text-center'>
                                                <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                                    <Button onClick={() => setDetails(publicacao.segmentacao)} variant='primary'>Segmentação</Button>
                                                </OverlayTrigger>
                                            </td>
                                            <td className='text-center'>
                                                {
                                                    userPermissions.some((permission) => permission.name === 'edit_role') ? (
                                                        <Button className='editButton btnTableMoBile me-2' onClick={() => AprovacaoConteudo(publicacao.publicacao.publicacao_id)}><AiOutlineSafetyCertificate /> Aprovar</Button>
                                                    ) : ''
                                                }

                                                {
                                                    userPermissions.some((permission) => permission.name === 'delete_role') ? (
                                                        <Button className='deleteButton btnTableMoBile' onClick={() => showReprovacaoConfirmationModal(publicacao.publicacao.publicacao_id)}><BsReverseBackspaceReverse /> Reprovar</Button>
                                                    ) : ''
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />

                    </div>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title className='textColors'>Preview</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Preview material={materialPreview} doSpaces={doSpaces} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </>
    )
}

export default Index