import React, { useState, useEffect } from 'react'
import { Bar, Pie, Line } from 'react-chartjs-2'
import { saveAs } from 'file-saver'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import { FaChartColumn, FaChartPie, FaChartLine, FaDownload, FaTableList } from 'react-icons/fa6'
import '../ChartjsDashComponents/styleCardsCharts.css'
import { useNavigate } from 'react-router-dom'
import '../../../components/MobileModeStyle/cardsChartsMobileStyle.css'

const handleDownload = () => {
    const canvas = document.querySelector('canvas')
    const dataURL = canvas.toDataURL('image/png')
    saveAs(dataURL, 'Grafico Total de Maquinas.png')
}

const ChartTotalMachines = ({ online, offline, alerta, total }) => {
    const [chartType, setChartType] = useState('bar')
    const [isDelayed, setIsDelayed] = useState(false)

    useEffect(() => {
        const delayed = () => {
            setIsDelayed(true)
        }

        delayed()

    }, [])

    const barData = {
        labels: ['Online', 'Offline', 'Alerta'],
        datasets: [
            {
                label: ['Quantidade'],
                backgroundColor: ['rgba(0, 144, 255, 0.63)', 'rgba(255, 0, 0, 0.63)', 'rgba(255, 153, 0, 0.64)'],
                borderColor: ['rgba(0, 171, 255, 1)', 'rgba(255, 0, 1, 1)', 'rgba(255, 179, 0, 1)'],
                borderWidth: 1,
                hoverBackgroundColor: ['rgb(0, 144, 255)', 'rgb(255, 0, 0)', 'rgb(255, 153, 0)'],
                hoverBorderColor: ['rgba(0, 171, 255, 1)', 'rgba(255, 0, 1, 1)', 'rgba(255, 179, 0, 1)'],
                data: [online, offline, alerta],
            },
        ],
    }

    const pieData = {
        labels: ['Online', 'Offline', 'Alerta'],
        datasets: [
            {
                backgroundColor: ['rgba(0, 144, 255, 0.63)', 'rgba(255, 0, 0, 0.63)', 'rgba(255, 153, 0, 0.64)'],
                borderColor: ['rgba(0, 171, 255, 1)', 'rgba(255, 0, 1, 1)', 'rgba(255, 179, 0, 1)'],
                borderWidth: 1,
                hoverBackgroundColor: ['rgb(0, 144, 255)', 'rgb(255, 0, 0)', 'rgb(255, 153, 0)'],
                hoverBorderColor: ['rgba(0, 171, 255, 1)', 'rgba(255, 0, 1, 1)', 'rgba(255, 179, 0, 1)'],
                data: [total !== 0 ? (((online / total) * 100).toFixed(2)) : 0, total !== 0 ? (((offline / total) * 100).toFixed(2)) : 0, total !== 0 ? (((alerta / total) * 100).toFixed(2)) : 0],
            },
        ],
    }

    const lineData = {
        labels: ['Online', 'Offline', 'Alerta'],
        datasets: [
            {
                label: ['Quantidade'],
                backgroundColor: ['rgba(0, 144, 255, 0.63)', 'rgba(255, 0, 0, 0.63)', 'rgba(255, 153, 0, 0.64)'],
                borderColor: ['rgba(0, 171, 255, 1)', 'rgba(255, 0, 1, 1)', 'rgba(255, 179, 0, 1)'],
                borderWidth: 1,
                hoverBackgroundColor: ['rgb(0, 144, 255)', 'rgb(255, 0, 0)', 'rgb(255, 153, 0)'],
                hoverBorderColor: ['rgba(0, 171, 255, 1)', 'rgba(255, 0, 1, 1)', 'rgba(255, 179, 0, 1)'],
                data: [online, offline, alerta],
            },
        ],
    }

    const barOptions = {
        animation: {
            onComplete: () => { },
            delay: (context) => {
                let delay = 0
                if (context.type === 'data' && context.mode === 'default' && !isDelayed) {
                    delay = context.dataIndex * 400 + context.datasetIndex * 100
                }
                return delay
            },
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                color: 'black',
                anchor: 'top',
                align: 'center',
                formatter: (value) => {
                    return value
                },
            },
        },
    }

    const pieOptions = {
        aspectRatio: 1.9,
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                color: 'white',
                anchor: 'end',
                align: 'start',
                font: {
                    size: '30',
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
        },
    }

    const lineOptions = {
        scales: {
            x: {
                stacked: true,
            },
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                color: 'black',
                anchor: 'end',
                align: 'center',
                font: {
                    size: '30',
                },
                formatter: (value) => {
                    return `${value}%`
                },
            },
        },
    }

    const handleBarChart = () => {
        setChartType('bar')
    }

    const handlePieChart = () => {
        setChartType('pie')
    }

    const handleLineChart = () => {
        setChartType('line')
    }

    const navigate = useNavigate()

    const handleButtonClick = () => {
        navigate('/dashboard/maquinasRelatorio')
    }

    return (
        <div className='backgroundCardChart'>
            <div className='divBtnGroup-ChartFunctions'>
                <ButtonGroup className='btnGroup-ChartFunctions'>
                    <Button className='d-flex align-items-center' onClick={handleButtonClick}>
                        <FaTableList className='btnRelatorioDash' />
                        <span className='spanBtnRelatorioDash'>Relatório</span>
                    </Button>
                    <Button onClick={handleBarChart}>
                        <FaChartColumn
                            className='faDownloadIcon'
                            data-bs-toggle='tooltip'
                            title='Grafico de barras' />
                    </Button>
                    <Button onClick={handlePieChart}>
                        <FaChartPie
                            className='faDownloadIcon'
                            data-bs-toggle='tooltip'
                            title='Grafico de pizza' />
                    </Button>
                    <Button onClick={handleLineChart}>
                        <FaChartLine
                            className='faDownloadIcon'
                            data-bs-toggle='tooltip'
                            title='Grafico de linha' />
                    </Button>
                    <Button>
                        <FaDownload
                            className='faDownloadIcon'
                            onClick={handleDownload}
                            data-bs-toggle='tooltip'
                            title='Download do Grafico' />
                    </Button>
                </ButtonGroup>
            </div>
            {chartType === 'bar' && <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} />}
            {chartType === 'pie' && <Pie data={pieData} options={pieOptions} plugins={[ChartDataLabels]} />}
            {chartType === 'line' && <Line data={lineData} options={lineOptions} />}
        </div>
    )
}

export default ChartTotalMachines
