import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Select from 'react-select'
import { Badge, ButtonGroup } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import AnimationPubLottie from './AnimationPubLottie/AnimationPubLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import { RxOpenInNewWindow } from 'react-icons/rx'
import { IoMdArrowDropleft } from 'react-icons/io'
import { IoCalendarOutline } from 'react-icons/io5'
import io from 'socket.io-client'
import { FaRegListAlt } from 'react-icons/fa'
import dayGridPlugin from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrap5Plugin from '@fullcalendar/bootstrap5'
import timeGridPlugin from '@fullcalendar/timegrid'
import { BiMessageSquareDetail } from 'react-icons/bi'
import { MdOutlinePermMedia } from 'react-icons/md'
import { PiIdentificationCard } from 'react-icons/pi'
import Preview from '../../components/Preview'
import '@fullcalendar/core/locales-all'
import '@fullcalendar/core/locales/pt-br'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { FaSearch } from 'react-icons/fa'
import { TbFilter } from 'react-icons/tb'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FiUsers } from 'react-icons/fi'
import { AiOutlinePlus } from 'react-icons/ai'
import { TbTemplate } from 'react-icons/tb'
import { GiPositionMarker } from 'react-icons/gi'
import { LuComputer } from 'react-icons/lu'
import { TbUsersGroup } from 'react-icons/tb'
import { FaArrowRotateRight } from 'react-icons/fa6'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { BiWindows } from 'react-icons/bi'
import { MdArrowForwardIos } from 'react-icons/md'
import moment from 'moment'
import Loading from '../../components/LoaderComponent/loader'
import { LuLayoutTemplate } from 'react-icons/lu'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/Preview'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'
import '../../components/MobileModeStyle/calendarioMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [publicacoes, setPublicacoes] = useState([])
    const [clientes, setClientes] = useState([])
    const [conteudos, setConteudos] = useState([])
    const [templates, setTemplates] = useState([])
    const [locais, setLocais] = useState([])
    const [maquinas, setMaquinas] = useState([])
    const [grupos, setGrupos] = useState([])
    const [selectedClient, setSelectedClient] = useState({ value: 'todos', label: 'Todos' })
    const [selectedContent, setSelectedContent] = useState({ value: 'todos', label: 'Todos' })
    const [selectedTemplate, setSelectedTemplate] = useState({ value: 'todos', label: 'Todos' })
    const [selectedLocal, setSelectedLocal] = useState({ value: 'todos', label: 'Todos' })
    const [selectedMaquina, setSelectedMaquina] = useState({ value: 'todos', label: 'Todos' })
    const [selectedGrupo, setSelectedGrupo] = useState({ value: 'todos', label: 'Todos' })
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [publicacaoIdToDelete, setPublicacaoIdToDelete] = useState('')
    const [publicacaoNameToDelete, setPublicacaoNameToDelete] = useState('')
    const [search, setSearch] = useState('')
    const [doSpaces, setDoSpaces] = useState('')
    const [details, setDetails] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [publicacoesPerPage] = useState(10)
    const indexOfLastPublicacao = currentPage * publicacoesPerPage
    const indexOfFirstPublicacao = indexOfLastPublicacao - publicacoesPerPage
    const currentPublicacoes = publicacoes.slice(indexOfFirstPublicacao, indexOfLastPublicacao)
    const nPages = Math.ceil(publicacoes.length / publicacoesPerPage)
    const [materialPreview, setMaterialPreview] = useState([])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const [selectedPublicacao, setSelectedPublicacao] = useState(null)
    const [modalCalendarShow, setModalCalendarShow] = useState(false)
    const [status, setStatus] = useState({ value: 'veiculando', label: 'Veiculando' })
    const [fullscreen, setFullscreen] = useState(true)
    const [loading, setLoading] = useState(true)
    const [permissions, setPermissions] = useState([])

    const getPublicacoes = async () => {

        setLoading(true)

        try {
            const [permissionsResponse, userPermissionsResponse, publicacoesResponse] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/publicacao/index')
            ])

            setPermissions(permissionsResponse.data.permissions)
            setUserPermissions(userPermissionsResponse.data.permissions)
            setPublicacoes(publicacoesResponse.data.publicacoes);

        } catch (error) {
            console.error('Erro')
        } finally {
            setLoading(false)
        }

        const response = await api.get('/api/publicacao/index')
        setPublicacoes(response.data.publicacoes)
        setDoSpaces(response.data.spaces)
        setClientes(response.data.clientes)

        if (response.data.clientes.length > 0) {
            setSelectedClient(response.data.clientes[0])
        }

        setConteudos(response.data.conteudos)

        if (response.data.conteudos.length > 0) {
            setSelectedContent(response.data.conteudos[0])
        }

        setTemplates(response.data.templates)
        setLocais(response.data.locais)
        setMaquinas(response.data.maquinas)
        setGrupos(response.data.grupos)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeletePublicacaoConfirmationModal = (publicacaoId, publicacaoName) => {
        setModalShow(true)
        setPublicacaoIdToDelete(publicacaoId)
        setPublicacaoNameToDelete(publicacaoName)
    }

    const deletePublicacao = async (publicacaoId) => {
        await api.delete(`/api/publicacao/${publicacaoId}`).then(() => {
            getPublicacoes()
            setModalShow(false)
            toast.success('Publicação deletada com sucesso!', { autoClose: 3000 })
        })
    }

    const popover = (
        <Popover className='bg-secondary text-white shadowCustom' hover='true' id='popover-basic'>
            <Popover.Header className='bg-dark fs-6' as='h3'>Segmentação</Popover.Header>
            <Popover.Body className='text-white fw-semibold'>
                {details.grupos?.map((grupo, index) => (
                    <div key={`pub-seg-grupo-${grupo.nome}`}>
                        <p className='mr-2'>{`GRUPO - ${grupo.nome}`}</p>
                    </div>
                ))}

                {details.locais?.map((local, index) => (
                    <div key={`pub-seg-local-${local.nome}`}>
                        <p className='mr-2'>{`LOCAL - ${local.nome}`}</p>
                    </div>
                ))}

                {details.maquinas?.map((maquina, index) => (
                    <div key={`pub-seg-maquina-${maquina.nome}`}>
                        <p className='mr-2'>{`MÁQUINA - ${maquina.nome}`}</p>
                    </div>
                ))}
            </Popover.Body>
        </Popover>
    )

    function handlePreviewInfos(props) {
        setMaterialPreview(props)
        handleShow()
    }

    function DeletePublicacaoConfirmationModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de publicacao.</h4>
                    <p>
                        Você tem certeza de que deseja remover a publicacao {props.publicacaoname} ?
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deletePublicacao(props.publicacaoid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }


    const showEditPublicacaoPage = (publicacaoId) => {
        history(`/publicacao/edit/${publicacaoId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx)
        }

        let pageNumbers = []
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))]
            if (nPages > 5) pageNumbers.push('...', nPages)
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages]
        } else {
            pageNumbers = [1, '...']
            pageNumbers.push(...range(nPages - 4, nPages))
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        )
    }

    const filteredPublicacoes = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentPublicacoes
        } else {
            return publicacoes.filter((publicacao) => publicacao.publicacao.publicacao_id.toString().toLowerCase().includes(lowerSearch) || publicacao.publicacao.data_fim?.toLowerCase().includes(lowerSearch) || publicacao.publicacao.data_inicio.toLowerCase().includes(lowerSearch) || publicacao.publicacao.status_aprovacao?.toLowerCase().includes(lowerSearch))
        }
    }, [search, publicacoes, currentPublicacoes])

    const handleDragEnd = async (result) => {
        if (!result.destination) return

        const items = Array.from(publicacoes)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        setPublicacoes(items)

        await api.post('/api/publicacoes/updateOrder', { ordem: items.map(item => item.publicacao.publicacao_id) })

        getPublicacoes()
    }

    useEffect(() => {
        io(process.env.REACT_APP_UPDATE_PLAYLIST_SOCKET, { transports: ['websocket'] })
        getPublicacoes()
    }, [])

    const openModal = (publicacao) => {
        setSelectedPublicacao(publicacao)
        setModalCalendarShow(true)
    }

    const [viewMode, setViewMode] = useState('tabela')

    const toggleViewMode = (mode) => {
        setViewMode(mode)
    }

    async function handleFilter() {
        const response = await api.post(`/api/filterPubs`, {
            status,
            cliente: selectedClient,
            conteudo: selectedContent,
            template: selectedTemplate,
            local: selectedLocal,
            maquina: selectedMaquina,
            grupo: selectedGrupo
        })

        setPublicacoes(response.data.publicacoes)
    }

    const [showInputsFilters, setShowInputsFilters] = useState(false)

    const buttonInputsFilters = () => {
        setShowInputsFilters(!showInputsFilters)
    }

    const opcoesStatus = [
        { value: 'todos', label: 'Todos' },
        { value: 'veiculando', label: 'Veiculando' },
        { value: 'antigas', label: 'Antigas' },
        { value: 'futuras', label: 'Futuras' },
    ]
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: 'dark',
            borderColor: state.isFocused ? '#lightskyblue' : 'var(--bg-BoderColorTable)',
            '&:hover': {
                borderColor: state.isFocused ? 'lightskyblue' : 'lightskyblue'
            }
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--bg-colorText)',
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px solid var(--bg-BoderColorTable)',
            marginLeft: '0.5rem',
            zIndex: 3,
            backgroundColor: state.isSelected ? 'lightskyblue' : 'var(--bgroot-color)',
            color: state.isSelected ? 'var(--bgroot-color)' : 'var(--bg-colorText)',
            '&:hover': {
                backgroundColor: state.isSelected ? 'lightblue' : 'lightskyblue',
                color: state.isSelected ? 'var(--bg-colorText)' : 'var(--bgroot-color)'
            }
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'var(--bgroot-color)',
            color: 'var(--bg-colorText)',
            border: '1px solid var(--bg-colorText)',
            zIndex: 5,
        }),
        input: (provided) => ({
            ...provided,
            color: 'var(--bg-colorText)',
        })
    }

    const handleChangeFilterOptions = async (cliente) => {
        setSelectedClient(cliente)
        const response = await api.get(`/api/changeFilterOptionsByClient/${cliente.value}`)

        setConteudos(response.data.conteudos)

        if (response.data.conteudos.length > 0) {
            setSelectedContent(response.data.conteudos[0])
        }

        setTemplates(response.data.templates)
        setSelectedTemplate({ value: 'todos', label: 'Todos' })

        setLocais(response.data.locais)
        setSelectedLocal({ value: 'todos', label: 'Todos' })

        setMaquinas(response.data.maquinas)
        setSelectedMaquina({ value: 'todos', label: 'Todos' })

        setGrupos(response.data.grupos)
        setSelectedGrupo({ value: 'todos', label: 'Todos' })
    }

    const handleChangePlace = async (place) => {
        setSelectedLocal(place)

        const response = await api.post(`/api/maquina/getMachinesByPlaceId`, { locais: [place] })

        setMaquinas(response.data.maquinas)
    }

    const [rotation, setRotation] = useState(0)

    const rotateLeft = () => {
        setRotation(prevRotation => prevRotation - 90) // Girar 90 graus para a esquerda
    }

    const rotateRight = () => {
        setRotation(prevRotation => prevRotation + 90) // Girar 90 graus para a direita
    }

    return (
        <>
            <NavBar />
            {loading ? (
                <div className='loading'><Loading /></div>
            ) : (
                <>
                    <div id='divContainer' className='container'>
                        <DeletePublicacaoConfirmationModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            publicacaoid={publicacaoIdToDelete}
                            publicacaoname={publicacaoNameToDelete}
                        />

                        <div className='headerContainer'>
                            <div className='divheaderTittleMobile'>
                                <div id='divHeaderTittle'><AnimationPubLottie /><h3 id='tittleH2'>Publicações</h3></div>
                            </div>

                            <div id='div-input-group' className='div-input-group'>
                                <div style={{ display: 'flex' }}>
                                    <InputGroup id='input-group' className='input-group'>
                                        <InputGroup.Text id='search'><AiOutlineSearch /></InputGroup.Text>
                                        <Form.Control
                                            placeholder='pesquisar'
                                            aria-label='pesquisar'
                                            aria-describedby='search'
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </InputGroup>
                                </div>

                                {
                                    userPermissions.some((permission) => permission.name === 'store_pub') ? (
                                        <NavLink className='btnVoltarMobile' to='/publicacao/new' style={{ textDecoration: 'none' }}>
                                            <Button className='borderbtnCreateEdit' id='buttonNew'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Nova Publicação</span></Button>
                                        </NavLink>
                                    ) : ''
                                }
                            </div>
                        </div>

                        <ToastContainer />

                        <div className='d-flex-column mb-2'>
                            <Row>
                                <div className='d-flex align-items-end hideColMobileClass'>
                                    <ButtonGroup className='shadowCustom'>
                                        <Button variant='secondary' className='d-flex align-items-center' onClick={() => toggleViewMode('tabela')} active={viewMode === 'tabela'}><FaRegListAlt className='fs-5 me-2' />Tabela</Button>
                                        <Button variant='secondary' className='d-flex align-items-center' onClick={() => toggleViewMode('calendario')} active={viewMode === 'calendario'}><IoCalendarOutline className='fs-5 me-2' />Calendário</Button>
                                    </ButtonGroup>
                                </div>
                            </Row>
                            <Row md={4}>
                                <Col className='d-flex align-items-end showColMobileClass'>
                                    <ButtonGroup className='shadowCustom'>
                                        <Button variant='secondary' className='d-flex align-items-center' onClick={() => toggleViewMode('tabela')} active={viewMode === 'tabela'}>
                                            <FaRegListAlt className='fs-5 me-2' />Tabela</Button>
                                        <Button variant='secondary' className='d-flex align-items-center' onClick={() => toggleViewMode('calendario')} active={viewMode === 'calendario'}>
                                            <IoCalendarOutline className='fs-5 me-2' />Calendário</Button>
                                        <Button className='d-flex align-items-center' variant='secondary' onClick={buttonInputsFilters}>
                                            <AiOutlinePlus className='fs-5 me-2' />Filtros
                                        </Button>
                                        <Button onClick={handleFilter} className='btnSearchFilterClass'>
                                            <FaSearch className='fs-5' />
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                                <div className='d-flex gap-3 divFilterMobileClass'>
                                    <Col className='colUnitMobileClass'>
                                        <div className='mb-0'>
                                            <label className='d-flex align-items-center positionHeaderClass textColors'>
                                                <FiUsers className='me-1' />
                                                <span className='fw-semibold'>
                                                    Cliente
                                                </span>
                                            </label>
                                            <InputGroup>
                                                <Select
                                                    className='selectFilterClass shadowCustom rounded-1'
                                                    placeholder='clientes'
                                                    id='clientes'
                                                    value={selectedClient}
                                                    onChange={(e) => handleChangeFilterOptions(e)}
                                                    options={clientes}
                                                    styles={customStyles}
                                                />
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col className='colUnitMobileClass'>
                                        <div className='mb-0'>
                                            <label className='d-flex align-items-center positionHeaderClass textColors'>
                                                <MdOutlinePermMedia className='me-1' />
                                                <span className='fw-semibold'>
                                                    Conteúdo
                                                </span>
                                            </label>
                                            <InputGroup>
                                                <Select
                                                    className='selectFilterClass shadowCustom rounded-1'
                                                    placeholder='conteudos'
                                                    id='conteudos'
                                                    value={selectedContent}
                                                    onChange={(e) => setSelectedContent(e)}
                                                    options={conteudos}
                                                    styles={customStyles}
                                                >
                                                </Select>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col className='colUnitMobileClass'>
                                        <div className='mb-0'>
                                            <label className='d-flex align-items-center positionHeaderClass textColors'>
                                                <TbFilter className='me-1' />
                                                <span className='fw-semibold'>
                                                    Status
                                                </span>
                                            </label>
                                            <InputGroup>
                                                <Select
                                                    className='selectFilterClass shadowCustom rounded-1'
                                                    placeholder='Todos'
                                                    id='status'
                                                    value={status}
                                                    onChange={(e) => setStatus(e)}
                                                    options={opcoesStatus}
                                                    styles={customStyles}
                                                >
                                                </Select>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col className='d-flex align-items-end hideColMobileClass'>
                                        <ButtonGroup className='shadowCustom'>
                                            <Button className='d-flex align-items-center' variant='secondary' onClick={buttonInputsFilters}>
                                                <AiOutlinePlus className='fs-5 me-2' />Filtros
                                            </Button>
                                            <Button onClick={handleFilter} className='btnSearchFilterClass'>
                                                <FaSearch className='fs-5' />
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </div>
                            </Row>
                        </div>
                        <div className='divFilterPublicacao'>
                            <Row className={`inputFilterClass ${showInputsFilters ? 'visible' : 'hidden'}`}>
                                <div className='d-flex gap-3 divFilterMobileClass'>
                                    <Col className='colUnitMobileClass'>
                                        <label className='d-flex align-items-center positionHeaderClass'>
                                            <TbTemplate className='me-1' />
                                            <span>
                                                Template
                                            </span>
                                        </label>
                                        <InputGroup>
                                            <Select
                                                className='selectFilterClass shadowCustom rounded-1'
                                                placeholder='Todos'
                                                id='templates'
                                                value={selectedTemplate}
                                                onChange={(e) => setSelectedTemplate(e)}
                                                options={templates}
                                                styles={customStyles}
                                            >
                                            </Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className='colUnitMobileClass'>
                                        <label className='d-flex align-items-center positionHeaderClass'>
                                            <GiPositionMarker className='me-1' />
                                            <span className='control-label'>
                                                Local
                                            </span>
                                        </label>
                                        <InputGroup>
                                            <Select
                                                className='selectFilterClass shadowCustom rounded-1'
                                                placeholder='locais'
                                                id='locais'
                                                value={selectedLocal}
                                                onChange={(e) => handleChangePlace(e)}
                                                options={locais}
                                                styles={customStyles}
                                            >
                                            </Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className='colUnitMobileClass'>
                                        <label className='d-flex align-items-center positionHeaderClass'>
                                            <LuComputer className='me-1' />
                                            <span className='control-label'>
                                                Máquina
                                            </span>
                                        </label>
                                        <InputGroup>
                                            <Select
                                                className='selectFilterClass shadowCustom rounded-1'
                                                placeholder='maquinas'
                                                id='maquinas'
                                                value={selectedMaquina}
                                                onChange={(e) => setSelectedMaquina(e)}
                                                options={maquinas}
                                                styles={customStyles}
                                            >
                                            </Select>
                                        </InputGroup>
                                    </Col>
                                    <Col className='colUnitMobileClass'>
                                        <label className='d-flex align-items-center positionHeaderClass'>
                                            <TbUsersGroup className='me-1' />
                                            <span className='control-label'>
                                                Grupo
                                            </span>
                                        </label>
                                        <InputGroup>
                                            <Select
                                                className='selectFilterClass shadowCustom rounded-1'
                                                placeholder='grupos'
                                                id='grupos'
                                                value={selectedGrupo}
                                                onChange={(e) => setSelectedGrupo(e)}
                                                options={grupos}
                                                styles={customStyles}
                                            >
                                            </Select>
                                        </InputGroup>
                                    </Col>
                                </div>
                            </Row>
                        </div>
                        <div className='container overflow-auto zIndexClass'>
                            {viewMode === 'tabela' && (
                                <div>
                                    <Table id='tableList' striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>Ordem</th>
                                                <th className='text-center'>#ID</th>
                                                <th className='w-100 d-flex justify-content-evenly'>
                                                    <span className='spanClassMaterial d-flex align-items-center'><MdOutlinePermMedia className='m-1' />Material</span><span className='spanClassTemplate d-flex align-items-center'><LuLayoutTemplate className='m-1' />Template</span>
                                                </th>
                                                <th className='text-center'>Data/Hora inicio</th>
                                                <th className='text-center'>Data/Hora fim</th>
                                                <th className='text-center'>Segmentação</th>
                                                <th className='text-center'>Ações</th>
                                            </tr>
                                        </thead>
                                        <DragDropContext onDragEnd={handleDragEnd}>
                                            <Droppable droppableId='publicacoes'>
                                                {(provided) => (
                                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                        {filteredPublicacoes.map((publicacao, index) => (
                                                            <Draggable key={publicacao.publicacao.publicacao_id} draggableId={publicacao.publicacao.publicacao_id.toString()} index={index}>
                                                                {(provided) => (
                                                                    <tr
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        className={publicacao.publicacao.pub_ativo ? '' : 'table-danger'}
                                                                    >
                                                                        <td className='text-center'>{publicacao.publicacao.ordem}</td>
                                                                        <td className='text-center'>{publicacao.publicacao.publicacao_id}</td>
                                                                        <td className='text-center w-25'>
                                                                            <div className='d-flex justify-content-center'>
                                                                                <button onClick={() => handlePreviewInfos(publicacao.publicacao)} className={publicacao.publicacao.nome_original ? publicacao.publicacao.nome_original.split('.')[1] === 'zip' ? 'btnMaterialTemplate p-1 d-flex' : 'btnMaterialTemplate p-1 fw-medium' : ''} >
                                                                                    {publicacao.publicacao.template_nome ? <LuLayoutTemplate className='colorIconTemplate' /> : <MdOutlinePermMedia className='colorIconMaterial' />}
                                                                                    {publicacao.nome_material ? publicacao.nome_material : publicacao.nome_template}
                                                                                    {publicacao.publicacao.nome_material ? publicacao.publicacao.nome_material.split('.')[1] === 'zip' ? publicacao.publicacao.template_nome : publicacao.publicacao.nome_material.split('.')[0] : ''}
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                        {/* <td className='text-center'>{publicacao.publicacao.nome_original? publicacao.publicacao.nome_original.split('.')[0] : ''}</td> */}
                                                                        <td className='text-center'><Badge className='bg-opacity-50 p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{moment(publicacao.publicacao.data_inicio).format('DD/MM/YYYY [|] HH[h]mm[min]')}</Badge></td>
                                                                        <td className='text-center'><Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>{publicacao.publicacao.data_fim ? moment(publicacao.publicacao.data_fim).format('DD/MM/YYYY [|] HH[h]mm[min]') : 'Sem data fim'}</Badge></td>
                                                                        <td className='text-center'>
                                                                            <OverlayTrigger trigger='click' placement='right' overlay={popover}>
                                                                                <Button onClick={() => setDetails(publicacao.segmentacao)} className='shadowCustom  fw-medium' variant='secondary'>Segmentação <MdArrowForwardIos />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        </td>
                                                                        <td className='text-center'>

                                                                            {
                                                                                userPermissions.some((permission) => permission.name === 'edit_pub') ? (
                                                                                    <Button className='editButton btnTableMoBile me-2' onClick={() => showEditPublicacaoPage(publicacao.publicacao.publicacao_id)}><BsPencilSquare /> Editar</Button>
                                                                                ) : ''
                                                                            }

                                                                            {
                                                                                userPermissions.some((permission) => permission.name === 'delete_pub') ? (
                                                                                    <Button className='deleteButton btnTableMoBile' onClick={() => showDeletePublicacaoConfirmationModal(publicacao.publicacao.publicacao_id, publicacao.publicacao.publicacao_id)}><FaTrash /> Excluir</Button>
                                                                                ) : ''
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Table>
                                    <Pagination
                                        nPages={nPages}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                            )}

                            {viewMode === 'calendario' && (
                                <div className='mt-2 mb-4 widthMobileCalendar'>
                                    <FullCalendar
                                        viewClassNames='colorFullCalendar'
                                        eventClick={(eventInfo) => openModal(eventInfo)}
                                        locale='pt-br'
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin]}
                                        initialView='dayGridMonth'
                                        dayMaxEvents='true'
                                        dayMaxEventRows={10}
                                        themeSystem='bootstrap5'
                                        navLinks='true'
                                        events={filteredPublicacoes.map(publicacao => ({
                                            title: `${publicacao.publicacao.template_nome ? publicacao.publicacao.template_nome : publicacao.publicacao.nome_material}`,
                                            start: publicacao.publicacao.data_inicio,
                                            end: publicacao.publicacao.data_fim,
                                            customInfo: { id_pub: publicacao.publicacao.publicacao_id, segmentacao: publicacao.segmentacao, hasEnd: publicacao.publicacao.data_fim ? true : false, publi: publicacao.publicacao }
                                        }))}
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay'
                                        }}
                                        buttonText={{
                                            today: 'Hoje',
                                            month: 'Mês',
                                            week: 'Semana',
                                            day: 'Dia'
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <Modal show={show} onHide={handleClose} fullscreen={fullscreen}>
                        <Modal.Header closeButton>
                            <Modal.Title className='textColors d-flex align-items-center'><BiWindows className='fs-4 me-2' />Preview</Modal.Title>
                            <div className='divArrowRotate shadowCustom'>
                                <FaArrowRotateLeft className='arrowDirectionDivModal arrowRotateRigth m-1' onClick={rotateLeft} />
                                <FaArrowRotateRight className='arrowDirectionDivModal arrowRotateLeft m-1' onClick={rotateRight} />
                            </div>
                        </Modal.Header>
                        <Modal.Body className='d-flex align-items-center justify-content-center'>
                            <div className='rotated-div' style={{ transform: `rotate(${rotation}deg)`, width: '70%' }}>
                                <Preview material={materialPreview} doSpaces={doSpaces} />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <Modal show={modalCalendarShow} onHide={() => setModalCalendarShow(false)}>
                        <Modal.Header className='bg-dark text-white'>
                            <Modal.Title className='d-flex align-items-center'><BiMessageSquareDetail className='me-2' />Detalhes da Publicação</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPublicacao && (
                                <div>
                                    <div className='d-flex justify-content-end'>
                                        <button className={selectedPublicacao.url_template ? 'shadowCustom btnPreviewModalCalendar' : 'shadowCustom btnPreviewModalCalendar'} onClick={() => handlePreviewInfos(selectedPublicacao.event._def.extendedProps.customInfo.publi)} >Preview
                                            <RxOpenInNewWindow className='m-1' />
                                            {selectedPublicacao.nome_material ? selectedPublicacao.nome_material : selectedPublicacao.nome_template}</button>
                                    </div>
                                    <div className='divMaquinasNovaPub rounded-1 p-1'>
                                        <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'><PiIdentificationCard className='me-1 fs-5' />
                                            <label className='fw-medium me-2'>#ID:</label>
                                            {selectedPublicacao.event._def.extendedProps.customInfo.id_pub}</p>
                                        <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'><MdOutlinePermMedia className='me-1 fs-5' />
                                            <label className='fw-medium me-2'>Material/Template:</label>
                                            {selectedPublicacao.event._def.title}</p>
                                        <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'><IoCalendarOutline className='me-1 fs-5' />
                                            <label className='fw-medium me-2'>Data/Hora Início:</label>
                                            {moment(selectedPublicacao.event._instance.range.start).format('DD/MM/YYYY [|] HH[h]mm[min]')}</p>
                                        <p className=' fs-6 badgeCustomDataInicioFim d-flex align-items-center'><IoCalendarOutline className='me-1 fs-5' />
                                            <label className='fw-medium me-2'>Data/Hora Fim:</label>
                                            {selectedPublicacao.event._def.extendedProps.customInfo.hasEnd ? moment(selectedPublicacao.event._instance.range.end).format('DD/MM/YYYY [|] HH[h]mm[min]') : <span className='text-danger'>Sem data fim.</span>}</p>
                                    </div>
                                    <div className='d-flex justify-content-between mt-2 divModalMobileCalendar'>
                                        <OverlayTrigger trigger='click' placement='left' overlay={popover} className='testeeeee'>
                                            <Button onClick={() => setDetails(selectedPublicacao.event._def.extendedProps.customInfo.segmentacao)} className='shadowCustom fw-medium' variant='secondary'><IoMdArrowDropleft />Segmentação
                                            </Button>
                                        </OverlayTrigger>
                                        <div className='divBtnGrpMobileCalendar'>
                                            {
                                                userPermissions.some((permission) => permission.name === 'edit_pub') ? (
                                                    <Button className='editButton btnTableMoBile' onClick={() => showEditPublicacaoPage(selectedPublicacao.event._def.extendedProps.customInfo.id_pub)}><BsPencilSquare /> Editar</Button>
                                                ) : ''
                                            }

                                            {
                                                userPermissions.some((permission) => permission.name === 'delete_pub') ? (
                                                    <Button className='deleteButton btnTableMoBile btnCustomDflex' onClick={() => showDeletePublicacaoConfirmationModal(selectedPublicacao.event._def.extendedProps.customInfo.id_pub)}><FaTrash /> Excluir</Button>
                                                ) : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Modal.Body>
                    </Modal>
                </>
            )}
        </>
    )
}

export default Index