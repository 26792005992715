import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsNewspaper } from 'react-icons/bs'
import { BiLayerPlus } from "react-icons/bi";
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css'
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview'
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginMediaPreview,
    FilePondPluginFileMetadata,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize)

const Edit = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [logo, setLogo] = useState('')
    const [ativo, setAtivo] = useState(false)
    const customId = 'success-toast-id'
    let { id } = useParams()

    const onFileChange = (files) => {
        if (files.length > 0) {
            setLogo(files[0].file)
        } else {
            setLogo(null)
        }
    }

    const editProvedores = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else {
            const formData = new FormData()
            formData.append('nome', name)
            formData.append('logo', logo)
            formData.append('ativo', ativo)

            await api.post(`/api/noticiaProvedorUpdate/${id}`, formData).then(() => {
                setName('')
                toast.success('Provedor editado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/provedores') })
            })
        }

    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.get(`api/noticiaProvedor/${id}`).then((res) => {
                if (cancel) return

                setName(res.data.provedor.nome)
                setAtivo(res.data.provedor.ativo)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [id])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'>
                            <BsNewspaper className='fs-1' />
                            <h3 id='tittleH2'> Edição de provedor</h3>
                        </div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/provedores' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Form className='mb-5 formGenMobile'>
                        <Row className='rowGenForm'>
                            <Col>
                                <Form.Group className='mb-3' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='input-group-edit' id='input-group-edit' type='text' placeholder='nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Table className='shadowCustom' striped bordered>
                                    <thead>
                                        <tr>
                                            <th><BiLayerPlus className='fs-4 m-1' />Adicionar Logo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div >
                                                    <FilePond
                                                        labelIdle='<span class="filepond--label-action filepond--drop-label">Arraste e solte seus arquivos ou clique aqui para selecionar</span>'
                                                        files={logo ? [{ source: logo }] : []}
                                                        onupdatefiles={onFileChange}
                                                        allowMultiple={false}
                                                        maxFiles={1}
                                                        acceptedFileTypes={['image/*']}
                                                        maxFileSize="200MB"
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                        <Row className='d-flex justify-content-between'>
                            <Col xs lg='2'>
                                <Form.Check
                                    type='switch'
                                    id='input-group-edit'
                                    checked={ativo}
                                    onChange={(e) => setAtivo(e.target.checked)}
                                    label='Ativar/Desativar'
                                    style={{ marginTop: '8px', width: '100vw', marginBottom: '5px' }}
                                />
                            </Col>
                            <Col xs lg='2'>
                                <Button className='btnCreateMobile' onClick={editProvedores} id='buttonNew' type='submit'>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>
    )
}

export default Edit