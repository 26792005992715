import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft, BsGridFill } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Table from 'react-bootstrap/Table'
import { FaTrash } from 'react-icons/fa'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Cookies from 'js-cookie'
import ListGroup from 'react-bootstrap/ListGroup';
import { MdLibraryAdd } from "react-icons/md";

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Create = () => {
    const history = useNavigate()
    const [name, setName] = useState('')
    const [clients, setClients] = useState([])
    const [clientToAssociate, setClientToAssociate] = useState(null)
    const [conteudos, setConteudos] = useState([])
    const [selectConteudos, setSelectConteudos] = useState([])
    const customId = 'success-toast-id'

    const createGrade = async (e) => {
        e.preventDefault()

        if (name === '') {
            toast.error('Por favor preencha o campo de nome.', { autoClose: 3000 })
        } else if (clientToAssociate === '') {
            toast.error('Por favor selecione um cliente.', { autoClose: 3000 })
        } else {
            await api.post('/api/grade/store', {
                name,
                ambienteToAssociate: Cookies.get('ambienteId'),
                clientToAssociate,
                selectConteudos
            }).then(() => {
                setName('')
                toast.success('Grade criada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/grades') })
            })

        }

    }

    const handleChangeCliente = async (e) => {
        setClientToAssociate(e)
    }

    const handleAddConteudo = (conteudo) => {

        setSelectConteudos([...selectConteudos, { ...conteudo, uniqueId: Date.now() }])
    }

    const handleDragEnd = (result) => {
        if (!result.destination) return

        const updatedItems = Array.from(selectConteudos)
        const [reorderedItem] = updatedItems.splice(result.source.index, 1)
        updatedItems.splice(result.destination.index, 0, reorderedItem)

        setSelectConteudos(updatedItems)
    }

    const handleDeleteConteudo = (uniqueId) => {
        const updatedSelectedConteudos = selectConteudos.filter((conteudo) => conteudo.uniqueId !== uniqueId)
        setSelectConteudos(updatedSelectedConteudos)
    }

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            await api.post(`api/client/getClientByAmbienteId`, {
                ambiente: Cookies.get('ambienteId')
            }).then((res) => {
                if (cancel) return

                let clientsData = [{ label: 'Todos', value: 'todos' }]

                res.data.clients.forEach(client => {
                    clientsData.push({
                        value: client.id,
                        label: client.name
                    })
                })

                setClients(clientsData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    useEffect(() => {
        if (clientToAssociate) {
            api.post(`api/conteudo/getConteudosByClientId`, {
                cliente: clientToAssociate,
            }).then((res) => {
                let conteudosData = []

                res.data.conteudos.forEach((conteudo) => {
                    conteudosData.push({
                        value: conteudo.id,
                        label: conteudo.name,
                    })
                })

                setConteudos(conteudosData)
            })
        }
    }, [clientToAssociate])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><BsGridFill className='fs-1' /><h3 id='tittleH2'> Nova grade</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/grades' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div className='formGenMobile'>
                    <Row className='rowGenForm'>
                        <Col>
                            <Form className='d-flex justify-content-between mb-3 rowGenForm'>
                                <Form.Group className='w-100 m-1' controlid='formBasicName'>
                                    <Form.Label className='fw-semibold'>Nome</Form.Label>
                                    <Form.Control className='shadowCustom' id='input-group-edit' type='text' placeholder='Nome' value={name} onChange={(e) => setName(e.target.value)} />
                                </Form.Group>

                                <Form.Group className='w-100 m-1'>
                                    <Form.Label className='fw-semibold'>Clientes</Form.Label>
                                    <Select
                                        className='shadowCustom'
                                        id="input-group-edit"
                                        value={clientToAssociate}
                                        onChange={(e) => handleChangeCliente(e)}
                                        options={clients}
                                    />
                                </Form.Group>
                            </Form>
                            <Form.Group className="mb-3">
                                <ListGroup as="ul" className='shadowCustom'>
                                    <ListGroup.Item as="li" className='fw-semibold' style={{ backgroundColor: '#1d2023', color: 'white' }}>
                                        <MdLibraryAdd className='fs-4 m-1' />Adicionar Conteúdo
                                    </ListGroup.Item>
                                    {conteudos.map((conteudo, index) => (
                                        <ListGroup.Item
                                            className='fw-medium listGroupItemGrade'
                                            as="li"
                                            key={conteudo.value}
                                            onClick={() => handleAddConteudo(conteudo)}
                                        >
                                            {conteudo.label}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Form.Group>

                        </Col>
                        <Col>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId='droppable-1' type='CONTEUDO'>
                                    {(provided, snapshot) =>
                                        <div ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <Table className='shadowCustom' striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th className='text-center fw-semibold'>Conteúdo</th>
                                                        <th className='text-center fw-semibold'>Excluir</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectConteudos.map((conteudo, index) => (
                                                        <Draggable key={conteudo.uniqueId} draggableId={`draggable-${conteudo.uniqueId}`} index={index}>
                                                            {(provided, snapshot) => (
                                                                <tr
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <td className='text-center' style={{ width: '100%' }}>{conteudo.label}</td>
                                                                    <td className='text-center' style={{ width: '100%' }}><Button className='deleteButton' onClick={() => handleDeleteConteudo(conteudo.uniqueId)}><FaTrash /></Button></td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </tbody>
                                            </Table>
                                        </div>
                                    }
                                </Droppable>
                            </DragDropContext>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs lg="2">
                            <Button className='btnCreateMobile' onClick={createGrade} id='buttonNew' type='submit'>
                                Criar
                            </Button>
                        </Col>
                    </Row>                        
                </div>
            </div>
        </>
    )
}

export default Create