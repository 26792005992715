import React, { useEffect, useState, useMemo } from 'react'
import api from '../../services/api'
import Table from 'react-bootstrap/Table'
import NavBar from '../../components/Navbar/navbar'
import Cookies from 'js-cookie'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import { FaFileAlt } from "react-icons/fa"
import { Tab, Tabs, Modal } from 'react-bootstrap'
import MachineDetails1 from '../../components/MachineDetails1'
import MachineDetails2 from '../../components/MachineDetails2'
import MachineDetails3 from '../../components/MachineDetails3'
import MachineDetails4 from '../../components/MachineDetails4'
import Loading from '../../components/LoaderComponent/loader'

import './styleDashboard.css'
import './ChartjsDashComponents/styleCardsCharts.css'
import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'

const Dashboard = () => {

    const [maquinas, setMaquinas] = useState([])
    const [search, setSearch] = useState('')
    const [machineDetails, setMachineDetails] = useState('')
    const [showDetails, setShowDetails] = useState(false)
    const [chaveAba, setChaveAba] = useState('aba1')
    const [status, setStatus] = useState('todos')

    const handleCloseDetails = () => setShowDetails(false)
    const handleSelectDetailsContent = (chave) => setChaveAba(chave)

    const [currentPage, setCurrentPage] = useState(1)
    const [maquinasPerPage] = useState(10)
    const indexOfLastClient = currentPage * maquinasPerPage
    const indexOfFirstClient = indexOfLastClient - maquinasPerPage
    const currentMaquinas = maquinas.slice(indexOfFirstClient, indexOfLastClient)
    const nPages = Math.ceil(maquinas.length / maquinasPerPage)
    const [loading, setLoading] = useState(true); // Adicionando o estado de loading

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Definindo loading como true antes de iniciar o carregamento

                const response = await api.get(`/api/dashboard/maquinasRelatorio/todos`);

                setMaquinas(response.data.maquinas);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false); // Definindo loading como false após o término do carregamento
            }
        };

        fetchData();
    }, []);


    async function handleStatus(statusId) {
        setStatus(statusId)
        const response = await api.get(`/api/dashboard/maquinasRelatorio/${statusId}`)

        setMaquinas(response.data.maquinas)
    }


    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        );
    };


    async function handleOpenMachineDetails(idMaquina) {
        const response = await api.get(`/api/getMachineDetails/${idMaquina}`)

        setMachineDetails(response.data)
        setShowDetails(true)
    }

    const handleDownload = async () => {
        try {
            const response = await api.get(`/api/downloadMaquinas/${Cookies.get('ambienteId')}/${status}`, {
                responseType: 'blob',
            })

            const fileName = response.headers['x-filename'] || response.headers['X-Filename'] || 'default_filename.xlsx'

            const url = window.URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }))

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()

            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
        } catch (error) {
            console.error(error)
        }
    }

    const filteredMaquinas = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentMaquinas
        } else {
            return maquinas.filter((maquina) => maquina.id.toString().toLowerCase().includes(lowerSearch) || maquina.local.toLowerCase().includes(lowerSearch) || maquina.name.toLowerCase().includes(lowerSearch) || maquina.cnpj.toString().toLowerCase().includes(lowerSearch) || maquina.hash.toLowerCase().includes(lowerSearch) || maquina.cliente.toLowerCase().includes(lowerSearch))
        }
    }, [search, maquinas, currentMaquinas])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className='loading'><Loading /></div>
            ) : (
                <div id='divContainer' className='container'>
                    <div>
                        <Modal show={showDetails} onHide={handleCloseDetails} className='modal-lg'>
                            <Modal.Header closeButton>
                                <Modal.Title className='textColors'>Detalhes</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Tabs activeKey={chaveAba} onSelect={handleSelectDetailsContent}>
                                    <Tab eventKey="aba1" title="Geral">
                                        <MachineDetails1 data={machineDetails} />
                                    </Tab>
                                    <Tab eventKey="aba2" title="Screenshots">
                                        <MachineDetails3 data={machineDetails} />
                                    </Tab>
                                    <Tab eventKey="aba3" title="Últimas comunicações">
                                        <MachineDetails2 data={machineDetails} />
                                    </Tab>
                                    <Tab eventKey="aba4" title="Tickets">
                                        <MachineDetails4 data={machineDetails} />
                                    </Tab>
                                </Tabs>
                            </Modal.Body>
                        </Modal>
                    </div>

                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'><FaFileAlt className='fs-1' /><h3 id='tittleH2'> Relatório</h3></div>
                        </div>

                            <div id='div-input-group' className='div-input-group divAllRelatorioMobile'>
                            <div className='d-flex gap-3 h-50 divRelatorioDashMobile'>
                                <InputGroup id='input-group' className='input-group w-75'>
                                    <select className='w-100 rounded-2' onChange={(e) => handleStatus(e.target.value)}>
                                        <option value='todos'>Todas</option>
                                        <option value='online'>Online</option>
                                        <option value='offline'>Offline</option>
                                        <option value='alerta'>Alerta</option>
                                    </select>
                                </InputGroup>

                                <InputGroup id='input-group' className='input-group'>
                                    <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                                    <Form.Control
                                        placeholder="Pesquisar"
                                        aria-label="pesquisar"
                                        aria-describedby="search"
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </InputGroup>
                            </div>
                            <div className='d-flex divBtnRelatorioMobile'>
                                <Button className='downloadDashButton' onClick={handleDownload}>
                                    <HiOutlineDocumentDownload className='fs-4' />
                                    <span className='downloadDashButtonMobile'>Download</span>
                                </Button>
                                <NavLink className='btnVoltarMobile' to='/dashboard' style={{ textDecoration: 'none', marginLeft: '10px' }}>
                                    <Button id='buttonBack'>
                                        <BsArrowReturnLeft style={{ marginRight: '3px' }} />
                                        <span>Voltar</span>
                                    </Button>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr id="trList">
                                    <th className='text-center'>#ID</th>
                                    <th className='text-center'>Cliente</th>
                                    <th className='text-center'>Local</th>
                                    <th className='text-center'>Nome</th>
                                    <th className='text-center'>CNPJ</th>
                                    <th className='text-center'>Hash</th>
                                    <th className='text-center'>Última comunicação</th>
                                    <th className='text-center'>Último IP</th>
                                    <th className='text-center'>Patrimônio</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredMaquinas.map((maquina, index) => (
                                    <tr key={maquina.id}>
                                        <td onClick={() => handleOpenMachineDetails(maquina.id)} style={{ cursor: 'pointer' }} className='text-center'>{maquina.id}</td>
                                        <td className='text-center'>{maquina.cliente}</td>
                                        <td className='text-center'>{maquina.local}</td>
                                        <td className='text-center'>{maquina.name}</td>
                                        <td className='text-center'>{maquina.cnpj}</td>
                                        <td className='text-center'>{maquina.hash}</td>
                                        <td className='text-center'>{maquina.ultimo_status}</td>
                                        <td className='text-center'>{maquina.ultimo_ip}</td>
                                        <td className='text-center'>{maquina.patrimonio}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            )}
        </>
    )
}

export default Dashboard