import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'

import AnimationEditoriasLottie from './AnimationEditoriasLottie/AnimationEditoriasLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [editorias, setEditorias] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [editoriaIdToDelete, setEditoriaIdToDelete] = useState('')
    const [editoriaNameToDelete, setEditoriaNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [editoriasPerPage] = useState(10)
    const indexOfLastEditoria = currentPage * editoriasPerPage
    const indexOfFirstEditoria = indexOfLastEditoria - editoriasPerPage
    const currentEditorias = editorias.slice(indexOfFirstEditoria, indexOfLastEditoria)
    const nPages = Math.ceil(editorias.length / editoriasPerPage)
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([])

    const getEditorias = async () => {

        setLoading(true);

        try {
            const [permissionsResponse, userPermissionsResponse, editoriasResponse] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/noticiaEditoria/index')
            ]);

            setPermissions(permissionsResponse.data.permissions);
            setUserPermissions(userPermissionsResponse.data.permissions);
            setEditorias(editoriasResponse.data.editorias);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get('/api/noticiaEditoria/index')
        setEditorias(response.data.editorias)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)
        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteEditoriaConfirmationModal = (editoriaId, editoriaName) => {
        setModalShow(true)
        setEditoriaIdToDelete(editoriaId)
        setEditoriaNameToDelete(editoriaName)
    }

    const deleteEditoria = async (editoriaId) => {
        await api.delete(`/api/noticiaEditoria/${editoriaId}`).then(() => {
            getEditorias()
            setModalShow(false)
            toast.success('Editoria deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteEditoriaConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header id="modalHeader" closeButton>
                    <div id="divModalTitle">
                        <Modal.Title id="modalTitle">
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id="modalBody">
                    <h4>Exclusão de editoria</h4>
                    <p>
                        Tem certeza que deseja excluir a editoria {props.editorianame}?
                    </p>
                </Modal.Body>

                <Modal.Footer id="modalFooter">
                    <Button id="yesButton" onClick={() => deleteEditoria(props.editoriaid)}>
                        Sim
                    </Button>
                    <Button id="noButton" onClick={props.onHide}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditEditoriaPage = (editoriaId) => {
        history(`/editoria/edit/${editoriaId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getEditorias()
    }, [])

    const filteredEditorias = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentEditorias
        } else {
            return editorias.filter(
                (editoria) =>
                    editoria.nome.toLowerCase().includes(lowerSearch) ||
                    editoria.nome_provedor.toLowerCase().includes(lowerSearch) ||
                    editoria.id.toString().toLowerCase().includes(lowerSearch) ||
                    editoria.url.toString().toLowerCase().includes(lowerSearch)
            )
        }
    }, [search, editorias, currentEditorias])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className="container" id='divContainer'>
                    <DeleteEditoriaConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        editoriaid={editoriaIdToDelete}
                        editorianame={editoriaNameToDelete}
                    />

                    <div className="headerContainer">
                        <div className='divheaderTittleMobile'>
                            <div id="divHeaderTittle">
                                <AnimationEditoriasLottie />
                                <h3 id="tittleH2">Editorias</h3>
                            </div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id="input-group" className='input-group'>
                                <InputGroup.Text id="search">
                                    <AiOutlineSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="pesquisar"
                                    aria-label="pesquisar"
                                    aria-describedby="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {userPermissions.some((permission) => permission.name === 'store_editoria') ? (
                                <NavLink className='btnVoltarMobile' to="/editoria/new" style={{ textDecoration: 'none' }}>
                                    <Button className='borderbtnCreateEdit' id="buttonNew">
                                        <AiOutlinePlusCircle id="iconButtonNew" className='iconButtonMobile' />
                                        <span className='novaPermissionMobile'>Nova Editoria</span>
                                    </Button>
                                </NavLink>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id="tableList" striped bordered hover>
                            <thead>
                                <tr>
                                    <th className="text-center">#ID</th>
                                    <th className="text-center">Provedor</th>
                                    <th className="text-center">Nome</th>
                                    <th className="text-center">URL</th>
                                    <th className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEditorias.map((editoria, index) => (
                                    <tr key={editoria.id} className={editoria.ativo ? '' : 'table-danger'}>
                                        <td className="text-center">{editoria.id}</td>
                                        <td className="text-center">{editoria.nome_provedor}</td>
                                        <td className="text-center">{editoria.nome}</td>
                                        <td className="text-center w-50">{editoria.url}</td>
                                        <td className="text-center" id='tdButtonAction'>
                                            {userPermissions.some((permission) => permission.name === 'edit_editoria') ? (
                                                <Button className="editButton btnTableMoBile me-2" onClick={() => showEditEditoriaPage(editoria.id)}>
                                                    <BsPencilSquare /> Editar
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {userPermissions.some((permission) => permission.name === 'delete_editoria') ? (
                                                <Button
                                                    className="deleteButton btnTableMoBile"
                                                    onClick={() => showDeleteEditoriaConfirmationModal(editoria.id, editoria.nome)}
                                                >
                                                    <FaTrash /> Excluir
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            )}
        </>
    )
}

export default Index
