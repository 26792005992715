import Lottie from "lottie-react";
import animationAmbienteLottie from "./ambienteAnimation.json";

const style = {
    height: 80,
    margin: -5,
};

const AnimationAmbienteLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationAmbienteLottie} />;
};

export default AnimationAmbienteLottie;