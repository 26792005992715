import { Badge } from 'react-bootstrap'

const MachineDetails1 = ({ data }) => {

    return (
        <div className='container overflow-auto mt-2' style={{ height: '50vh' }}>
            <h2 className='textColors'>{ data.maquina.name }</h2>
            <p className='textColors'><b>Local:</b> { data.maquina.local }</p>
            <p className='textColors'><b>ID:</b> { data.maquina.id }</p>
            <p className='textColors'><b>Hash:</b> { data.maquina.hash }</p>
            <p className='textColors'><b>Patrimônio:</b> { data.maquina.patrimonio }</p>
            <p className='textColors'><b>Monitoramento:</b> { data.maquina.monitoramento ? <Badge className='bg-opacity-50 p-2 fs-6 fw-medium badgeCustomDataInicioFim'>Sim</Badge> : <Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>Não</Badge> }</p>
            <p className='textColors'><b>Bloqueio:</b> { data.maquina.bloqueio ? <Badge className='bg-opacity-50 p-2 fs-6 fw-medium badgeCustomDataInicioFim'>Sim</Badge> : <Badge className='bg-opacity-50 bg-danger p-2 fs-6 fw-medium badgeCustomDataInicioFim'>Não</Badge> }</p>
            <p className='textColors'><b>Quantidade de monitores:</b> { data.maquina.monitores }</p>
            <p className='textColors'><b>Grade:</b> { data.maquina.id_grade }</p>
            <p className='textColors'><b>Última comunicação:</b> { data.maquina.ultimo_status }</p>
            <p className='textColors'><b>Último IP:</b> { data.maquina.ultimo_ip }</p>
        </div>
    )
}
  
export default MachineDetails1