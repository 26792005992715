import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill, BsFillCloudFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'

import AnimationNoticiasLottie from './AnimationNoticiasLottie/AnimationNoticiasLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [noticias, setNoticias] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [ambientesPopOver, setAmbientesPopover] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [noticiaIdToDelete, setNoticiaIdToDelete] = useState('')
    const [noticiaNameToDelete, setNoticiaNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [noticiasPerPage] = useState(10)
    const indexOfLastNoticia = currentPage * noticiasPerPage
    const indexOfFirstNoticia = indexOfLastNoticia - noticiasPerPage
    const currentNoticias = noticias.slice(indexOfFirstNoticia, indexOfLastNoticia)
    const nPages = Math.ceil(noticias.length / noticiasPerPage)
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([])

    const getNoticias = async () => {

        setLoading(true);

        try {
            const [permissionsResponse, userPermissionsResponse, noticiasResponse] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/noticia/index')
            ]);

            setPermissions(permissionsResponse.data.permissions);
            setUserPermissions(userPermissionsResponse.data.permissions);
            setNoticias(noticiasResponse.data.noticias);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get('/api/noticia/index')
        setNoticias(response.data.noticias)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)
        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteNoticiaConfirmationModal = (noticiaId, noticiaName) => {
        setModalShow(true)
        setNoticiaIdToDelete(noticiaId)
        setNoticiaNameToDelete(noticiaName)
    }

    const deleteNoticia = async (noticiaId) => {
        await api.delete(`/api/noticia/${noticiaId}`).then(() => {
            getNoticias()
            setModalShow(false)
            toast.success('Noticia deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteNoticiaConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header id="modalHeader" closeButton>
                    <div id="divModalTitle">
                        <Modal.Title id="modalTitle">
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id="modalBody">
                    <h4>Exclusão de Notícia</h4>
                    <p>
                        Tem certeza que deseja excluir a noticia {props.noticianame}?
                    </p>
                </Modal.Body>

                <Modal.Footer id="modalFooter">
                    <Button id="yesButton" onClick={() => deleteNoticia(props.noticiaid)}>
                        Sim
                    </Button>
                    <Button id="noButton" onClick={props.onHide}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditNoticiaPage = (noticiaId) => {
        history(`/noticia/edit/${noticiaId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    const getAmbientesPopOver = async (idNoticia) => {
        await api.get(`/api/noticia/getAssociatedAmbientes/${idNoticia}`).then((res) => {
            setAmbientesPopover(res.data.ambientes)
        })
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Segmentação</Popover.Header>
            <Popover.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                {
                    ambientesPopOver.map(ambiente => (
                        <strong key={ambiente}>{ambiente}</strong>
                    ))
                }
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        getNoticias()
    }, [])

    const filteredNoticias = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentNoticias
        } else {
            return noticias.filter(
                (noticia) =>
                    noticia.texto.toLowerCase().includes(lowerSearch) ||
                    noticia.id.toString().toLowerCase().includes(lowerSearch) ||
                    noticia.imagem.toString().toLowerCase().includes(lowerSearch) ||
                    noticia.nome_editoria.toString().toLowerCase().includes(lowerSearch)
            )
        }
    }, [search, noticias, currentNoticias])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className="container" id='divContainer'>
                    <DeleteNoticiaConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        noticiaid={noticiaIdToDelete}
                        noticianame={noticiaNameToDelete}
                    />

                    <div className="headerContainer">
                        <div className='divheaderTittleMobile'>
                            <div id="divHeaderTittle">
                                <AnimationNoticiasLottie />
                                <h3 id="tittleH2">Noticias</h3>
                            </div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id="input-group" className='input-group'>
                                <InputGroup.Text id="search">
                                    <AiOutlineSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder="pesquisar"
                                    aria-label="pesquisar"
                                    aria-describedby="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {userPermissions.some((permission) => permission.name === 'store_noticia') ? (
                                <NavLink className='btnVoltarMobile' to="/noticia/new" style={{ textDecoration: 'none' }}>
                                    <Button className='borderbtnCreateEdit' id="buttonNew">
                                        <AiOutlinePlusCircle id="iconButtonNew" className='iconButtonMobile' />
                                        <span className='novaPermissionMobile'>Nova Notícia</span>
                                    </Button>
                                </NavLink>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id="tableList" striped bordered hover>
                            <thead>
                                <tr>
                                    <th className="text-center">#ID</th>
                                    <th className="text-center">Editoria</th>
                                    <th className="text-center">Texto</th>
                                    <th className="text-center">Imagem</th>
                                    <th className="text-center">Ambientes</th>
                                    <th className="text-center">Data Final</th>
                                    <th className="text-center">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredNoticias.map((noticia, index) => (
                                    <tr key={noticia.id}>
                                        <td className="text-center">{noticia.id}</td>
                                        <td className="text-center">{noticia.nome_editoria}</td>
                                        <td className="text-center" style={{ maxWidth: '220px' }}>{noticia.texto}</td>
                                        <td className="text-center">
                                            <img id='thumb' style={{ height: '70px' }} src={noticia.imagem} alt="Imagem" />
                                        </td>
                                        <td className="text-center">
                                            <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                                <Button className="editButton" onClick={() => getAmbientesPopOver(noticia.id)}>
                                                    <BsFillCloudFill /> Ambientes
                                                </Button>
                                            </OverlayTrigger>
                                        </td>
                                        <td className="text-center">{noticia.data_fim}</td>
                                        <td className="text-center">
                                            {userPermissions.some((permission) => permission.name === 'edit_noticia') ? (
                                                <Button className="editButton btnTableMoBile" onClick={() => showEditNoticiaPage(noticia.id)}>
                                                    <BsPencilSquare /> Editar
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {userPermissions.some((permission) => permission.name === 'delete_noticia') ? (
                                                <Button
                                                    className="deleteButton btnTableMoBile"
                                                    onClick={() => showDeleteNoticiaConfirmationModal(noticia.id, noticia.id)}
                                                >
                                                    <FaTrash /> Excluir
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                </div>
            )}
        </>
    )
}

export default Index
