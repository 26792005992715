import React from 'react';
import NavBar from '../../components/Navbar/navbar'
import DragAndDropArea from '../../components/DragAndDrop/edit'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { MdMovieEdit } from "react-icons/md";

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Create = () => {

return (
    <>
      <NavBar />
      <div id='divContainer' className='container'>
        <div className='headerContainer'>
          <div>
          <div id='divHeaderTittle'><MdMovieEdit className='fs-1'/><h3 id='tittleH2'> Edição de Template</h3></div>
          </div>

          <div>
            <div>
                <NavLink to='/template/padrao/index' style={{ textDecoration: 'none' }}>
                    <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                </NavLink>
            </div>
          </div>
        </div>
      </div>
      <DragAndDropArea />
    </>
  )
}

export default Create