import Lottie from "lottie-react";
import animationDashPubLottie from "./dashAnimationPub.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationDashPubLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationDashPubLottie} />;
};

export default AnimationDashPubLottie;