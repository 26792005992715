import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import api from '../../services/api'
import Cookies from 'js-cookie'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'

import AnimationConteudoLottie from './AnimationConteudoLottie/AnimationConteudoLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [conteudo, setConteudo] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [conteudoIdToDelete, setConteudoIdToDelete] = useState('')
    const [conteudoNameToDelete, setConteudoNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [conteudoPerPage] = useState(10)
    const indexOfLastConteudo = currentPage * conteudoPerPage
    const indexOfFirstConteudo = indexOfLastConteudo - conteudoPerPage
    const currentConteudo = conteudo.slice(indexOfFirstConteudo, indexOfLastConteudo)
    const nPages = Math.ceil(conteudo.length / conteudoPerPage)
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([])

    const getConteudo = async () => {

        setLoading(true);

        try {
            const [permissionsResponse, userPermissionsResponse, conteudoResponse] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get(`/api/conteudo/index`)
            ]);

            setPermissions(permissionsResponse.data.permissions);
            setUserPermissions(userPermissionsResponse.data.permissions);
            setConteudo(conteudoResponse.data.conteudo);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get(`/api/conteudo/index`)
        setConteudo(response.data.conteudo)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (conteudoId, conteudoName) => {
        setModalShow(true)
        setConteudoIdToDelete(conteudoId)
        setConteudoNameToDelete(conteudoName)
    }

    const deleteConteudo = async (conteudoId) => {
        await api.delete(`/api/conteudo/${conteudoId}`).then(() => {
            getConteudo()
            setModalShow(false)
            toast.success('Conteúdo deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteConteudoConfirmaitonModal(props) {

        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de conteúdo.</h4>
                    <p>
                        Você tem certeza de que deseja remover o conteúdo {props.localconteudo} ?
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteConteudo(props.conteudoid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditConteudoPage = (conteudoId) => {
        history(`/conteudo/edit/${conteudoId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getConteudo()
    }, [])

    const filteredConteudo = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentConteudo
        } else {
            return conteudo.filter((conteudo) => conteudo.name.toLowerCase().includes(lowerSearch) || conteudo.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, conteudo, currentConteudo])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                <div id='divContainer' className='container'>
                    <DeleteConteudoConfirmaitonModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        conteudoid={conteudoIdToDelete}
                        conteudoname={conteudoNameToDelete}
                    />

                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'><AnimationConteudoLottie /><h3 id='tittleH2'>Conteúdos</h3></div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                                <Form.Control
                                    placeholder="pesquisar"
                                    aria-label="pesquisar"
                                    aria-describedby="search"
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {
                                userPermissions.some((permission) => permission.name === 'store_conteudo') ? (
                                    <NavLink to='/conteudo/new' style={{ textDecoration: 'none' }}>
                                        <Button id='buttonNew'><AiOutlinePlusCircle id='iconButtonNew' /> Novo Conteudo</Button>
                                    </NavLink>
                                ) : ''
                            }
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>#ID</th>
                                    <th className='text-center'>Cliente</th>
                                    <th className='text-center'>Nome</th>
                                    <th className='text-center'>Tipo de Conteúdo</th>
                                    <th className='text-center'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredConteudo.map((conteudo, index) => (
                                    <tr key={conteudo.id} className={conteudo.ativo ? '' : 'table-danger'}>
                                        <td className='text-center'>{conteudo.id}</td>
                                        <td className='text-center'>{conteudo.nome_cliente ?? 'Todos'}</td>
                                        <td className='text-center'>{conteudo.name}</td>
                                        <td className='text-center'>{conteudo.tipo_conteudo}</td>
                                        <td className='text-center' id='tdButtonAction'>
                                            {
                                                userPermissions.some((permission) => permission.name === 'edit_conteudo') ? (
                                                    <Button className='editButton btnTableMoBile me-2' onClick={() => showEditConteudoPage(conteudo.id)}><BsPencilSquare /> Editar</Button>
                                                ) : ''
                                            }

                                            {
                                                userPermissions.some((permission) => permission.name === 'delete_conteudo') ? (
                                                    <Button className='deleteButton btnTableMoBile' onClick={() => showDeleteConfirmationModal(conteudo.id, conteudo.name)}><FaTrash /> Excluir</Button>
                                                ) : ''
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            )}
        </>
    )
}

export default Index