import Lottie from "lottie-react";
import animationPubLottie from "./pubAnimation.json";

const style = {
    height: 70,
    width: 90,
};

const AnimationPubLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationPubLottie} />;
};

export default AnimationPubLottie;