import Lottie from "lottie-react";
import animationWarningApproveNewsLottie from "./animationWarningApproveNewsLottie.json";

const style = {
    width: 100,
    height: 100,
    top: -25,
};

const AnimationWarningApproveNewsConfimLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationWarningApproveNewsLottie} />;
};

export default AnimationWarningApproveNewsConfimLottie;