import { Container } from 'react-bootstrap'

const MachineDetails4 = ({ data }) => {

    return (
        <div className='container overflow-auto mt-2' style={{ height: '50vh' }}>
            { data.tickets.map(ticket => (
                <Container key={`ticket_${ticket.id}`}>
                    <p className='textColors'><b>ID Ticket:</b> { ticket.id }</p>
                    <p className='textColors'><b>Criado em:</b> { ticket.created_at }</p>
                    <p className='textColors'><b>Encerrado em:</b> { ticket.close_date }</p>
                    <p className='textColors'><b>Atendente:</b> { ticket.nome_atendente }</p>
                    <p className='textColors'><b>Categoria:</b> { ticket.nome_categoria }</p>
                    <p className='textColors'><b>Descrições:</b></p>
                    { ticket.descs.map((desc, index) => (
                        <pre key={`desc_${index}`}><p className='textColors'><b>. </b>{ desc.descricao }</p></pre>
                    )) }
                    <p className='textColors'><b>Observações:</b></p>
                    { ticket.obs.map((obs, index) => (
                        <pre key={`obs_${index}`}><p className='textColors'><b>. </b>{ obs.observacao }</p></pre>
                    )) }
                    <div style={{ border: '1px solid #ccc', width: '100%', height: '1px' }}></div>
                    <br/>
                </Container>
            )) }
        </div>
    )
}
  
export default MachineDetails4