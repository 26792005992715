import Lottie from "lottie-react";
import animationMaquinasLottie from "./maquinasAnimation.json";

const style = {
    height: 70,
    width: 50,
};

const AnimationMaquinasLottie = () => {
    return <Lottie loop={true} style={style} animationData={animationMaquinasLottie} />;
};

export default AnimationMaquinasLottie;