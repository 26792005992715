import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { BsPencilSquare, BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch, AiOutlinePlusCircle } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import Loading from '../../components/LoaderComponent/loader'

import AnimationProvedoresLottie from './AnimationProvedoresLottie/AnimationProvedoresLottie'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'
import '../../components/MobileModeStyle/editCreateMobileStyle.css'

const Index = () => {
    const history = useNavigate()
    const [provedores, setProvedores] = useState([])
    const [userPermissions, setUserPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [provedorIdToDelete, setProvedorIdToDelete] = useState('')
    const [provedorNameToDelete, setProvedorNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [provedoresPerPage] = useState(10)
    const indexOfLastProvedor = currentPage * provedoresPerPage
    const indexOfFirstProvedor = indexOfLastProvedor - provedoresPerPage
    const currentProvedores = provedores.slice(indexOfFirstProvedor, indexOfLastProvedor)
    const nPages = Math.ceil(provedores.length / provedoresPerPage)
    const [loading, setLoading] = useState(true)
    const [permissions, setPermissions] = useState([])

    const getProvedores = async () => {

        setLoading(true)

        try {
            const [permissionsResponse, userPermissionsResponse, provedoresResponse] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/noticiaProvedor/index')
            ])

            setPermissions(permissionsResponse.data.permissions)
            setUserPermissions(userPermissionsResponse.data.permissions)
            setProvedores(provedoresResponse.data.provedores);

        } catch (error) {
            console.error('Erro')
        } finally {
            setLoading(false)
        }

        const response = await api.get('/api/noticiaProvedor/index')
        setProvedores(response.data.provedores)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)
        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteProvedorConfirmationModal = (provedorId, provedorName) => {
        setModalShow(true)
        setProvedorIdToDelete(provedorId)
        setProvedorNameToDelete(provedorName)
    }

    const deleteProvedor = async (provedorId) => {
        await api.delete(`/api/noticiaProvedor/${provedorId}`).then(() => {
            getProvedores()
            setModalShow(false)
            toast.success('Provedor deletado com sucesso!', { autoClose: 3000 })
        })
    }

    function DeleteProvedorConfirmationModal(props) {
        return (
            <Modal
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                centered>
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie />
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Provedor</h4>
                    <p>
                        Tem certeza que deseja excluir o provedor {props.provedorname}?
                    </p>
                </Modal.Body>

                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deleteProvedor(props.provedorid)}>
                        Sim
                    </Button>
                    <Button id='noButton' onClick={props.onHide}>
                        Não
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const showEditProvedorPage = (provedorId) => {
        history(`/provedor/edit/${provedorId}`)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getProvedores()
    }, [])

    const filteredProvedores = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentProvedores
        } else {
            return provedores.filter(
                (provedor) =>
                    provedor.nome.toLowerCase().includes(lowerSearch) ||
                    provedor.id.toString().toLowerCase().includes(lowerSearch) ||
                    provedor.logo.toString().toLowerCase().includes(lowerSearch)
            )
        }
    }, [search, provedores, currentProvedores])

    return (
        <>
            <NavBar />
            {loading ? (
                <div className='loading'><Loading /></div>
            ) : (
                <div style={{ marginTop: '3rem', width: '100vw' }} className='container' id='divContainer'>
                    <DeleteProvedorConfirmationModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        provedorid={provedorIdToDelete}
                        provedorname={provedorNameToDelete}
                    />

                    <div className='headerContainer'>
                        <div className='divheaderTittleMobile'>
                            <div id='divHeaderTittle'>
                                <AnimationProvedoresLottie />
                                <h3 id='tittleH2'>Provedores</h3>
                            </div>
                        </div>

                        <div id='div-input-group' className='div-input-group'>
                            <InputGroup id='input-group' className='input-group'>
                                <InputGroup.Text id='search'>
                                    <AiOutlineSearch />
                                </InputGroup.Text>
                                <Form.Control
                                    placeholder='pesquisar'
                                    aria-label='pesquisar'
                                    aria-describedby='search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </InputGroup>

                            {userPermissions.some((permission) => permission.name === 'store_provedor') ? (
                                <NavLink className='btnVoltarMobile' to='/provedor/new' style={{ textDecoration: 'none' }}>
                                    <Button className='borderbtnCreateEdit' id='buttonNew'>
                                        <AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' />
                                        <span className='novaPermissionMobile'>Novo Provedor</span>
                                    </Button>
                                </NavLink>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <ToastContainer />

                    <div className='container overflow-auto mt-2'>
                        <Table id='tableList' striped bordered hover>
                            <thead>
                                <tr>
                                    <th className='text-center'>#ID</th>
                                    <th className='text-center'>Nome</th>
                                    <th className='text-center'>Logo</th>
                                    <th className='text-center'>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProvedores.map((provedor, index) => (
                                    <tr key={provedor.id} className={provedor.ativo ? '' : 'table-danger'}>
                                        <td className='text-center'>{provedor.id}</td>
                                        <td className='text-center'>{provedor.nome}</td>
                                        <td className='text-center'>
                                            <img id='thumb' style={{ height: '50px' }} src={provedor.logo} alt='Imagem Logo' />
                                        </td>
                                        <td className='text-center'>
                                            {userPermissions.some((permission) => permission.name === 'edit_provedor') ? (
                                                <Button className='editButton btnTableMoBile me-2' onClick={() => showEditProvedorPage(provedor.id)}>
                                                    <BsPencilSquare /> Editar
                                                </Button>
                                            ) : (
                                                ''
                                            )}

                                            {userPermissions.some((permission) => permission.name === 'delete_provedor') ? (
                                                <Button
                                                    className='deleteButton btnTableMoBile'
                                                    onClick={() => showDeleteProvedorConfirmationModal(provedor.id, provedor.nome)}
                                                >
                                                    <FaTrash /> Excluir
                                                </Button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                    </div>
                </div>
            )}
        </>
    )
}

export default Index
