import React, { useState, useEffect } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { BsArrowReturnLeft } from 'react-icons/bs'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import InputGroup from 'react-bootstrap/InputGroup'
import { IoHourglass } from 'react-icons/io5'
import { Spinner } from 'react-bootstrap'
import { LuComputer } from 'react-icons/lu'
import { FaRegTrashCan } from 'react-icons/fa6'
import { FaListCheck } from 'react-icons/fa6'
import { ButtonGroup } from 'reactstrap'
import { MdVideoSettings } from 'react-icons/md'
import { IoLocationOutline } from "react-icons/io5";
import { TbTemplate } from "react-icons/tb";
import { SiMaterialdesignicons } from "react-icons/si";
import io from 'socket.io-client'

import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'

const Create = () => {
    const history = useNavigate()
    const customId = 'success-toast-id'
    const [localToAssociate, setLocalToAssociate] = useState([])
    const [locais, setLocais] = useState([])
    const [maquinasToAssociate, setMaquinasToAssociate] = useState([])
    const [maquinas, setMaquinas] = useState([])
    const [templateToAssociate, setTemplateToAssociate] = useState('')
    const [templates, setTemplates] = useState([])
    const [materialToAssociate, setMaterialToAssociate] = useState('')
    const [materiais, setMateriais] = useState([])
    const [produtos, setProdutos] = useState([])
    const [dataInicio, setDataInicio] = useState('')
    const [horaInicio, setHoraInicio] = useState('')
    const [dataFim, setDataFim] = useState('')
    const [horaFim, setHoraFim] = useState('')
    const [doSpaces, setDoSpaces] = useState('')
    const [templateData, setTemplateData] = useState({})
    const [queryString, setQueryString] = useState('')
    const [iframeUrl, setIframeUrl] = useState('')
    const [templateInfos, setTemplateInfos] = useState([])

    const [botaoHabilitado, setBotaoHabilitado] = useState(true)
    const [exibindoSpinner, setExibindoSpinner] = useState(false)

    const createPub = async (e) => {

        if (!botaoHabilitado || exibindoSpinner) {
            return
        }

        // Inicia a exibição do Spinner
        setExibindoSpinner(true)

        // Lógica de salvamento aqui (simulada com um setTimeout)
        setTimeout(() => {
            // Desabilita o botão após o salvamento
            setBotaoHabilitado(false)
            // Para de exibir o Spinner
            setExibindoSpinner(false)
            // Habilita o botão novamente após 5 segundos        
            setBotaoHabilitado(true)
        }, 4000) // Tempo de espera simulado de 2 segundos (substitua pela lógica real)

        e.preventDefault()

        if (materialToAssociate === '' && templateToAssociate.length === '') {
            toast.error('Por favor selecione um material.', { autoClose: 3000 })
        } else if (maquinasToAssociate.length === 0 && localToAssociate.length === 0) {
            toast.error('Por favor selecione uma segmentação.', { autoClose: 3000 })
        } else if (dataInicio === '' || horaInicio === '') {
            toast.error('Por favor selecione data e hora de início da publicação.', { autoClose: 3000 })
        } else {
            await api.post('/api/publicacaoLojista/store', {
                templateData,
                templateToAssociate: templateToAssociate.value?.value,
                queryString,
                maquinasToAssociate,
                localToAssociate: localToAssociate.value,
                materialToAssociate: materialToAssociate.value?.value,
                dataInicio,
                horaInicio,
                dataFim,
                horaFim,
                templateInfos,
            }).then(() => {
                setTemplateData('')
                setQueryString('')
                setTemplateToAssociate('')
                setMaterialToAssociate('')
                setMaquinasToAssociate([])
                setLocalToAssociate([])
                setDataInicio('')
                setHoraInicio('')
                setDataFim('')
                setHoraFim('')
                setTemplateInfos([])
                toast.success('Publicação criada com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/publicacaoLojista') })
            })
        }
    }

    useEffect(() => {
        io(process.env.REACT_APP_UPDATE_PLAYLIST_SOCKET, { transports: ['websocket'] })
        let cancel = false

        async function fetchData() {
            api.get(`api/materiais/getMateriaisLojista`).then(res => {
                if (cancel) return

                let materiaisData = []

                setDoSpaces(res.data.spaces)

                res.data.materiais.forEach(material => {
                    materiaisData.push({
                        value: {
                            type: material.type,
                            ...material,
                            arquivo: material.arquivo,
                        },
                        label: material.label
                    })
                })

                setMateriais(materiaisData)

                let templatesData = []

                res.data.templates.forEach(template => {
                    templatesData.push({
                        value: {
                            ...template,
                            arquivo: template.arquivo,
                        },
                        label: template.label
                    })
                })

                setTemplates(templatesData)
            })

            api.get(`api/locais/getLocais`).then(res => {
                if (cancel) return

                let locaisData = []

                res.data.locais.forEach(local => {
                    locaisData.push({
                        value: local.value,
                        label: local.label
                    })
                })

                setLocais(locaisData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    useEffect(() => {
        let cancel = false

        async function fetchData() {
            api.get(`api/produtos/getProdutosLojista`).then(res => {
                if (cancel) return

                let produtosData = []

                res.data.produtos.forEach(produto => {
                    produtosData.push({
                        value: {
                            type: produto.type,
                            ...produto,
                            arquivo: produto.arquivo,
                        },
                        label: produto.label
                    })
                })

                setProdutos(produtosData)
            })
        }

        fetchData()

        return () => {
            cancel = true
        }
    }, [])

    const handleDadoInput = (e, dadoDado, id_tipo) => {
        setTemplateInfos((prevData) => {
            const updateData = { ...prevData, [dadoDado]: [e.target.value, dadoDado, id_tipo] }
            return updateData
        })

        setTemplateData((prevData) => {
            const updatedData = {
                ...prevData,
                [dadoDado]: e.target.value,
            }

            const updatedQueryString = Object.entries(updatedData)
                .filter(([key, value]) => value !== undefined && value !== '')
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&')

            setQueryString(updatedQueryString)

            // Combine a URL do iframe
            const iframeUrl = `${templateToAssociate.value.arquivo}${updatedQueryString}`
            setIframeUrl(iframeUrl)

            return updatedData
        })
    }

    const handleDataInicioChange = (e) => {
        setDataInicio(e.target.value)
    }

    const handleHoraInicioChange = (e) => {
        setHoraInicio(e.target.value)
    }

    const handleDataFimChange = (e) => {
        setDataFim(e.target.value)
    }

    const handleHoraFimChange = (e) => {
        setHoraFim(e.target.value)
    }

    const handleChangeLocal = async (e) => {
        setLocalToAssociate(e)

        await api.post(`api/maquina/getMachinesByPlaceId`, {
            locais: {
                value: e
            }
        }).then((res) => {
            setMaquinas(res.data.maquinas)
        })
    }

    const handleSelectAllMachines = (e) => {
        e.preventDefault()

        setMaquinasToAssociate(maquinas)
    }

    const handleRemoveAllMachines = (e) => {
        e.preventDefault()

        setMaquinasToAssociate([])
    }

    const handleChangeTemplate = (e) => {
        setMaterialToAssociate('')
        setTemplateToAssociate(e)
    }

    const handleChangeMaterial = (e) => {
        setTemplateToAssociate('')
        setMaterialToAssociate(e)
    }

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer editcreateMobile'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><MdVideoSettings className='fs-1' /><h3 id='tittleH2'> Nova Publicação</h3></div>
                    </div>

                    <div className='divbtnVoltarMobile'>
                        <div>
                            <NavLink to='/publicacaoLojista' style={{ textDecoration: 'none' }}>
                                <Button id='buttonBack'><BsArrowReturnLeft style={{ marginRight: '3px' }} /> Voltar</Button>
                            </NavLink>
                        </div>
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Row>
                        <Col xs lg={6} className='colGapClass'>
                            <Form>
                                <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                    <label htmlFor='locais' className='fw-semibold borderDown p-2 bg-dark text-white'>
                                        <span><IoLocationOutline className='fs-5' /> Local</span>
                                    </label>
                                    <Select
                                        placeholder='Selecione o local'
                                        className='p-2'
                                        id='locais'
                                        value={localToAssociate}
                                        onChange={(e) => handleChangeLocal(e)}
                                        options={locais}
                                    />
                                </Form.Group>
                            </Form>

                            <>
                                <Form>
                                    <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                        <label htmlFor='templates' className='textColors fw-semibold borderDown p-2 bg-dark text-white'>
                                            <span><TbTemplate className='fs-5'/> Template</span>
                                        </label>
                                        <Select
                                            placeholder='Selecione o template'
                                            className='p-2'
                                            id='templates'
                                            value={templateToAssociate}
                                            onChange={(e) => handleChangeTemplate(e)}
                                            options={templates}
                                        />
                                    </Form.Group>
                                </Form>

                                {templateToAssociate && templateToAssociate.label !== '' && (
                                    templateToAssociate.value && (
                                        <div>
                                            {templateToAssociate.value.dadosTemplate.map((dado) => (
                                                <div className='mb-3' key={`template-${dado.id}`}>
                                                    <label
                                                        className='control-label mb-1 textColors'
                                                        htmlFor={dado.dado}
                                                    >
                                                        {dado.nome}
                                                    </label>
                                                    {dado.id_tipo_dado === 1 && (
                                                        <select
                                                            name={dado.dado}
                                                            className='text-center form-control valid'
                                                            id={dado.dado}
                                                            value={templateData[dado.dado] || ''}
                                                            onChange={(e) => handleDadoInput(e, dado.dado, dado.id_tipo_dado)}
                                                            required
                                                        >
                                                            <option key='selecione' value=''>
                                                                Selecione...
                                                            </option>

                                                            {materiais.map((material, index) => (
                                                                <option key={index} value={`${doSpaces}${material.value.caminho}/${material.value.arquivo}`}>
                                                                    {material.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {dado.id_tipo_dado === 2 && (
                                                        <select
                                                            name={dado.dado}
                                                            className='text-center form-control valid'
                                                            id={dado.dado}
                                                            value={templateData[dado.dado] || ''}
                                                            onChange={(e) => handleDadoInput(e, dado.dado, dado.id_tipo_dado)}
                                                            required
                                                        >
                                                            <option key='selecione' value=''>
                                                                Selecione o produto...
                                                            </option>

                                                            {produtos.map((produto, index) => (
                                                                <option key={index} value={`${doSpaces}${produto.value.caminho}/${produto.value.arquivo}`}>
                                                                    {produto.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    )}
                                                    {dado.id_tipo_dado === 3 && (
                                                        <input
                                                            type='text'
                                                            name={dado.dado}
                                                            className='text-center form-control valid'
                                                            id={dado.dado}
                                                            value={templateData[dado.dado] || ''}
                                                            onChange={(e) => handleDadoInput(e, dado.dado, dado.id_tipo_dado)}
                                                            required
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                            <iframe
                                                title='templatePreview'
                                                style={{
                                                    width: '100%',
                                                    height: '200px',
                                                    marginBottom: '5px',
                                                    border: 'none',
                                                }}
                                                src={iframeUrl !== '' ? iframeUrl : templateToAssociate.value.arquivo}
                                            />
                                        </div>
                                    )
                                )}
                            </>

                            <>
                                <Form>
                                    <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                        <label htmlFor='materiais' className='textColors fw-semibold borderDown p-2 bg-dark text-white'>
                                            <span><SiMaterialdesignicons className='fs-5'/> Material</span>
                                        </label>
                                        <Select
                                            placeholder='Selecione o material'
                                            className='p-2'
                                            id='materiais'
                                            value={materialToAssociate}
                                            onChange={(e) => handleChangeMaterial(e)}
                                            options={materiais}
                                        />
                                    </Form.Group>
                                </Form>

                                {materialToAssociate && materialToAssociate.label !== '' && (
                                    materialToAssociate.value && (
                                        <div>
                                            {materialToAssociate.value.type === 'video' ? (
                                                <video style={{ height: '365px' }} controls>
                                                    <source src={doSpaces + materialToAssociate.value.caminho + "/" + materialToAssociate.value.arquivo} type='video/mp4' />
                                                    Seu navegador não suporta o elemento de vídeo.
                                                </video>
                                            ) : materialToAssociate.value.type === 'imagem' ? (
                                                <img style={{ height: '200px', marginBottom: '5px' }} src={doSpaces + materialToAssociate.value.caminho + "/" + materialToAssociate.value.arquivo} alt='Imagem' />
                                            ) : null}
                                        </div>
                                    )
                                )}
                            </>

                        </Col>
                        <Col xs lg={6} className='colGapClass marginTopMobile1rem'>
                            <Form.Group className='divMaquinasNovaPub shadowCustom'>
                                <div className='d-flex justify-content-between align-items-center borderDown p-1 bg-dark text-white'>
                                    <div className='badgeCustomDataInicioFim d-flex align-items-center'>
                                        <label className='fw-semibold text-center marginClassMaquina'><LuComputer className='fs-5' /> Maquinas</label>
                                    </div>
                                    <div>
                                        <ButtonGroup size='sm'>
                                            <Button className='fw-semibold d-flex align-items-center' onClick={(e) => handleSelectAllMachines(e)}>
                                                <FaListCheck className='FaListCheckClass' />
                                                <span>Selecionar tudo</span>
                                            </Button>
                                            <Button variant='danger' onClick={(e) => handleRemoveAllMachines(e)}><FaRegTrashCan className='fs-5' /></Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className='p-2'>
                                    <Select
                                        id='input-group-edit'
                                        options={maquinas}
                                        isMulti={true}
                                        placeholder='Selecione as maquinas'
                                        value={maquinasToAssociate}
                                        onChange={(e) => setMaquinasToAssociate(e)}
                                    />
                                </div>
                            </Form.Group>

                            <Table striped bordered className='shadowCustom'>
                                <thead className='bg-dark mb-2'>
                                    <tr>
                                        <th colSpan={2}>
                                            <h5 className='text-white text-center'>
                                                <IoHourglass className='mb-1' /> Periodo
                                            </h5>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {/* data e hora fim */}
                                            <Col>
                                                <h5 className='bg-success text-white p-1 mb-3 rounded text-center'>
                                                    Inicio
                                                </h5>
                                                <InputGroup>
                                                    {/* <label
                                                                className='control-label textColors'
                                                                htmlFor='data_inicio'
                                                            >                                                                                                                          
                                                                <span className='glyphicon glyphicon-calendar'></span>
                                                            </label> */}
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Data</InputGroup.Text>
                                                    <input
                                                        type='date'
                                                        name='data_inicio'
                                                        className='text-center form-control valid fw-semibold'
                                                        id='data_inicio'
                                                        aria-describedby='data_inicio'
                                                        aria-invalid='false'
                                                        value={dataInicio}
                                                        onChange={handleDataInicioChange}
                                                        required
                                                    />
                                                </InputGroup>
                                                <InputGroup className='mt-3'>
                                                    {/* <label className='control-label textColors' htmlFor='hora_inicio'>
                                                                Hora
                                                                <span className='glyphicon glyphicon-time'></span>
                                                            </label> */}
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Hora</InputGroup.Text>
                                                    <input
                                                        type='time'
                                                        name='hora_inicio'
                                                        step='1'
                                                        className='text-center form-control inteiro fw-semibold'
                                                        id='hora_inicio'
                                                        aria-describedby='hora_inicio'
                                                        value={horaInicio}
                                                        onChange={handleHoraInicioChange}
                                                        required
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </td>
                                        <td>
                                            {/* data e hora fim */}
                                            <Col>
                                                <h5 className='bg-danger text-white p-1 mb-3 rounded text-center'>
                                                    Final
                                                </h5>
                                                <InputGroup>
                                                    {/* <label
                                                        className='control-label textColors'
                                                        htmlFor='data_fim'
                                                    >
                                                        Data
                                                        <span className='glyphicon glyphicon-calendar'></span>
                                                    </label> */}
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Data</InputGroup.Text>
                                                    <input
                                                        type='date'
                                                        name='data_fim'
                                                        className='text-center form-control valid fw-semibold'
                                                        id='data_fim'
                                                        aria-describedby='data_fim'
                                                        aria-invalid='false'
                                                        value={dataFim}
                                                        onChange={handleDataFimChange}
                                                        required
                                                    />
                                                </InputGroup>
                                                {/* <label className='control-label textColors' htmlFor='hora_fim'>
                                                    Hora
                                                    <span className='glyphicon glyphicon-time'></span>
                                                </label> */}
                                                <InputGroup className='mt-3'>
                                                    <InputGroup.Text className='bg-dark text-white' id='basic-addon1'>Hora</InputGroup.Text>
                                                    <input
                                                        type='time'
                                                        name='hora_fim'
                                                        step='1'
                                                        className='text-center form-control inteiro fw-semibold'
                                                        id='hora_fim'
                                                        value={horaFim}
                                                        onChange={handleHoraFimChange}
                                                        aria-describedby='hora_fim'
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row className='mt-3 mb-5'>
                        <Col xs lg='2'>
                            <Button onClick={createPub} id='buttonNew' type='submit' disabled={!botaoHabilitado}>
                                {exibindoSpinner ? (
                                    <>
                                        <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' style={{ marginRight: '7px' }} />
                                        <span className='sr-only ml-3'>Salvando...</span>
                                    </>
                                ) : (
                                    'Salvar'
                                )}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default Create