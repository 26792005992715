import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import api from '../../services/api'
import Card from 'react-bootstrap/Card'
import './style.css'
import AnimationScreenshotsLottie from './AnimationScreenshotsLottie/AnimationScreeshotsLottie'
const Screenshots = () => {
    const [shots, setShots] = useState([])
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage] = useState(12)
    const indexOfLastUser = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastUser - itemsPerPage
    const currentItems = shots.slice(indexOfFirstItem, indexOfLastUser)
    const nPages = Math.ceil(shots.length / itemsPerPage)

    const getShots = async () => {
        const response = await api.get('/api/getScreenShotsByAmbienteId/todos')
        setShots(response.data.shots)
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
        if (nPages <= 1) return null;

        const range = (start, end) => {
            return Array(end - start + 1)
                .fill()
                .map((_, idx) => start + idx);
        };

        let pageNumbers = [];
        if (currentPage <= 3) {
            pageNumbers = [...range(1, Math.min(5, nPages))];
            if (nPages > 5) pageNumbers.push('...', nPages);
        } else if (currentPage > 3 && currentPage < nPages - 2) {
            pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
        } else {
            pageNumbers = [1, '...'];
            pageNumbers.push(...range(nPages - 4, nPages));
        }

        return (
            <nav className='d-flex justify-content-center align-items-center'>
                <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                    {pageNumbers.map((number, index) => (
                        <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                            <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
                <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
            </nav>
        );
    };


    useEffect(() => {
        getShots()
    }, [])

    async function handleStatus(statusId) {
        const response = await api.get(`/api/getScreenShotsByAmbienteId/${statusId}`)

        setShots(response.data.shots)
    }

    const filteredShots = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentItems
        } else {
            return shots.filter((shot) => shot.maquina.toLowerCase().includes(lowerSearch))
        }
    }, [search, shots, currentItems])

    return (
        <>
            <NavBar />

            <div id='divContainer' className='container'>
                <div className='headerContainer'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><AnimationScreenshotsLottie/><h3 id='tittleH2'> Screenshots</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group me-0'>
                        <InputGroup id='input-group' className='input-group me-3'>
                            <select className="rounded-2 w-100 border-1 border" onChange={(e) => handleStatus(e.target.value)}>
                                <option value='todos'>Todas</option>
                                <option value='online'>Online</option>
                                <option value='offline'>Offline</option>
                                <option value='alerta'>Alerta</option>
                            </select>
                        </InputGroup>

                        <InputGroup id='input-group' className='input-group'>
                            <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </div>

                <div className='container overflow-auto mt-2 grid-container mb-2 shotsContainer'>
                    {filteredShots.map(shot => (
                        <Card style={{ width: '18rem' }} key={`shot_${shot.id}`} className='grid-item'>
                            <a href={shot.caminho} target="_blank" rel="noreferrer" style={{ height: '60%' }}>
                                <Card.Img variant="top" src={shot.caminho} style={{ height: '100%' }} />
                            </a>
                            <Card.Body>
                                <Card.Title>{shot.maquina}</Card.Title>
                                <Card.Text>
                                    {shot.data}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </div>

                <Pagination
                    nPages={nPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </div>
        </>
    )
}

export default Screenshots