import React from 'react'

const Preview = ({ material }) => {

  const renderizarConteudo = () => {
    switch (material.tipo) {
      case 'zip':
        return (
          <iframe title="Zip Preview" src={material.url} width="100%" height="600px" />
        )
      case 'imagem':
        return (
          <img style={{ width: '100%', maxHeight: '85vh' }} src={material.url} alt="materialImage" />
        )
      case 'video':
        return (
          <video style={{ maxWidth: '90vh'}} controls>
            <source src={material.url} type="video/mp4" />
            Seu navegador não suporta o elemento de vídeo.
          </video>
        )
      default:
        return null
    }
  }

  return (
    <div className="modal-body texto_preview textColors WHfit-content">
      {renderizarConteudo()}
    </div>
  )
}

export default Preview
