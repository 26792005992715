import React, { useEffect, useState, useMemo } from 'react'
import NavBar from '../../components/Navbar/navbar'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { NavLink } from 'react-router-dom'
import { FaTrash } from 'react-icons/fa'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Modal from 'react-bootstrap/Modal'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import { AiOutlineSearch } from 'react-icons/ai'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import Cookies from 'js-cookie'
import 'react-toastify/dist/ReactToastify.css'
import AnimationPermissionLottie from './AnimationPermissionLottie/AnimationPermissionLottie'
import { BsFillArrowRightSquareFill, BsFillArrowLeftSquareFill } from 'react-icons/bs'
import AnimationWarningLottie from '../../components/AnimationWarningDeleteConfim/AnimationWarningLottie'
import Loading from '../../components/LoaderComponent/loader'

import '../../components/styleComponents/styleModalDeleteConfimation.css'
import '../../components/styleComponents/styleHeaderContainer.css'
import '../../components/styleComponents/styleTableContainer.css'
import '../../components/styleComponents/styleFooterContainer.css'
import '../../components/MobileModeStyle/headerContainerMobileStyle.css'

const Index = () => {
    const [permissions, setPermissions] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [permissionIdToDelete, setPermissionIdToDelete] = useState('')
    const [userPermissions, setUserPermissions] = useState([])
    const [permissionNameToDelete, setPermissionNameToDelete] = useState('')
    const [search, setSearch] = useState('')

    const [currentPage, setCurrentPage] = useState(1)
    const [permissionsPerPage] = useState(10)
    const indexOfLastPermission = currentPage * permissionsPerPage
    const indexOfFirstPermission = indexOfLastPermission - permissionsPerPage
    const currentPermissions = permissions.slice(indexOfFirstPermission, indexOfLastPermission)
    const nPages = Math.ceil(permissions.length / permissionsPerPage)
    const [loading, setLoading] = useState(true);

    const getPermissions = async () => {

        setLoading(true);

        try {
            const [permissionsResponse, userPermissionsResponse] = await Promise.all([
                api.get('/api/permission/index'),
                api.get(`api/user/${Cookies.get('userId')}`),
                api.get('/api/permission/index')
            ]);

            setPermissions(permissionsResponse.data.permissions);
            setUserPermissions(userPermissionsResponse.data.permissions);

        } catch (error) {
            console.error('Erro');
            toast.error('Erro ao carregar dados.', { autoClose: 3000 });
        } finally {
            setLoading(false);
        }

        const response = await api.get('/api/permission/index')
        setPermissions(response.data.permissions)

        const responsePermissions = await api.get(`api/user/${Cookies.get('userId')}`)

        setUserPermissions(responsePermissions.data.permissions)
    }

    const showDeleteConfirmationModal = (permissionId, permissionName) => {
        setModalShow(true)
        setPermissionIdToDelete(permissionId)
        setPermissionNameToDelete(permissionName)
    }

    const deletePermission = async (permissionId) => {
        await api.delete(`/api/permission/${permissionId}`).then(() => {
            getPermissions()
            setModalShow(false)
            toast.success('Permissão deletada com sucesso!', { autoClose: 3000 })
        })
    }

    function DeletePermissionConfirmationModal(props) {
    
        return (
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header id='modalHeader' closeButton>
                    <div id='divModalTitle'>
                        <Modal.Title id='modalTitle'>
                            <AnimationWarningLottie/>
                        </Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body id='modalBody'>
                    <h4>Exclusão de Permissão.</h4>
                    <p>
                        Você tem certeza de que deseja remover esta permissão <span className='d-flex justify-content-center mb-0'>{props.permissionname} ?</span>
                    </p>
                </Modal.Body>
                <Modal.Footer id='modalFooter'>
                    <Button id='yesButton' onClick={() => deletePermission(props.permissionid)}>Sim</Button>
                    <Button id='noButton' onClick={props.onHide}>Não</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
    if (nPages <= 1) return null;

    const range = (start, end) => {
        return Array(end - start + 1)
            .fill()
            .map((_, idx) => start + idx);
    };

    let pageNumbers = [];
    if (currentPage <= 3) {
        pageNumbers = [...range(1, Math.min(5, nPages))];
        if (nPages > 5) pageNumbers.push('...', nPages);
    } else if (currentPage > 3 && currentPage < nPages - 2) {
        pageNumbers = [1, '...', ...range(currentPage - 1, currentPage + 1), '...', nPages];
    } else {
        pageNumbers = [1, '...'];
        pageNumbers.push(...range(nPages - 4, nPages));
    }

    return (
        <nav className='d-flex justify-content-center align-items-center'>
            <BsFillArrowLeftSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
            <ul className='pagination pagination-sm' style={{ marginLeft: '1rem', marginRight: '1rem', marginTop: 'revert' }}>
                {pageNumbers.map((number, index) => (
                    <li key={index} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                        <button onClick={() => typeof number === 'number' && setCurrentPage(number)} className='page-link'>
                            {number}
                        </button>
                    </li>
                ))}
            </ul>
            <BsFillArrowRightSquareFill className='BsFillArrow' onClick={() => setCurrentPage(prev => Math.min(prev + 1, nPages))} />
        </nav>
    );
};


    useEffect(() => {
        getPermissions()
    }, [])

    const filteredPermissions = useMemo(() => {
        const lowerSearch = search.toLowerCase()

        if (search === '') {
            return currentPermissions
        } else {
            return permissions.filter((permission) => permission.name.toLowerCase().includes(lowerSearch) || permission.id.toString().toLowerCase().includes(lowerSearch))
        }
    }, [search, permissions, currentPermissions])

    return (
        <>
            <NavBar />

            {loading ? (
                <div className="loading"><Loading /></div>
            ) : (
                
            <div id='divContainer' className='container'>
                <DeletePermissionConfirmationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    permissionid={permissionIdToDelete}
                    permissionname={permissionNameToDelete}
                />

                <div className='headerContainer'>
                    <div className='divheaderTittleMobile'>
                        <div id='divHeaderTittle'><AnimationPermissionLottie /><h3 id='tittleH2'>Permissões</h3></div>
                    </div>

                    <div id='div-input-group' className='div-input-group'>
                        <InputGroup id='input-group' className='input-group'>
                            <InputGroup.Text id="search"><AiOutlineSearch /></InputGroup.Text>
                            <Form.Control
                                placeholder="pesquisar"
                                aria-label="pesquisar"
                                aria-describedby="search"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </InputGroup>

                        {
                            userPermissions.some((permission) => permission.name === 'store_permission') ? (
                                <NavLink className='btnVoltarMobile' to='/permission/new' style={{ textDecoration: 'none' }}>
                                    <Button id='buttonNew' className='borderbtnCreateEdit'><AiOutlinePlusCircle id='iconButtonNew' className='iconButtonMobile' /><span className='novaPermissionMobile'>Nova Permissão</span></Button>
                                </NavLink>
                            ) : ''
                        }
                    </div>
                </div>

                <ToastContainer />

                <div>
                    <Table id='tableList' striped bordered hover>
                        <thead>
                            <tr id="trList">
                                <th className='th-text-center'>#ID</th>
                                <th className='th-text-center'>Nome</th>
                                <th className='d-flex justify-content-around'>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredPermissions.map((permission, index) => (
                                <tr key={permission.id}>
                                    <td className='td-text-center fontCustom'>{permission.id}</td>
                                    <td className='td-text-center fontCustom'>{permission.name}</td>
                                    <td className='d-flex justify-content-around'>
                                        {
                                            userPermissions.some((permission) => permission.name === 'delete_permission') ? (
                                                <Button className='deleteButton' onClick={() => showDeleteConfirmationModal(permission.id, permission.name)}><FaTrash /> Excluir</Button>
                                            ) : ''
                                        }
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Pagination
                        nPages={nPages}
                        currentPage={currentPage} 
                        setCurrentPage={setCurrentPage}
                    />
                </div>
            </div>
            )}
        </>
    )
}

export default Index