import React, { useState, useEffect } from 'react'
import interact from 'interactjs'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import api from '../../services/api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import Select from 'react-select'
import { MdSaveAlt } from "react-icons/md";
import { LuTextCursorInput } from "react-icons/lu";
import { PiSelectionBackgroundDuotone } from "react-icons/pi";
import { FaUsers } from "react-icons/fa";

const DragAndDropArea = () => {
  const [items, setItems] = useState([])
  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const [selectedBackground, setSelectedBackground] = useState({ type: '', url: '' })
  const [templateName, setTemplateName] = useState('')
  const [backgrounds, setBackgrounds] = useState([])
  const [doSpaces, setDoSpaces] = useState('')
  const customId = 'success-toast-id'
  const [clients, setClients] = useState([])
  const [clientToAssociate, setClientToAssociate] = useState('')
  const history = useNavigate()
  const [backgroundContent, setBackgroundContent] = useState('')

  let { id } = useParams()

  const handleSubmit = () => {
    const convertedItems = convertItemsForBackend(items)
    if (templateName === '') {
      toast.error('Por favor preencha o campo de Nome Template.', { autoClose: 3000 })
    } else if (backgrounds === '') {
      toast.error('Por favor escolha um plano de fundo.', { autoClose: 3000 })
    } else {
      api.put(`/api/templateDND/${id}`, {
        items: convertedItems,
        background: selectedBackground,
        templateName: templateName,
        cliente: clientToAssociate
      })
        .then(response => {
          toast.success('Template criado com sucesso!', { toastId: customId, autoClose: 3000, onClose: history('/template/padrao/index') })
        })
        .catch(error => {
          toast.error('Erro ao criar template', { toastId: customId, autoClose: 3000 })
          console.error("Erro ao enviar dados:", error)
        })
    }
  }

  useEffect(() => {
    let cancel = false

    async function fetchData() {
      await api.get(`api/templateDND/${id}`).then((res) => {
        if (res.data.items.length > 0) {
          const area = document.getElementById('dragAndDropArea')
          const areaRect = area.getBoundingClientRect()

          const updatedItems = res.data.items.map(item => ({
            ...item,
            width: (item.width * areaRect.width) / 100,
            height: (item.height * areaRect.height) / 100,
            left: (item.left * areaRect.width) / 100,
            top: (item.top * areaRect.height) / 100,
          }));

          setItems(updatedItems);
        }
        setClientToAssociate(res.data.clienteAssociated)
        setTemplateName(res.data.template.nome)
        let bg = res.data.template.template_background.split(",")
        setSelectedBackground({ type: bg[0], url: bg[1] })

        if (bg[0] === 'video') {
          setBackgroundContent(`
            <video autoPlay loop muted playsInline style="position: absolute; width: 100%; height: 100%; object-fit: cover;">
              <source src="${bg[1]}" type="video/mp4"/>
              Seu navegador não suporta vídeos.
            </video>
          `)
        } else {
          setBackgroundContent(`
            <div style="width: 100%; height: 100%; background-image: url(${bg[1]}); background-size: cover; background-position: center;"></div>
          `)
        }
      })

      await api.get(`api/materiais/getMateriais`)
        .then(response => {
          setBackgrounds(response.data.materiais)
          setDoSpaces(response.data.spaces)
        })
        .catch(error => console.error("Falha ao carregar backgrounds:", error))


      await api.post(`api/client/getClientByAmbienteId`, {
        ambiente: Cookies.get('ambienteId'),
      }).then((res) => {
        if (cancel) return

        let clientsData = []

        res.data.clients.forEach((client) => {
          clientsData.push({
            value: client.id,
            label: client.name,
          })
        })

        setClients(clientsData)
      })
    }

    const adjustDragAndDropAreaSize = () => {
      const area = document.getElementById('dragAndDropArea')
      if (!area) return

      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight * 0.5
      const fullHdAspectRatio = 1920 / 1080

      let newWidth = viewportWidth
      let newHeight = viewportWidth / fullHdAspectRatio

      if (newHeight > viewportHeight) {
        newHeight = viewportHeight
        newWidth = viewportHeight * fullHdAspectRatio
      }

      area.style.width = `${newWidth}px`
      area.style.height = `${newHeight}px`
    }

    adjustDragAndDropAreaSize()

    window.addEventListener('resize', adjustDragAndDropAreaSize)

    window.removeEventListener('resize', adjustDragAndDropAreaSize)

    interact('.resize-drag')
      .draggable({
        onmove: window.dragMoveListener,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: 'parent',
            endOnly: true,
          }),
        ],
      })
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        modifiers: [
          interact.modifiers.restrictEdges({
            outer: 'parent',
          }),
        ],
      })
      .on('dragmove', (event) => {
        const index = event.target.getAttribute('data-index')
        updateItemPosition(index, event.dx, event.dy)
      })
      .on('resizemove', (event) => {
        const index = event.target.getAttribute('data-index')
        updateItemSize(index, event.rect, event.deltaRect)
      })

    function updateItemPosition(index, dx, dy) {
      setItems(items =>
        items.map((item, i) =>
          i === parseInt(index)
            ? {
              ...item,
              left: item.left + dx,
              top: item.top + dy,
            }
            : item,
        ),
      )
    }

    function updateItemSize(index, rect, deltaRect) {
      setItems(items =>
        items.map((item, i) =>
          i === parseInt(index)
            ? {
              ...item,
              width: rect.width,
              height: rect.height,
              left: item.left + deltaRect.left,
              top: item.top + deltaRect.top,
            }
            : item,
        ),
      )
    }

    window.dragMoveListener = function (event) {
      const target = event.target
      const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
      const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

      target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)'

      target.setAttribute('data-x', x)
      target.setAttribute('data-y', y)
    }

    fetchData()

    return () => {
      cancel = true
    }
  }, [id])

  const handleChangeCliente = async (e) => {
    setClientToAssociate(e)
  }

  const handleItemClick = (index) => {
    setSelectedItemIndex(index)
  }

  const handleChangeBackground = (e) => {
    const bg = backgrounds.find(bg => `${doSpaces}${bg.caminho}/${bg.arquivo}` === e.target.value)
    if (bg) {
      setSelectedBackground({ type: bg.type, url: e.target.value })
    }

    if (bg.type === 'video') {
      setBackgroundContent(`
        <video autoPlay loop muted playsInline style="position: absolute; width: 100%; height: 100%; object-fit: cover;">
          <source src="${e.target.value}" type="video/mp4"/>
          Seu navegador não suporta vídeos.
        </video>
      `)
    } else {
      setBackgroundContent(`
        <div style="width: 100%; height: 100%; background-image: url(${e.target.value}); background-size: cover; background-position: center;"></div>
      `)
    }
  }

  const updateSelectedItem = (updatedFields) => {
    setItems(items =>
      items.map((item, i) =>
        i === selectedItemIndex ? { ...item, ...updatedFields } : item,
      ),
    )
  }

  const convertItemsForBackend = (items) => {
    const area = document.getElementById('dragAndDropArea')
    if (!area) return items

    const areaRect = area.getBoundingClientRect()

    return items.map(item => {
      const itemWidthInPx = parseFloat(item.width)
      const itemHeightInPx = parseFloat(item.height)
      const itemLeftInPx = parseFloat(item.left)
      const itemTopInPx = parseFloat(item.top)

      const widthPercent = (itemWidthInPx / areaRect.width) * 100
      const heightPercent = (itemHeightInPx / areaRect.height) * 100
      const leftPercent = (itemLeftInPx / areaRect.width) * 100
      const topPercent = (itemTopInPx / areaRect.height) * 100

      return {
        ...item,
        width: widthPercent,
        height: heightPercent,
        left: leftPercent,
        top: topPercent,
      }
    })
  }

  return (
    <div className="container">
      <ToastContainer />
      <Row className='d-flex'>
        <div className='divSaveBtnClass'>
          <Button onClick={handleSubmit} className="saveBtnClass mb-2" type='submit'>
            <span className='d-flex align-items-center'><MdSaveAlt className='fs-5 me-1' />Salvar</span>
          </Button>
        </div>
        <div className='d-flex gap-2'>
          <Col>
            <Form.Group className="divMaquinasNovaPub shadowCustom">
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><LuTextCursorInput className='fs-5 me-1' />Nome do Template</span>
              </label>
              <div className='p-2'>
                <Form.Control
                  className='rounded-1'
                  required
                  type='text'
                  disabled
                  placeholder='Digite o nome do template'
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </div>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group className='divMaquinasNovaPub shadowCustom'>
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><FaUsers className='fs-5 me-1' />Clientes</span>
              </label>
              <Select
                className='p-2'
                value={clientToAssociate}
                onChange={(e) => handleChangeCliente(e)}
                options={clients}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className='divMaquinasNovaPub shadowCustom'>
              <label className='fw-semibold borderDown p-2 bg-dark text-white'>
                <span><PiSelectionBackgroundDuotone className='fs-5 me-1' />Plano de fundo</span>
              </label>
              <div className='p-2'>
                <select
                  onChange={handleChangeBackground}
                  className="w-100 selectGenClass"
                >
                  {backgrounds.map((bg, index) => (
                    <option key={index} value={`${doSpaces}${bg.caminho}/${bg.arquivo}`}>{bg.label}</option>
                  ))}
                </select>
              </div>
            </Form.Group>
          </Col>
        </div>
      </Row>
      {selectedItemIndex !== null && (
        <Row className='d-flex mt-2 mb-2'>
          <div className='d-flex gap-2'>
            {items[selectedItemIndex].tipo === 'texto' ||
              items[selectedItemIndex].tipo === 'relogio' ||
              items[selectedItemIndex].tipo === 'data' ||
              items[selectedItemIndex].tipo === 'semana' ||
              items[selectedItemIndex].tipo === 'max' ||
              items[selectedItemIndex].tipo === 'min' ||
              items[selectedItemIndex].tipo === 'dolar' ||
              items[selectedItemIndex].tipo === 'euro' ||
              items[selectedItemIndex].tipo === 'lista' ?
              <Col>
                <Form.Group className='divMaquinasNovaPub shadowCustom text-nowrap' controlid='formBasicConfirmationPassword'>
                  <label className='fw-semibold borderDown p-2 bg-dark text-white'>Cor da fonte {items[selectedItemIndex].tipo}</label>
                  <div className='p-2'>
                    <Form.Control required type='color' className='w-100' placeholder='Selecione o tempo em segundos' value={items[selectedItemIndex].fontColor} onChange={(e) => updateSelectedItem({ fontColor: e.target.value })} />
                  </div>
                </Form.Group>
              </Col> : ''
            }
            <Col>
              <Form.Group className='divMaquinasNovaPub shadowCustom text-nowrap' controlid='formBasicConfirmationPassword'>
                <label className='fw-semibold borderDown p-2 bg-dark text-white'>Tempo para {items[selectedItemIndex].tipo} aparecer</label>
                <div className='p-2'>
                  <Form.Control required type='number' placeholder='Selecione o tempo em segundos (Opcional)' value={items[selectedItemIndex].fadeIn} onChange={(e) => updateSelectedItem({ fadeIn: e.target.value })} />
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='divMaquinasNovaPub shadowCustom text-nowrap' controlid='formBasicConfirmationPassword'>
                <label className='fw-semibold borderDown p-2 bg-dark text-white'>Tempo para {items[selectedItemIndex].tipo} desaparecer</label>
                <div className='p-2'>
                  <Form.Control required type='number' placeholder='Selecione o tempo em segundos' value={items[selectedItemIndex].fadeOut} onChange={(e) => updateSelectedItem({ fadeOut: e.target.value })} />
                </div>
              </Form.Group>
            </Col>
            {items[selectedItemIndex].tipo === 'texto' ||
              items[selectedItemIndex].tipo === 'relogio' ||
              items[selectedItemIndex].tipo === 'data' ||
              items[selectedItemIndex].tipo === 'semana' ||
              items[selectedItemIndex].tipo === 'max' ||
              items[selectedItemIndex].tipo === 'min' ||
              items[selectedItemIndex].tipo === 'dolar' ||
              items[selectedItemIndex].tipo === 'euro' ||
              items[selectedItemIndex].tipo === 'lista' ?
              <Col>
                <Form.Group className='divMaquinasNovaPub shadowCustom text-nowrap' controlid='formBasicConfirmationPassword'>
                  <label className='fw-semibold borderDown p-2 bg-dark text-white'>Tamanho da fonte de {items[selectedItemIndex].tipo}</label>
                  <div className='p-2'>
                    <Form.Control min={1} required type='number' placeholder='Tamanho da fonte' value={items[selectedItemIndex].fontSize} onChange={(e) => updateSelectedItem({ fontSize: e.target.value })} />
                  </div>
                </Form.Group>
              </Col> : ''
            }
            {items[selectedItemIndex].tipo === 'lista' ?
              <Col>
                <Form.Group className='divMaquinasNovaPub shadowCustom text-nowrap' controlid='formBasicConfirmationPassword'>
                  <label className='fw-semibold borderDown p-2 bg-dark text-white'>Escreva os itens da {items[selectedItemIndex].tipo}</label>
                  <div className='p-2'>
                    <Form.Control min={1} required className='p-2' type='text' placeholder="Separador ';'" value={items[selectedItemIndex].lista} onChange={(e) => updateSelectedItem({ lista: e.target.value })} />
                  </div>
                </Form.Group>
              </Col> : ''
            }
          </div>
        </Row>
      )}
        <div id="dragAndDropArea" className='container w-100 mt-2' style={{
          margin: '0px',
          padding: '0px',
          width: '100%',
          position: 'relative',
          border: '1px solid #ccc',
        }}
        >
          <div style={{ position: 'absolute', width: '100%', height: '100%' }} dangerouslySetInnerHTML={{ __html: backgroundContent }}></div>

          {items.map((item, index) => (
            <div
              key={index}
              className="resize-drag"
              data-index={index}
              style={{
                width: `${item.width}px`,
                height: `${item.height}px`,
                transform: `translate(${item.left}px, ${item.top}px)`,
                position: 'absolute',
                border: '1px solid #fff',
                backgroundColor: 'rgba(0,0,255,0.1)',
              }}
              onClick={() => handleItemClick(index)}
            >
              {item.nome}
            </div>
          ))}

        </div>
    </div>
  )
}

export default DragAndDropArea
