import React, { useState } from 'react';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { Tooltip } from 'reactstrap';

import './style.css'

function WhatsAppButton(args) {

const [tooltipOpen, setTooltipOpen] = useState(false);
const toggle = () => setTooltipOpen(!tooltipOpen);

    return (
        <div id='div-whatsapp-button'>
            <a
                href="https://api.whatsapp.com/send?phone=5511989593038"
                target="_blank"
                rel="noopener noreferrer"
                id="TooltipExample"
                className='pt-4 text-decoration-none'
            >
                <button 
                    id="whatsapp-button"
                    data-bs-toggle="tooltip" data-bs-placement="top"
                    data-bs-custom-class="custom-tooltip"
                    data-bs-title="Thi"
                    >
                    <AiOutlineWhatsApp id='whatsapp-icon' icon={faWhatsapp}/>
                </button>
                <p id='textSuporte'>Suporte</p>
            </a>
            <Tooltip
                {...args}
                isOpen={tooltipOpen}
                target="TooltipExample"
                toggle={toggle}
            >
                ATMO Help Desk
            </Tooltip>
        </div>
    );
}

export default WhatsAppButton;
